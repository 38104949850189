import React, { useState, useEffect } from "react";

import CreateButton from "../../UI/CreateButton";
import Table from "../../../NewTable/Table";
import Card from "../../UI/Card/Card";
import Loader from "../../UI/Loader";
import FleetForm from "./FleetForm";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import ConfirmationSuccess from "../../../Modals/ConfirmationModal/ConfirmationSuccess";
import trucktypesJSON from "../../../../data/trucktypes.json";
import { useDispatch, useSelector } from "react-redux";
import { ADD_FLEET, REMOVE_FLEET } from "../../../../store/actionTypes";

import {
  addFleet,
  removeFleet,
} from "../../../../store/actions/dashboard/company";
import TableHeader from "../../../NewTable/TableHeader";
import TableRow from "../../../NewTable/TableRow";
import TableRowItem from "../../../NewTable/TableRowItem";
import { TableHeaderItem } from "../../../NewTable/Styles";
import Options from "../../../NewTable/Options";

const FleetCard = (props) => {
  const [componentState, setComponentState] = useState({
    loadingSubmission: false,
    saved: false,
    showFleetForm: false,
  });
  const [optionsId, setOptionsId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const FLEET = useSelector((state) => state.dashboard.company?.data?.fleet);
  const [inputs, setInputs] = useState({});
  const [truckTypes, setTruckTypes] = useState(trucktypesJSON.types);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { USER } = props;

  const handleConfirmationModal = (id, title) => {
    setConfirmationModal({ id, title });
  };

  const handleToggleOptions = (id) => {
    if (optionsId === id) {
      setOptionsId(null);
    } else {
      setOptionsId(id);
    }
  };

  // Update fleet dropdown every time new truck is added

  useEffect(() => {
    /*     if (FLEET) {
      const existingTrucks = [];
      FLEET.map((truck) => {
        return existingTrucks.push(truck.truckType);
      });
      let newTruckTypeOptions = truckTypes;
      if (existingTrucks.length > 0) {
        existingTrucks.forEach(
          (el) =>
            (newTruckTypeOptions = newTruckTypeOptions.filter(
              (element) => element.value !== el
            ))
        );
      }
      setTruckTypes(newTruckTypeOptions);
    } */
  }, [/* FLEET, */ truckTypes]);

  const handleInputChange = (e, type) => {
    if (type?.name !== "truckType") {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    } else {
      setInputs({
        ...inputs,
        [type.name]: {
          label: e.label,
          value: e.value,
        },
      });
    }
  };

  // Handle adding a truck

  const addTruckHandler = (e) => {
    e.preventDefault();
    if (inputs.truckType && inputs.count && inputs.count !== "0") {
      setComponentState((prevState) => ({
        ...prevState,
        loadingSubmission: true,
      }));
      setErrors({});
      dispatch(addFleet(inputs.truckType.value, inputs.count))
        .then((res) => {
          // UPDATE STATE WITH NEW TRUCK
          dispatch({
            type: ADD_FLEET,
            payload: res.data,
          });
          // HANDLE UI
          setComponentState((prevState) => ({
            ...prevState,
            loadingSubmission: false,
            saved: true,
          }));
          setTimeout(() => {
            setComponentState((prevState) => ({ ...prevState, saved: false }));
          }, 2000);
          // Reset input values
          setInputs({});
        })
        .catch((err) => {
          const errors = err.response?.data?.errors;
          setErrors(errors);
        });
    }
  };

  // Handle removing a truck

  const removeTruckHandler = (id) => {
    dispatch(removeFleet(id)).then((res) => {
      //props.onSetCompanyFleet(res.data);
      // REMOVE TRUCK FROM REDUX STORE
      dispatch({
        type: REMOVE_FLEET,
        id: id,
      });
      setConfirmationModal(null);
      setConfirmationSuccess(true);
      setTruckTypes(trucktypesJSON.types);
    });
    // TODO: CREATE ERROR MESSAGE?
  };

  return (
    <Card title="Fleet">
      {FLEET?.length > 0 ? (
        <Table>
          <TableHeader>
            <TableHeaderItem flex="0.7">Truck type</TableHeaderItem>
            <TableHeaderItem flex="0.2">Trucks</TableHeaderItem>
            {USER?.privileges === "Admin" && (
              <TableHeaderItem flex="0.1"></TableHeaderItem>
            )}
          </TableHeader>
          {FLEET?.map((truck) => {
            return (
              <TableRow key={truck._id}>
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truck.truckType?.replace(/_/gi, " "),
                  }}
                  flex="0.7"
                />
                <TableRowItem
                  rowName="Trucks"
                  title={{
                    name: truck.count,
                  }}
                  flex="0.2"
                />
                {USER?.privileges === "Admin" && (
                  <TableRowItem flex="0.1" overflow>
                    <Options
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleOptions(truck._id);
                      }}
                      open={optionsId === truck._id}
                      onBlur={() => setOptionsId(null)}
                    >
                      <ul>
                        <li
                          onClick={() =>
                            handleConfirmationModal(
                              truck._id,
                              `Delete: ${truck.truckType?.replace(/_/gi, " ")}`
                            )
                          }
                        >
                          Delete
                        </li>
                      </ul>
                    </Options>
                  </TableRowItem>
                )}
              </TableRow>
            );
          })}
        </Table>
      ) : (
        !FLEET && <Loader />
      )}
      {USER?.privileges === "Admin" && !componentState.showFleetForm && (
        <CreateButton
          title="Add truck"
          isButton
          onClick={() =>
            setComponentState((prevState) => ({
              ...prevState,
              showFleetForm: true,
            }))
          }
        />
      )}
      {componentState.showFleetForm && (
        <FleetForm
          onSubmit={(e) => addTruckHandler(e)}
          errors={errors}
          inputs={inputs}
          onInputChange={handleInputChange}
          loadingSubmission={componentState.loadingSubmission}
          saved={componentState.saved}
          truckTypes={truckTypes}
        />
      )}
      {USER?.privileges === "Admin" && (
        <>
          {confirmationModal && (
            <ConfirmationModal
              onClose={() => setConfirmationModal(null)}
              title={confirmationModal.title}
              onConfirm={() => removeTruckHandler(confirmationModal.id)}
            />
          )}
          {confirmationSuccess && (
            <ConfirmationSuccess
              onClose={() => setConfirmationSuccess(false)}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default FleetCard;
