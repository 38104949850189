import React from "react";
import SheetBlock from "../../PageWithList/Sheet/SheetBlock";
import {
  ShowWrapper,
  SheetItem,
  SheetItemInline,
} from "../../PageWithList/Sheet/Styles";
import DetailsSpecialRequests from "../../PageWithList/Sheet/DetailsSpecialRequests";
import uuid from "uuid";

const Details = (props) => {
  const {
    loading,
    unloading,
    Freight,
    shipments,
    targetPrice,
    offerIsActive,
    truckType,
    Payment,
    periodOfTransports,
    temperature,
  } = props.tender;
  return (
    <ShowWrapper show={props.show}>
      <SheetBlock label="Tender info">
        <SheetItem>
          <h3>Loading</h3>
          {loading &&
            loading.map((load) => {
              return <h4 key={uuid()}>{load.locationName}</h4>;
            })}
        </SheetItem>
        <SheetItem>
          <h3>Unloading</h3>
          {unloading &&
            unloading.map((unload) => {
              return <h4 key={uuid()}>{unload.locationName}</h4>;
            })}
        </SheetItem>
        <SheetItem>
          <h3>Truck type</h3>
          <h4>
            {truckType
              .map((element) => {
                if (element.value === "Refrigerator" && temperature) {
                  return `${element.value} (${temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ")}
          </h4>
        </SheetItem>
        <SheetItemInline>
          <SheetItem>
            <h3>Type of load</h3>
            <h4>{Freight.cargoType}</h4>
          </SheetItem>
          <SheetItem>
            <h3>No of trips</h3>
            <h4>
              {shipments.numberOfShipments} x{" "}
              {shipments.periodicity.toLowerCase()}
            </h4>
          </SheetItem>
          {targetPrice && targetPrice !== "0" && (
            <SheetItem>
              <h3>Target price</h3>
              <h4>{targetPrice}€</h4>
            </SheetItem>
          )}
        </SheetItemInline>
      </SheetBlock>
      <SheetBlock label="Details">
        <SheetItemInline inlineRow>
          {Freight.dimensions.weight && (
            <SheetItem>
              <h3>Weight</h3>
              <h4>
                {Freight.dimensions.weight}
                {Freight.dimensions.weight <= 101 ? "000 kg" : "kg"}
              </h4>
            </SheetItem>
          )}
          {Freight.dimensions.loadingMeters && (
            <SheetItem>
              <h3>Loading meters</h3>
              <h4>{Freight.dimensions.loadingMeters} m</h4>
            </SheetItem>
          )}
          {Freight.dimensions?.length && (
            <SheetItem>
              <h3>Length</h3>
              <h4>{Freight.dimensions?.length} cm</h4>
            </SheetItem>
          )}
          {Freight.dimensions.width && (
            <SheetItem>
              <h3>Width</h3>
              <h4>{Freight.dimensions.width} cm</h4>
            </SheetItem>
          )}
          {Freight.dimensions.height && (
            <SheetItem>
              <h3>Height</h3>
              <h4>{Freight.dimensions.height} cm</h4>
            </SheetItem>
          )}
          {Freight.dimensions.volume && (
            <SheetItem>
              <h3>Volume</h3>
              <h4>
                {Freight.dimensions.volume} m<sup>3</sup>
              </h4>
            </SheetItem>
          )}
        </SheetItemInline>
        {Freight.freight && (
          <SheetItem>
            <h3>Type of cargo</h3>
            <h4>{Freight.freight.replace(/_/g, " ")}</h4>
          </SheetItem>
        )}
        {periodOfTransports && (
          <SheetItem>
            <h3>Period of shipments</h3>
            <h4>
              {new Date(periodOfTransports.startDate).toLocaleDateString()} -{" "}
              {new Date(periodOfTransports.endDate).toLocaleDateString()}
            </h4>
          </SheetItem>
        )}
        {offerIsActive && (
          <SheetItem>
            <h3>Deadline to recieve offers</h3>
            <h4>{new Date(offerIsActive).toLocaleDateString()}</h4>
          </SheetItem>
        )}
        <DetailsSpecialRequests specialRequests={Freight.specialRequests} />
        {Freight.specialRequests.otherReq && (
          <SheetItem>
            <h3>Other requirements</h3>
            <h4>{Freight.specialRequests.otherReq}</h4>
          </SheetItem>
        )}
      </SheetBlock>
      {Payment.paymentTime !== 0 ||
        (Payment.paymentComments && (
          <SheetBlock label="Payment">
            {Payment.paymentTime !== 0 && (
              <SheetItem>
                <h3>Payment time</h3>
                <h4>{Payment.paymentTime}</h4>
              </SheetItem>
            )}
            {Payment.paymentComments && (
              <SheetItem>
                <h3>Payment comments</h3>
                <h4>{Payment.paymentComments}</h4>
              </SheetItem>
            )}
          </SheetBlock>
        ))}
    </ShowWrapper>
  );
};

export default Details;
