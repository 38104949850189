import React, { useEffect, useReducer } from "react";
import SheetBlock from "./SheetBlock";
import { ShowWrapper, SheetItem, MapWrapper, LoaderWrapper } from "./Styles";
import pinStart from "../../../images/pinStart.svg";
import pinEnd from "../../../images/pinEnd.svg";
import Loader from "../../Dashboard/UI/Loader";

const reducer = (state, action) => {
  switch (action.type) {
    case "HAS_REQUESTED_MAP":
      return {
        ...state,
        hasRequestedMap: action.payload,
      };
    case "SET_DISTANCE":
      return {
        ...state,
        distance: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

const Map = (props) => {
  const { hasRequestedMap, loading, unloading } = props;
  const [state, dispatch] = useReducer(reducer, {
    hasRequestedMap: hasRequestedMap,
    distance: null,
    loading: false,
  });

  useEffect(() => {
    if (state.hasRequestedMap !== hasRequestedMap)
      dispatch({ type: "HAS_REQUESTED_MAP", payload: hasRequestedMap });
  }, [state.hasRequestedMap, hasRequestedMap]);

  useEffect(() => {
    if (hasRequestedMap) {
      dispatch({ type: "SET_LOADING", payload: true });
      //AUTH
      const platform = new window.H.service.Platform({
        apikey: "p4t5u_Nd2KzfYyVtkPi-1oxFIVeCIQhVojxNR3b1ONE",
      });
      const layer = platform.createDefaultLayers();
      //ADD ROUTING PARAMETERS
      const waypointOne =
        loading[0]?.coordinates.lat + "," + loading[0]?.coordinates.long;
      const waypointTwo =
        unloading[0]?.coordinates.lat + "," + unloading[0]?.coordinates.long;
      const centerLat =
        (parseFloat(loading[0]?.coordinates.lat) +
          parseFloat(unloading[0]?.coordinates.lat)) /
        2;
      const centerLong =
        (parseFloat(loading[0]?.coordinates.long) +
          parseFloat(unloading[0]?.coordinates.long)) /
        2;
      const routingParameters = {
        mode: "fastest;truck",
        waypoint0: waypointOne,
        waypoint1: waypointTwo,
        representation: "display",
        legAttributes: "summary",
      };

      //INIT MAP
      // reset map innerhtml to render a new map
      const clearMap = document.getElementById("mapContainer");
      clearMap.innerHTML = "";
      const map = new window.H.Map(
        document.getElementById("mapContainer"),
        layer.vector.normal.map,
        {
          zoom: 4,
          center: { lat: centerLat, lng: centerLong },
        }
      );
      window.H.ui.UI.createDefault(map, layer);

      //ADD ROUTES TO MAP
      // Define a callback function to process the routing response:
      const onResult = function (result) {
        //Set Distance between 2 points
        dispatch(
          {
            type: "SET_DISTANCE",
            payload: Math.round(
              result.response.route[0].leg[0].summary.distance / 1000
            ),
          },
          { type: "SET_HAS_REQUESTED_MAP", payload: true }
        );
        var route, routeShape, startPoint, endPoint, linestring;
        if (result.response.route) {
          dispatch({ type: "SET_LOADING", payload: false });
          route = result.response.route[0];
          // Pick the route's shape:
          routeShape = route.shape;

          // Create a linestring to use as a point source for the route line
          linestring = new window.H.geo.LineString();

          // Push all the points in the shape into the linestring:
          routeShape.forEach(function (point) {
            var parts = point.split(",");
            linestring.pushLatLngAlt(parts[0], parts[1]);
          });

          // Retrieve the mapped positions of the requested waypoints:
          startPoint = route.waypoint[0].mappedPosition;
          endPoint = route.waypoint[1].mappedPosition;

          // Create a polyline to display the route:
          // Create an outline for the route polyline:
          var routeOutline = new window.H.map.Polyline(linestring, {
            style: {
              lineWidth: 5,
              strokeColor: "#15198F",
              lineTailCap: "arrow-tail",
              lineHeadCap: "arrow-head",
            },
          });
          // create a group that represents the route line and contains outline
          var routeLine = new window.H.map.Group();
          routeLine.addObjects([routeOutline]);
          // Pick the first route from the response:

          // Create a marker for the start point:
          const iconStart = new window.H.map.Icon(pinStart);
          var startMarker = new window.H.map.Marker(
            {
              lat: startPoint.latitude,
              lng: startPoint.longitude,
            },
            { icon: iconStart }
          );
          const iconEnd = new window.H.map.Icon(pinEnd);
          // Create a marker for the end point:
          var endMarker = new window.H.map.Marker(
            {
              lat: endPoint.latitude,
              lng: endPoint.longitude,
            },
            { icon: iconEnd }
          );
          // Add the route polyline and the two markers to the map:
          map.addObjects([routeLine, startMarker, endMarker]);

          // Set the map's viewport to make the whole route visible:
          const boundingBox = routeLine.getBoundingBox();
          //SET zoom so whole route is visible
          const lookBoundingBox = new window.H.geo.Rect(
            boundingBox.a.lat - 1,
            boundingBox.a.lng + 0.5,
            boundingBox.c.lat + 1,
            boundingBox.c.lng - 0.5
          );

          map.getViewModel().setLookAtData({ bounds: lookBoundingBox });
          //ADD EVENTS AND LISTENERS
          var mapEvents = new window.H.mapevents.MapEvents(map);

          map.addEventListener("tap", function (evt) {});

          // Instantiate the default behavior, providing the mapEvents object:
          new window.H.mapevents.Behavior(mapEvents);
        }
      };

      // Get an instance of the routing service:
      var router = platform.getRoutingService();

      // Call calculateRoute() with the routing parameters,
      // the callback and an error callback function (called if a
      // communication error occurs):
      router.calculateRoute(routingParameters, onResult, function (error) {
        alert(error.message);
      });
      return () =>
        dispatch(
          { type: "HAS_REQUESTED_MAP", payload: false },
          { type: "SET_DISTANCE", payload: null },
          { type: "SET_LOADING", payload: false }
        );
    }
  }, [loading, unloading, hasRequestedMap, dispatch]);
  return (
    <ShowWrapper show={props.show}>
      <SheetBlock label="Map">
        <MapWrapper>
          <div id="mapContainer"></div>
        </MapWrapper>
        {state.loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {state.distance && (
          <SheetItem>
            <h3>Distance</h3>
            <h4>{`${state.distance} km`}</h4>
          </SheetItem>
        )}
      </SheetBlock>
    </ShowWrapper>
  );
};

export default Map;
