import React from "react";
import { HeaderWrapper, HeaderClose, HeaderInnerClose } from "./Styles";

const Header = (props) => {
  return (
    <HeaderWrapper noBorder={props.noBorder} hasTitle={props.title}>
      {props.title && <h2>{props.title}</h2>}
      <HeaderClose onClick={props.onClose}>
        <HeaderInnerClose>{ModalCloseIcon}</HeaderInnerClose>
      </HeaderClose>
    </HeaderWrapper>
  );
};

const ModalCloseIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.782"
    height="18.781"
    viewBox="0 0 18.782 18.781"
  >
    <g transform="translate(-1332.725 -223.68)">
      <path
        d="M-5412.861-16947.906l15.954,15.953"
        transform="translate(6747 17173)"
        fill="none"
        stroke="#dcdcdc"
        strokeWidth="4"
      />
      <path
        d="M-5396.907-16947.906l-15.954,15.953"
        transform="translate(6747 17173)"
        fill="none"
        stroke="#dcdcdc"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default Header;
