import React from "react";
import { Radio } from "../CreateForm/Styles";

const RadioInput = (props) => {
  return (
    <Radio
      selected={
        props.inputs?.[props.name] === props.value || props.defaultChecked
      }
      style={props.style}
    >
      <label htmlFor={props.value}>
        <input
          type="radio"
          name={props.name}
          id={props.value}
          value={props.value}
          defaultChecked={props.defaultChecked}
          checked={props.inputs?.[props.name] === props.value || ""}
          onChange={(e) => props.onInputChange(+e.target.value, props.name)}
        />
        {props.label && props.label}
        {props.children}
      </label>
    </Radio>
  );
};

export default RadioInput;
