import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { SET_OFFERS } from "../../store/actionTypes";
import { FormatEmptyTruckEditData } from "../../helpers/FormatEmptyTruckEditData";

import Form from "../CreateForm/Form";
import FormBlock from "../CreateForm/FormBlock";
import EmptyTruckCreatorStepOne from "./EmptyTruckCreatorStepOne";
import EmptyTruckCreatorStepTwo from "./EmptyTruckCreatorStepTwo";
import LargeButton from "../CreateForm/LargeButton";
import { ButtonWrapper } from "../CreateForm/Styles";
import Loader from "../Dashboard/UI/Loader";
import Screen from "../Dashboard/Screen/Screen";
import LeftDrawer from "../Dashboard/Drawers/LeftDrawer";
import Navbar from "../Dashboard/Navbar";
import Minimessages from "../Dashboard/Messages/Minimessages";
import { isBrowser } from "react-device-detect";
import {
  validateEmptyTruck,
  getSingleEmptyTruck,
  createEmptyTruck,
} from "../../store/actions/app/emptytruck";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const EmptyTruckCreator = (props) => {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(props.showAllBlocks ? 0 : 1);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      props.history.push("/trucks");
  }, [USER_PLAN, props.history]);

  // Get data when user requests edit page
  const { editMode, duplicateMode } = props;
  const { id: truckId } = props.match?.params || {};
  useEffect(() => {
    if ((editMode || duplicateMode) && truckId) {
      dispatch(getSingleEmptyTruck(truckId)).then((res) => {
        setInputs(FormatEmptyTruckEditData(res.data));
        setLoaded(true);
      });
      //.catch(() => props.history.push("/trucks"));
    } else {
      setLoaded(true);
    }
  }, [editMode, duplicateMode, truckId, props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    dispatch(
      createEmptyTruck(!editMode ? "POST" : "PUT", {
        ...inputs,
        id: editMode ? truckId : null,
      })
    )
      .then((res) => {
        dispatch({ type: SET_OFFERS, payload: res.data });
        props.history.push("/dashboard/my-offers/emptytrucks");
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
        setSubmitting(false);
        scrollToTop();
      });
  };

  const handleInputChange = (value, type, descendant) => {
    if (!descendant) {
      setInputs((prevState) => ({ ...prevState, [type]: value }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [descendant]: { ...prevState[descendant], [type]: value },
      }));
    }
    // Clear error of interacted input
    setErrors((prevState) => ({ ...prevState, [type]: null }));
  };

  const handleSelectChange = (value, type) => {
    setInputs({ ...inputs, [type]: value });
    // Clear error of interacted input
    if (errors?.[type])
      setErrors((prevState) => ({ ...prevState, [type]: null }));
  };

  const handleInputDelete = (type) => {
    const copy = inputs;
    Object.keys(copy).find((obj) => {
      if (obj === type) {
        delete copy[type];
        return true;
      } else {
        return false;
      }
    });
    setInputs(copy);
  };

  const changeStep = (step) => setCurrentStep(step);

  const handleNextClick = () => {
    dispatch(validateEmptyTruck(inputs))
      .then(() => {
        changeStep(2);
        scrollToTop();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
        scrollToTop();
      });
  };

  if (!loaded) return <Loader />;
  if (editMode && !inputs) return <Loader />;
  return (
    <Screen centered>
      <Navbar />
      <Form
        title={!props.editMode ? "Add Empty Truck" : "Edit Empty Truck"}
        icon={icon}
        onSubmit={handleSubmit}
      >
        <FormBlock
          title="1. Route"
          step={!props.showAllBlocks ? 1 : 0}
          currentStep={currentStep}
        >
          <EmptyTruckCreatorStepOne
            handleInputDelete={handleInputDelete}
            onSelectChange={handleSelectChange}
            onInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
          />
          {currentStep !== 0 && (
            <div style={{ marginTop: "2rem" }}>
              <ButtonWrapper>
                <LargeButton label="Next" onClick={() => handleNextClick()} />
              </ButtonWrapper>
            </div>
          )}
        </FormBlock>
        <FormBlock
          title="2. Truck"
          step={!props.showAllBlocks ? 2 : 0}
          currentStep={currentStep}
        >
          <EmptyTruckCreatorStepTwo
            onSelectChange={handleSelectChange}
            onInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
          />
          {!submitting ? (
            <ButtonWrapper>
              {!props.showAllBlocks && (
                <LargeButton
                  secondary
                  label="Back"
                  onClick={() => {
                    changeStep(1);
                    scrollToTop();
                  }}
                />
              )}
              <LargeButton type="submit" label="Publish" />
            </ButtonWrapper>
          ) : (
            <Loader />
          )}
        </FormBlock>
      </Form>
      {isBrowser && <Minimessages />}
      <LeftDrawer small />
    </Screen>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.014"
    height="23.061"
    viewBox="0 0 32.014 23.061"
  >
    <g transform="translate(-1.306 -4.718)">
      <path
        d="M20.895,7.61H2.752a1.446,1.446,0,1,1,0-2.892H20.895a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 0)"
        fill="#e13505"
      />
      <path
        d="M20.895,11.582H2.752a1.446,1.446,0,1,1,0-2.892H20.895a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 2.772)"
        fill="#e13505"
      />
      <path
        d="M14.467,15.554H2.752a1.446,1.446,0,1,1,0-2.892H14.467a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 5.544)"
        fill="#e13505"
      />
      <path
        d="M26.69,15.915H14.372a1.446,1.446,0,1,1,0-2.892H26.69a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(5.184 5.705)"
        fill="#e13505"
      />
      <path
        d="M18.632,23.974a1.445,1.445,0,0,1-1.446-1.446V10.21a1.446,1.446,0,0,1,2.892,0V22.528A1.446,1.446,0,0,1,18.632,23.974Z"
        transform="translate(7.084 3.805)"
        fill="#e13505"
      />
    </g>
  </svg>
);

export default withRouter(EmptyTruckCreator);
