export const FormatEmptyTruckEditData = (data) => {
  const {
    truckType,
    loading,
    unloading,
    temperature,
    typeOfLoad,
    freight,
    specialFeatures,
    extraInfo,
  } = data.data;
  const unloadingLocations = {};
  const unloadingRadius = {};
  unloading.locations.forEach((l, i) => {
    unloadingLocations[`unloading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
    unloadingRadius[`unloadingradius${i}`] = l.radius;
  });
  return {
    truckType: truckType[0],
    temperature: temperature,
    loading0: {
      label: loading.locations[0].locationName,
      value: loading.locations[0].locationName,
    },
    loadingradius0: loading.locations[0].radius,
    loadingDate: {
      startDate: loading.date.start,
      endDate: loading.date.end,
    },
    ...unloadingLocations,
    ...unloadingRadius,
    typeOfLoad,
    freight: {
      weight: freight?.weight,
      loadingMeters: freight?.loadingMeters,
      height: freight?.height,
      length: freight?.length,
      width: freight?.width,
      volume: freight?.volume,
    },
    specialFeatures,
    extraInfo,
  };
};
