import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import Home from "../../pages/Home";
import MainScreen from "./Main/Main";
/* import Pricing from "../../pages/Pricing";
import Checkout from "../Checkout/Checkout"; */
import MyCompany from "./MyCompany/MyCompany";
import Messages from "./Messages/Messages";
import Quotations from "./Quotations/Quotations";
import MyOffers from "./MyOffers";
import Favourites from "./Favourites/Favourites";
import Subscription from "./Subscription/Subscription";
import Users from "./Users/Users";
import MyAccount from "./MyAccount/";
import SingleCompanyProfile from "./Main/SingleCompanyProfile";
/* import Tender from "../../pages/Tender"; */
import TenderCreator from "../NewTender/TenderCreator/TenderCreator";
/* import Roundtrip from "../../pages/Roundtrip"; */
import RoundtripCreator from "../Roundtrip/RoundtripCreator/RoundtripCreator";
/* import Spotload from "../../pages/Spotload"; */
import SpotloadCreator from "../Spotload/SpotloadCreator";
/* import EmptyTruck from "../../pages/EmptyTruck"; */
import EmptyTruckCreator from "../EmptyTruck/EmptyTruckCreator";
/* import Blog from "../../pages/Blog";
import About from "../../pages/About";
import FreeTrial from "../../pages/FreeTrial";
import Terms from "../../pages/Terms";
import Privacy from "../../pages/Privacy"; */

const Routes = (props) => {
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/dashboard" component={MainScreen} />
      {/*       <Route path="/blog/:id?" component={() => <Blog USER={USER} />} />
      <Route path="/pricing" render={() => <Pricing USER={USER} />} />
      <Route path="/about" render={() => <About USER={USER} />} />
      <Route path="/free-trial" render={() => <FreeTrial USER={USER} />} />
      <Route path="/terms" render={() => <Terms USER={USER} />} />
      <Route path="/privacy" render={() => <Privacy USER={USER} />} />
      <Route path="/checkout" component={Checkout} /> */}
      <Route path="/dashboard/my-company" component={MyCompany} />
      <Route path="/dashboard/messages" component={Messages} />
      <Route path="/dashboard/quotations" component={Quotations} />
      <Route path="/dashboard/my-offers" component={MyOffers} />
      <Route
        path="/dashboard/favourites"
        render={() => <Favourites USER={USER} />}
      />
      {USER && USER.privileges === "Admin" && (
        <Route path="/dashboard/subscription" component={Subscription} />
      )}
      {USER && USER.privileges === "Admin" && (
        <Route
          path="/dashboard/users"
          render={() => <Users COMPANY={COMPANY} />}
        />
      )}
      <Route
        path="/dashboard/my-account"
        render={() => <MyAccount USER={USER} COMPANY={COMPANY} />}
      />
      <Route path="/dashboard/company/:id" component={SingleCompanyProfile} />

      <Route
        exact
        path="/dashboard/tender/create"
        component={() => <TenderCreator />}
      ></Route>
      <Route
        exact
        path="/dashboard/tender/edit/:id"
        component={(props) => (
          <TenderCreator showAllBlocks editMode {...props} />
        )}
      />
      <Route
        exact
        path="/dashboard/tender/duplicate/:id"
        component={(props) => (
          <TenderCreator showAllBlocks duplicateMode {...props} />
        )}
      />
      {/*       <Route path="/tender/:id?" render={() => <Tender USER={USER} />} /> */}

      <Route
        exact
        path="/dashboard/roundtrip/create"
        component={() => <RoundtripCreator />}
      ></Route>
      <Route
        exact
        path="/dashboard/roundtrip/edit/:id"
        component={(props) => (
          <RoundtripCreator showAllBlocks editMode {...props} />
        )}
      />
      <Route
        exact
        path="/dashboard/roundtrip/duplicate/:id"
        component={(props) => (
          <RoundtripCreator showAllBlocks duplicateMode {...props} />
        )}
      />
      {/*       <Route path="/roundtrip/:id?" render={() => <Roundtrip USER={USER} />} /> */}

      <Route
        exact
        path="/dashboard/spotload/create"
        component={() => <SpotloadCreator />}
      ></Route>
      <Route
        exact
        path="/dashboard/spotload/edit/:id"
        component={(props) => (
          <SpotloadCreator showAllBlocks editMode {...props} />
        )}
      ></Route>
      <Route
        exact
        path="/dashboard/spotload/duplicate/:id"
        component={(props) => (
          <SpotloadCreator showAllBlocks duplicateMode {...props} />
        )}
      ></Route>
      {/*       <Route
        path="/spotload/:id?"
        render={() => <Spotload USER={USER} />}
      ></Route> */}

      <Route
        exact
        path="/dashboard/trucks/create"
        component={() => <EmptyTruckCreator />}
      ></Route>
      <Route
        exact
        path="/dashboard/trucks/edit/:id"
        component={(props) => (
          <EmptyTruckCreator showAllBlocks editMode {...props} />
        )}
      ></Route>
      <Route
        exact
        path="/dashboard/trucks/duplicate/:id"
        component={(props) => (
          <EmptyTruckCreator showAllBlocks duplicateMode {...props} />
        )}
      ></Route>
      {/*       <Route
        path="/trucks/:id?"
        render={() => <EmptyTruck USER={USER} />}
      ></Route> */}
    </Switch>
  );
};

export default Routes;
