import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { API_URL } from "../../../constants/index";

import {
  Wrapper,
  Selector,
  SelectorGroup,
  ButtonWrapper,
  ActiveArrow,
  OfferList,
} from "./Styles";
import Loader from "../../Dashboard/UI/Loader";
import SpotloadOffers from "./SpotloadOffers";
import RoundtripOffers from "./RoundtripOffers";
import TenderOffers from "./TenderOffers";
import EmptyTruckOffers from "./EmptyTruckOffers";

import { isMobile } from "react-device-detect";
import { LinkWrapper } from "../../PageWithList/List/Styles";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import CreateButton from "../../Dashboard/UI/CreateButton";
import { Link } from "react-router-dom";

const Offers = () => {
  const [tab, setTab] = useState(1);
  const [spotloads, setSpotloads] = useState(null);
  const [roundtrips, setRoundtrips] = useState(null);
  const [tenders, setTenders] = useState(null);
  const [emptytrucks, setEmptytrucks] = useState(null);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const USER = useSelector((state) => state.dashboard?.user?.data);
  const USER_PLAN = useSelector(
    (state) => state.dasbhoard?.company?.data?.subscriptionPlan?.status
  );

  useEffect(() => {
    if (tab === 1 && !spotloads) {
      axios.get(`${API_URL}/spotloads`).then((res) => {
        const filteredSpotloads = res.data?.filter((s) => s.active);
        setSpotloads(filteredSpotloads?.reverse()?.slice(0, !isMobile ? 3 : 2));
      });
    }
    if (tab === 2 && !roundtrips) {
      axios.get(`${API_URL}/roundtrip`).then((res) => {
        setRoundtrips(res?.data?.roundtrips?.slice(0, !isMobile ? 3 : 2));
      });
    }
    if (tab === 3 && !tenders) {
      axios.get(`${API_URL}/getTenders`).then((res) => {
        const filteredTenders = res?.data?.filter((t) => t.status);
        setTenders(filteredTenders?.reverse()?.slice(0, !isMobile ? 3 : 2));
      });
    }
    if (tab === 4 && !emptytrucks) {
      axios.get(`${API_URL}/emptyTruck`).then((res) => {
        if (res?.data?.data === null) return;
        const filteredTrucks = res?.data?.filter((t) => t.active);
        setEmptytrucks(filteredTrucks?.slice(0, !isMobile ? 3 : 2));
      });
    }
  }, [tab, spotloads, roundtrips, tenders, emptytrucks]);

  return (
    <Wrapper>
      <Selector>
        <h3>See latest offers for:</h3>
        <SelectorGroup>
          <ButtonWrapper active={tab === 1 ? 1 : null}>
            {tab === 1 && <ActiveArrow>{activeArrow}</ActiveArrow>}
            <button type="button" onClick={() => setTab(1)}>
              Load Exchange
            </button>
          </ButtonWrapper>
          <ButtonWrapper active={tab === 2 ? 1 : null}>
            {tab === 2 && <ActiveArrow>{activeArrow}</ActiveArrow>}
            <button type="button" onClick={() => setTab(2)}>
              Roundtrip
            </button>
          </ButtonWrapper>
          <ButtonWrapper active={tab === 3 ? 1 : null}>
            {tab === 3 && <ActiveArrow>{activeArrow}</ActiveArrow>}
            <button type="button" onClick={() => setTab(3)}>
              Tender
            </button>
          </ButtonWrapper>
          <ButtonWrapper active={tab === 4 ? 1 : null}>
            {tab === 4 && <ActiveArrow>{activeArrow}</ActiveArrow>}
            <button type="button" onClick={() => setTab(4)}>
              Empty trucks
            </button>
          </ButtonWrapper>
        </SelectorGroup>
      </Selector>
      <OfferList>
        {tab === 1 && spotloads ? (
          <SpotloadOffers spotloads={spotloads} />
        ) : (
          tab === 1 && !spotloads && <Loader />
        )}
        {tab === 2 && roundtrips ? (
          <RoundtripOffers roundtrips={roundtrips} />
        ) : (
          tab === 2 && !roundtrips && <Loader />
        )}
        {tab === 3 && tenders ? (
          <TenderOffers tenders={tenders} />
        ) : (
          tab === 3 && !tenders && <Loader />
        )}
        {tab === 4 && emptytrucks ? (
          <EmptyTruckOffers emptytrucks={emptytrucks} />
        ) : (
          tab === 4 && !emptytrucks && <Loader />
        )}
        <LinkWrapper centered margin="2rem 0">
          <Link
            to={`${
              tab === 1
                ? "/spotload"
                : tab === 2
                ? "/roundtrip"
                : tab === 3
                ? "/tender"
                : "/trucks"
            }`}
            style={{
              color: "#000",
              padding: "0.25rem 1.5rem",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            View all offers
          </Link>
          {USER && (USER_PLAN !== "Expired" || USER_PLAN !== "Cancelled") ? (
            <>
              <CreateButton
                title="Add your offer"
                to={
                  tab === 1
                    ? "/dashboard/spotload/create"
                    : tab === 2
                    ? "/dashboard/roundtrip/create"
                    : tab === 3
                    ? "/dashboard/tender/create"
                    : "/dashboard/trucks/create"
                }
              />
            </>
          ) : USER && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled") ? (
            <>
              <CreateButton
                title="Add your offer"
                isButton
                onClick={() => setExpiredModalOpen(true)}
              />
            </>
          ) : (
            <CreateButton
              title="Add your offer"
              isButton
              onClick={() => setLoggedModalOpen(true)}
            />
          )}
        </LinkWrapper>
      </OfferList>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
    </Wrapper>
  );
};

const activeArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14"
    viewBox="0 0 18 14"
  >
    <path
      d="M9,0l9,14H0Z"
      transform="translate(18 14) rotate(180)"
      fill="#ef4900"
    />
  </svg>
);

export default Offers;
