import React from "react";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { StandardQuillWrapper } from "./Styles";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  ErrorContainer,
  ErrorWrapper,
} from "../../CreateForm/Styles";

const StandardQuill = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <StandardQuillWrapper>
            <QuillEditor
              theme="snow"
              name={props.name}
              value={props.value || ""}
              modules={{
                toolbar: [],
              }}
              formats={formats}
              className={props.error && "invalid"}
              onChange={(e) => props.onInputChange(e, props.name)}
              placeholder={props.placeholder || "Type your message..."}
            />
          </StandardQuillWrapper>
          {props.error && (
            <ErrorWrapper>
              <span>{props.error.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

const formats = ["header", "bold", "italic"];

export default StandardQuill;
