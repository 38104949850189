import React from "react";
import RadioGroup from "../../CreateForm/RadioGroup";
import RadioInput from "../../CreateForm/RadioInput";
import NumberInput from "../../CreateForm/NumberInput";
import CheckboxGroup from "../../CreateForm/CheckboxGroup";
import Checkbox from "../../CreateForm/Checkbox";
import { Wrapper, InlineWrapper } from "../../CreateForm/Styles";
import StandardQuill from "../../UI/StandardQuill/";

const RoundtripCreatorStepTwo = (props) => {
  return (
    <Wrapper>
      <InlineWrapper size="small">
        <NumberInput
          label="Price per km"
          name="kmPrice"
          placeholder="Price"
          /* min={0} */
          value={
            props.inputs?.price?.kmPrice
              ? props.inputs?.price?.kmPrice
              : props.inputs?.price?.kmPrice === 0
              ? 0
              : ""
          }
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "price")
          }
          error={props.errors?.kmPrice}
        />
        <RadioGroup error={props.errors?.priceType}>
          <InlineWrapper>
            <RadioInput
              name="priceType"
              value="all inclusive"
              onInputChange={(value, name) =>
                props.onInputChange(value, name, "price")
              }
              inputs={props.inputs?.price}
            />
            <RadioInput
              name="priceType"
              value="+ road tolls"
              onInputChange={(value, name) =>
                props.onInputChange(value, name, "price")
              }
              inputs={props.inputs?.price}
            />
          </InlineWrapper>
        </RadioGroup>
      </InlineWrapper>
      <NumberInput
        label="Average km per month"
        name="kmPerMonth"
        placeholder="Km"
        min={0}
        value={props.inputs?.kmPerMonth || ""}
        onInputChange={props.onInputChange}
        error={props.errors?.kmPerMonth}
      />
      <NumberInput
        label="Payment time in days"
        name="paymentTime"
        placeholder="Days"
        min={0}
        value={props.inputs?.payment?.paymentTime || ""}
        onInputChange={(value, name) =>
          props.onInputChange(value, name, "payment")
        }
        error={props.errors?.paymentTime}
      />
      <RadioGroup
        label="Payment after receiving document"
        error={props.errors?.documents}
      >
        <InlineWrapper>
          <RadioInput
            name="documents"
            value="Originals"
            onInputChange={(value, name) =>
              props.onInputChange(value, name, "payment")
            }
            inputs={props.inputs?.payment}
          />
          <RadioInput
            name="documents"
            value="Copy"
            onInputChange={(value, name) =>
              props.onInputChange(value, name, "payment")
            }
            inputs={props.inputs?.payment}
          />
        </InlineWrapper>
      </RadioGroup>
      <CheckboxGroup
        label="Skonto payment possibility"
        error={props.errors?.skonto}
      >
        <Checkbox
          label="Yes"
          name="skonto"
          value="skonto"
          inputs={props.inputs?.payment}
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "payment")
          }
        />
      </CheckboxGroup>
      <CheckboxGroup label="Diesel card" error={props.errors?.dieselCard}>
        <Checkbox
          label="Yes"
          name="dieselCard"
          value="dieselCard"
          inputs={props.inputs?.payment}
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "payment")
          }
        />
      </CheckboxGroup>
      <StandardQuill
        label="Payment comment"
        name="paymentComment"
        placeholder="Add additional information regarding payment"
        value={props.inputs?.payment?.paymentComment}
        onInputChange={(value, name) =>
          props.onInputChange(value, name, "payment")
        }
        error={props.errors?.paymentComment}
      />
    </Wrapper>
  );
};

export default RoundtripCreatorStepTwo;
