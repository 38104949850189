import React, { useState, useEffect } from "react";
import SubMenu from "../Menu/SubMenu";
import { NavLink, Switch, Route, Redirect, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getOffers,
  changeStatus,
  removeOffer,
} from "../../../store/actions/dashboard/offers";
import welcomeOffers from "../../../assets/welcome/offers.svg";
import Loader from "../UI/Loader";

import Spotloads from "./Spotloads";
import Tenders from "./Tenders";
import Roundtrips from "./Roundtrips";
import UserNotice from "../../UserNotice/UserNotice";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import LeftDrawer from "../Drawers/LeftDrawer";
import Navbar from "../Navbar";
import Screen from "../Screen/Screen";
import ScreenBlock from "../Screen/ScreenBlock";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";
import EmptyTrucks from "./EmptyTrucks";

const MyOffersWrapper = (props) => {
  const [redirectUrl, setRedirectUrl] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [expiredModal, setExpiredModal] = useState(false);
  const OFFERS = useSelector((state) => state.dashboard.offers?.data);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Set first route to either spotloads, tenders or roundtrips
  useEffect(() => {
    const isOffersPage =
      props.location.pathname === "/dashboard/my-offers" ||
      props.location.pathname === "/dashboard/my-offers/";
    if (isOffersPage && OFFERS && OFFERS[2]?.spotloads?.length > 0) {
      setRedirect(true);
      setRedirectUrl("/dashboard/my-offers/spotloads");
      return;
    }
    if (isOffersPage && OFFERS && OFFERS[0]?.tenders?.length > 0) {
      setRedirect(true);
      setRedirectUrl("/dashboard/my-offers/tenders");
      return;
    }
    if (isOffersPage && OFFERS && OFFERS[1]?.roundtrips?.length > 0) {
      setRedirect(true);
      setRedirectUrl("/dashboard/my-offers/roundtrips");
      return;
    }
    if (isOffersPage && OFFERS && OFFERS[3]?.emptyTrucks?.length > 0) {
      setRedirect(true);
      setRedirectUrl("/dashboard/my-offers/emptyTrucks");
      return;
    }
  }, [props.location.pathname, OFFERS]);

  // Fetch current offers
  useEffect(() => {
    if (!OFFERS) {
      dispatch(getOffers());
    }
  }, [OFFERS, dispatch]);

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  // Change handler for changing the status of an offer

  const handleStatusChange = (id, newStatus, type, array) =>
    dispatch(changeStatus(id, newStatus, type, array));

  // Delete handler for deleting an offer

  const handleOfferRemove = (id, array) => dispatch(removeOffer(id, array));

  return (
    <>
      <Screen>
        <Navbar />
        {/* Display Welcome mat when no tenders, exhanges, roundtrips, empty trucks : else show menu */}
        {OFFERS &&
        OFFERS[3]?.emptyTrucks &&
        OFFERS[3]?.emptyTrucks?.length <= 0 &&
        OFFERS[2]?.spotloads &&
        OFFERS[2]?.spotloads?.length <= 0 &&
        OFFERS[1]?.roundtrips &&
        OFFERS[1]?.roundtrips?.length <= 0 &&
        OFFERS[0]?.tenders &&
        OFFERS[0]?.tenders?.length <= 0 ? (
          <ScreenBlock>
            <UserNotice
              image={welcomeOffers}
              title="Start by adding your first tender, spot freight or roundtrip"
              isExpired={isExpired}
              buttons={[
                {
                  name: "Add tender",
                  isRouter: true,
                  to: "/dashboard/tender/create",
                },
                {
                  name: "Add spot freight",
                  isRouter: true,
                  to: "/dashboard/spotload/create",
                },
                {
                  name: "Add roundtrip",
                  isRouter: true,
                  to: "/dashboard/roundtrip/create",
                },
              ]}
            />
          </ScreenBlock>
        ) : OFFERS ? (
          <>
            {redirect && <Redirect to={redirectUrl} />}
            <SubMenu>
              {OFFERS &&
              OFFERS[2]?.spotloads &&
              OFFERS[2]?.spotloads?.length > 0 ? (
                <NavLink
                  activeClassName="active"
                  exact
                  to="/dashboard/my-offers/spotloads"
                >
                  Load exchange ({OFFERS[2]?.spotloads?.length})
                </NavLink>
              ) : (
                <Link to="/dashboard/spotload/create">Add spot freight</Link>
              )}
              {OFFERS &&
              OFFERS[0]?.tenders &&
              OFFERS[0]?.tenders?.length > 0 ? (
                <NavLink
                  activeClassName="active"
                  to="/dashboard/my-offers/tenders"
                >
                  Tenders ({OFFERS[0]?.tenders?.length})
                </NavLink>
              ) : (
                <Link to="/dashboard/tender/create">Add tender</Link>
              )}
              {OFFERS &&
              OFFERS[1]?.roundtrips &&
              OFFERS[1]?.roundtrips?.length > 0 ? (
                <NavLink
                  activeClassName="active"
                  to="/dashboard/my-offers/roundtrips"
                >
                  Roundtrips ({OFFERS[1]?.roundtrips?.length})
                </NavLink>
              ) : (
                <Link to="/dashboard/roundtrip/create">Add roundtrip</Link>
              )}
              {OFFERS &&
              OFFERS[3]?.emptyTrucks &&
              OFFERS[3]?.emptyTrucks?.length > 0 ? (
                <NavLink
                  activeClassName="active"
                  to="/dashboard/my-offers/emptytrucks"
                >
                  Empty trucks ({OFFERS[3]?.emptyTrucks?.length})
                </NavLink>
              ) : (
                <Link to="/dashboard/trucks/create">Add empty truck</Link>
              )}
            </SubMenu>
            <Switch>
              {OFFERS &&
                OFFERS[0]?.tenders &&
                OFFERS[0]?.tenders?.length > 0 && (
                  <Route
                    path="/dashboard/my-offers/tenders"
                    render={() => (
                      <Tenders
                        isExpired={isExpired}
                        expiredModal={expiredModal}
                        setExpiredModal={setExpiredModal}
                        OFFERS={OFFERS}
                        handleStatusChange={handleStatusChange}
                        handleOfferRemove={handleOfferRemove}
                      />
                    )}
                  />
                )}
              {OFFERS &&
                OFFERS[1]?.roundtrips &&
                OFFERS[1]?.roundtrips?.length > 0 && (
                  <Route
                    path="/dashboard/my-offers/roundtrips"
                    render={() => (
                      <Roundtrips
                        isExpired={isExpired}
                        expiredModal={expiredModal}
                        setExpiredModal={setExpiredModal}
                        OFFERS={OFFERS}
                        handleStatusChange={handleStatusChange}
                        handleOfferRemove={handleOfferRemove}
                      />
                    )}
                  />
                )}
              {OFFERS &&
                OFFERS[2]?.spotloads &&
                OFFERS[2]?.spotloads?.length > 0 && (
                  <Route
                    exact
                    path="/dashboard/my-offers/spotloads"
                    render={() => (
                      <Spotloads
                        isExpired={isExpired}
                        expiredModal={expiredModal}
                        setExpiredModal={setExpiredModal}
                        OFFERS={OFFERS}
                        handleStatusChange={handleStatusChange}
                        handleOfferRemove={handleOfferRemove}
                      />
                    )}
                  />
                )}
              {OFFERS &&
                OFFERS[3]?.emptyTrucks &&
                OFFERS[3]?.emptyTrucks?.length > 0 && (
                  <Route
                    exact
                    path="/dashboard/my-offers/emptytrucks"
                    render={() => (
                      <EmptyTrucks
                        isExpired={isExpired}
                        expiredModal={expiredModal}
                        setExpiredModal={setExpiredModal}
                        OFFERS={OFFERS}
                        handleStatusChange={handleStatusChange}
                        handleOfferRemove={handleOfferRemove}
                      />
                    )}
                  />
                )}
            </Switch>
          </>
        ) : (
          <Loader />
        )}
        {isBrowser && <Minimessages />}
        <LeftDrawer />
        <DashboardFooter />
      </Screen>
      {expiredModal && (
        <SubscribeGateway onClose={() => setExpiredModal(false)} />
      )}
    </>
  );
};

export default MyOffersWrapper;
