import React from "react";
import { SheetTabsWrapper } from "./Styles";

const SheetTab = (props) => {
  return (
    <SheetTabsWrapper>
      {!props.detailsTab ? (
        <button type="button" onClick={() => props.setDetailsTab(true)}>
          {detailsIcon}
          Back
        </button>
      ) : (
        <button type="button" onClick={() => props.setDetailsTab(false)}>
          {mapIcon}
          Map
        </button>
      )}
    </SheetTabsWrapper>
  );
};

const mapIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.728"
    height="19"
    viewBox="0 0 15.728 19"
  >
    <g transform="translate(-15827.5 21994.5)">
      <g transform="translate(15825 -21995)">
        <path
          d="M17.727,8.364C17.727,14.091,10.364,19,10.364,19S3,14.091,3,8.364a7.364,7.364,0,1,1,14.727,0Z"
          fill="none"
          stroke="#262a68"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(8 6)"
          strokeWidth="1"
          stroke="#262a68"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </g>
  </svg>
);

const detailsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.889"
    height="10.523"
    viewBox="0 0 14.889 10.523"
  >
    <g transform="translate(-2.898 -5.5)">
      <line
        x2="13.888"
        transform="translate(3.399 6)"
        fill="none"
        stroke="#262a68"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="2.381"
        transform="translate(3.399 10.762)"
        fill="none"
        stroke="#262a68"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.936"
        transform="translate(8.954 10.762)"
        fill="none"
        stroke="#262a68"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="6.349"
        transform="translate(3.399 15.523)"
        fill="none"
        stroke="#262a68"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M.01,0"
        transform="translate(3 10.762)"
        fill="none"
        stroke="#262a68"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default SheetTab;
