import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSpotloads } from "../../../store/actions/app/spotload";
import { getTenders } from "../../../store/actions/app/tender";
import { getRoundtrips } from "../../../store/actions/app/roundtrip";

import CompanyProgress from "../MyCompany/CompanyProgress";
import Card from "../UI/Card";
import CreateButton from "../UI/CreateButton";
import Loader from "../UI/Loader";

import iSpotloads from "../../../assets/icons/latest-spotloads.svg";
import iTenders from "../../../assets/icons/latest-tenders.svg";
import iRoundtrips from "../../../assets/icons/latest-roundtrips.svg";
import iEmptyTrucks from "../../../assets/icons/latest-emptytrucks.svg";
import ScrollToTopOnMount from "../../../helpers/ScrollToTop";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import LeftDrawer from "../Drawers/LeftDrawer";
import Screen from "../Screen/Screen";
import Navbar from "../Navbar/";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";
import { getEmptyTrucks } from "../../../store/actions/app/emptytruck";

const Main = (props) => {
  const [isExpired, setIsExpired] = useState(false);
  const [expiredModal, setExpiredModal] = useState(false);
  const dispatch = useDispatch();
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const LATEST_SPOTLOADS = useSelector((state) => state.app.spotload?.data);
  const LATEST_TENDERS = useSelector((state) => state.app.tender?.data);
  const LATEST_ROUNDTRIPS = useSelector((state) => state.app.roundtrip?.data);
  const LATEST_EMPTYTRUCKS = useSelector(
    (state) => state.app.emptytrucks?.data
  );

  useEffect(() => {
    // Get tenders if they don't already exist
    if (!LATEST_TENDERS) {
      dispatch(getTenders());
    }
    // Get roundtrips if they don't already exist
    if (!LATEST_ROUNDTRIPS) {
      dispatch(getRoundtrips());
    }
    if (!LATEST_SPOTLOADS) {
      dispatch(getSpotloads());
    }
    if (!LATEST_EMPTYTRUCKS) {
      dispatch(getEmptyTrucks());
    }
  }, [LATEST_SPOTLOADS, LATEST_TENDERS, LATEST_ROUNDTRIPS]);

  // Set subscription barrier
  // TODO: SET CORRECT ENDPOINT FOR USER_PLAN
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  return (
    <>
      <Screen>
        <ScrollToTopOnMount />
        <Navbar />
        {USER && USER.privileges === "Admin" && (
          <CompanyProgress COMPANY={COMPANY} USER={USER} />
        )}
        <div className="Card-inline">
          <Card title="Latest spot freights" icon={iSpotloads}>
            {LATEST_SPOTLOADS ? (
              <div className="Latest">
                {LATEST_SPOTLOADS.slice(0, 3).map((e) => {
                  return (
                    <Link
                      to={{
                        pathname: "/spotload",
                        state: {
                          sheet: e._id,
                        },
                      }}
                      key={e._id}
                    >
                      <h5>
                        {e.loading?.locations[0]?.locationName} -{" "}
                        {e.unloading?.locations[0]?.locationName}
                      </h5>
                    </Link>
                  );
                })}
              </div>
            ) : (
              !LATEST_ROUNDTRIPS && <Loader />
            )}

            {!isExpired ? (
              <CreateButton
                centered
                title="Create Spot freight"
                to="/dashboard/spotload/create"
              />
            ) : (
              <CreateButton
                centered
                title="Create Spot freight"
                isButton
                onClick={() => setExpiredModal(true)}
              />
            )}
            <div className="all-button">
              <Link to="/spotload">Load exchange</Link>
            </div>
          </Card>
          <Card title="Latest tenders" icon={iTenders}>
            {LATEST_TENDERS ? (
              <div className="Latest">
                {LATEST_TENDERS.slice(0, 3).map((e) => {
                  return (
                    <Link
                      to={{
                        pathname: "/tender",
                        state: {
                          sheet: e._id,
                        },
                      }}
                      key={e._id}
                    >
                      <h5>
                        {e.loading[0]?.locationName} -{" "}
                        {e.unloading[0]?.locationName}
                      </h5>
                    </Link>
                  );
                })}
              </div>
            ) : (
              !LATEST_TENDERS && <Loader />
            )}

            {!isExpired ? (
              <CreateButton
                centered
                title="Create Tender"
                to="/dashboard/tender/create"
              />
            ) : (
              <CreateButton
                centered
                title="Create Tender"
                isButton
                onClick={() => setExpiredModal(true)}
              />
            )}
            <div className="all-button">
              <Link to="/tender">All tenders</Link>
            </div>
          </Card>
        </div>
        <div className="Card-inline">
          <Card title="Latest roundtrips" icon={iRoundtrips}>
            {LATEST_ROUNDTRIPS ? (
              <div className="Latest">
                {LATEST_ROUNDTRIPS.slice(0, 3).map((roundtrip) => {
                  return (
                    <Link
                      to={{
                        pathname: "/roundtrip",
                        state: {
                          sheet: roundtrip._id,
                        },
                      }}
                      key={roundtrip._id}
                    >
                      <h5>{roundtrip.name}</h5>
                    </Link>
                  );
                })}
              </div>
            ) : (
              !LATEST_ROUNDTRIPS && <Loader />
            )}

            {/* {props.ROUNDTRIPS_FETCH_ERROR && <ErrorClosure />} */}
            {!isExpired ? (
              <CreateButton
                centered
                title="Create Roundtrip"
                to="/dashboard/roundtrip/create"
              />
            ) : (
              <CreateButton
                centered
                title="Create Roundtrip"
                isButton
                onClick={() => setExpiredModal(true)}
              />
            )}
            <div className="all-button">
              <Link to="/roundtrip">All roundtrips</Link>
            </div>
          </Card>
          <Card title="Latest empty trucks" icon={iEmptyTrucks}>
            {LATEST_EMPTYTRUCKS ? (
              <div className="Latest">
                {LATEST_EMPTYTRUCKS.slice(0, 3).map((truck) => {
                  return (
                    <Link
                      to={{
                        pathname: "/trucks",
                        state: {
                          sheet: truck._id,
                        },
                      }}
                      key={truck._id}
                    >
                      <h5>
                        {truck.loading.locations[0].locationName} -{" "}
                        {truck.unloading.locations[0].locationName}
                      </h5>
                    </Link>
                  );
                })}
              </div>
            ) : (
              !LATEST_EMPTYTRUCKS && <Loader />
            )}

            {/* {props.ROUNDTRIPS_FETCH_ERROR && <ErrorClosure />} */}
            {!isExpired ? (
              <CreateButton
                centered
                title="Add Empty Truck"
                to="/dashboard/trucks/create"
              />
            ) : (
              <CreateButton
                centered
                title="Add Empty Truck"
                isButton
                onClick={() => setExpiredModal(true)}
              />
            )}
            <div className="all-button">
              <Link to="/trucks">All empty trucks</Link>
            </div>
          </Card>
        </div>
        <DashboardFooter />
      </Screen>
      {expiredModal && (
        <SubscribeGateway onClose={() => setExpiredModal(false)} />
      )}
      {isBrowser && <Minimessages />}
      <LeftDrawer />
    </>
  );
};

export default Main;
