import React, { useState } from "react";
import {
  FilterWrapper,
  FilterWrapperInner,
  FilterToggler,
  FilterItemWrapper,
  ViewResults,
} from "../../PageWithList/Filter/Styles";
import FilterOptions from "../../PageWithList/Filter/FilterOptions";
import TrucktypeFilter from "../../PageWithList/Filter/TrucktypeFilter";
import CountryFilter from "../../PageWithList/Filter/CountryFilter";

import { isMobile } from "react-device-detect";

const Filter = React.memo((props) => {
  const [open, setOpen] = useState(false);

  const handleInputChange = (value, type) => {
    props.setFilters({ ...props.filters, [type]: value });
  };

  const handleClearFilters = () => {
    props.setFilters({});
    setOpen(false);
  };

  return (
    <FilterWrapper fixed={props.fixed}>
      <FilterWrapperInner>
        <FilterToggler
          open={open}
          onClick={() => setOpen(!open)}
          fixed={props.fixed}
        >
          {filterIcon}
          <h4>{isMobile ? "Open filters" : "Filters"}</h4>
          {arrowDown}
        </FilterToggler>
        <FilterOptions
          open={open}
          fixed={props.fixed}
          clearFilters={handleClearFilters}
        >
          <FilterItemWrapper marginMobile="0 0 1rem 0">
            <TrucktypeFilter
              type="truckType"
              placeholder="Truck type"
              onSelectChange={handleInputChange}
              filters={props.filters}
            />
          </FilterItemWrapper>
          <FilterItemWrapper>
            <CountryFilter
              type="country"
              placeholder="Select countries"
              onSelectChange={handleInputChange}
              filters={props.filters}
            />
          </FilterItemWrapper>
          <ViewResults>
            <button type="button" onClick={() => setOpen(!open)}>
              View results
            </button>
          </ViewResults>
        </FilterOptions>
      </FilterWrapperInner>
    </FilterWrapper>
  );
});

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="19"
    viewBox="0 0 31 19"
  >
    <g transform="translate(1)">
      <g>
        <line
          x2="5"
          transform="translate(0 4.5)"
          fill="none"
          stroke="#9aabc2"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          x2="15"
          transform="translate(14 4.5)"
          fill="none"
          stroke="#9aabc2"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <g
          transform="translate(4.5)"
          fill="none"
          stroke="#9aabc2"
          strokeWidth="2"
        >
          <rect width="10" height="9" rx="1" stroke="none" />
          <rect x="1" y="1" width="8" height="7" fill="none" />
        </g>
      </g>
      <g>
        <line
          x2="14"
          transform="translate(0 14.5)"
          fill="none"
          stroke="#9aabc2"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          x2="6"
          transform="translate(23 14.5)"
          fill="none"
          stroke="#9aabc2"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <g
          transform="translate(13.5 10)"
          fill="none"
          stroke="#9aabc2"
          strokeWidth="2"
        >
          <rect width="10" height="9" rx="1" stroke="none" />
          <rect x="1" y="1" width="8" height="7" fill="none" />
        </g>
      </g>
    </g>
  </svg>
);

const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.629"
    height="12.976"
    viewBox="0 0 20.629 12.976"
  >
    <path
      d="M1124.658,207.71l9.224,9.762,9.224-9.762"
      transform="translate(-1123.568 -206.68)"
      fill="none"
      stroke="#0f1540"
      strokeWidth="3"
    />
  </svg>
);

export default Filter;
