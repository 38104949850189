import React from "react";
import Modal from "../Modal";
import Header from "../Header";
import { ModalInnerWrapper, Centered } from "../Styles";
import LargeButton from "../../CreateForm/LargeButton";

const LoginGateway = (props) => {
  return (
    <Modal onClose={props.onClose} small>
      <Header onClose={props.onClose} />
      <ModalInnerWrapper>
        <Centered>
          {icon}
          {!props.offerGateway ? (
            <h3>
              In order to access TransConnector portal, please log in or
              register an account
            </h3>
          ) : (
            <h3>
              This offer is only visible to users who have an account on
              TransConnector portal. In order to get more info log in or create
              an account.
            </h3>
          )}
          <LargeButton to="/log-in" label="Log in" secondary centered />{" "}
          <LargeButton to="/sign-up" label="Register" secondary centered />
        </Centered>
      </ModalInnerWrapper>
    </Modal>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="259"
    height="250"
    viewBox="0 0 259 250"
  >
    <g transform="translate(-11121 21194)">
      <circle
        cx="125"
        cy="125"
        r="125"
        transform="translate(11130 -21194)"
        fill="#fbfbfb"
      />
      <g transform="translate(10332.825 -21533.375)">
        <path
          d="M802.11,404.272h26.064v14.066h7.574v-5.41h6.372v5.41h6.973V404.272h10.46"
          transform="translate(22.087 67.329)"
          fill="#e2e5ec"
        />
        <path
          d="M869.652,372.342a38.245,38.245,0,0,0-38.2,38.2v8.306H821.9v-8.306A47.8,47.8,0,0,1,869.652,362.8"
          transform="translate(53.462 1.585)"
          fill="#e2e5ec"
        />
        <path
          d="M908.863,436.357H825.915a2.585,2.585,0,1,1,0-5.17h82.947a2.585,2.585,0,1,1,0,5.17Z"
          transform="translate(55.723 109.992)"
          fill="#2f4265"
        />
        <path
          d="M845.476,436.357H804a2.585,2.585,0,1,1,0-5.17h41.472a2.585,2.585,0,0,1,0,5.17Z"
          transform="translate(20.991 109.992)"
          fill="#2f4265"
        />
        <path
          d="M906.3,436.357H864.825a2.585,2.585,0,1,1,0-5.17H906.3a2.585,2.585,0,1,1,0,5.17Z"
          transform="translate(117.399 109.992)"
          fill="#2f4265"
        />
        <rect
          width="118.304"
          height="24.47"
          transform="translate(861.925 499.254)"
          fill="#e2e5ec"
        />
        <path
          d="M939.178,490.512H815.7v-24.6a2.585,2.585,0,0,1,5.17,0v19.429H934.008V389.549H820.874v25.065a2.585,2.585,0,0,1-5.17,0V384.379H939.178Z"
          transform="translate(43.636 35.797)"
          fill="#2f4265"
        />
        <path
          d="M921.571,423.02H906.852V412.129a35.615,35.615,0,1,0-71.229,0V423.02H820.9V412.129a50.334,50.334,0,1,1,100.668,0Zm-9.549-5.17H916.4v-5.721a45.164,45.164,0,1,0-90.328,0v5.721h4.379v-5.721a40.785,40.785,0,1,1,81.57,0Z"
          transform="translate(51.876 0)"
          fill="#2f4265"
        />
        <path
          d="M841.171,437.958a2.583,2.583,0,0,1-2.585-2.585V407.051a2.585,2.585,0,1,1,5.17,0v28.322A2.583,2.583,0,0,1,841.171,437.958Z"
          transform="translate(79.905 67.637)"
          fill="#2f4265"
        />
        <path
          d="M847.9,423.1a13.568,13.568,0,1,1,13.567-13.569A13.583,13.583,0,0,1,847.9,423.1Zm0-21.966a8.4,8.4,0,1,0,8.4,8.4A8.406,8.406,0,0,0,847.9,401.139Z"
          transform="translate(73.172 54.168)"
          fill="#2f4265"
        />
        <path
          d="M840.374,362.8"
          transform="translate(82.74 1.585)"
          fill="#e2e5ec"
        />
        <path
          d="M876.35,436.231H865.161l-2.585-5.411h-3.11l-2.585,5.411H845.088V422.163h-19.1a19.728,19.728,0,1,1,.23-15.086h58.009v15.086H876.35Zm-7.926-5.17h2.755V416.993h7.874v-4.746H822.409l-.533-1.882a14.536,14.536,0,1,0-.142,8.422l.579-1.794h27.945v14.068h3.361l2.585-5.411h9.635Z"
          transform="translate(0 52.021)"
          fill="#2f4265"
        />
        <path
          d="M800.628,415.562a8.221,8.221,0,1,1,8.22-8.221A8.23,8.23,0,0,1,800.628,415.562Zm0-11.271a3.05,3.05,0,1,0,3.05,3.05A3.055,3.055,0,0,0,800.628,404.291Z"
          transform="translate(6.705 59.165)"
          fill="#2f4265"
        />
      </g>
    </g>
  </svg>
);

export default LoginGateway;
