import React from "react";
import { ScreenWrapper, ScreenWrapperInner } from "./Styles";

const Screen = ({ small, centered, children }) => {
  return (
    <ScreenWrapper small={small ? 1 : null} centered={centered ? 1 : null}>
      <ScreenWrapperInner>{children}</ScreenWrapperInner>
    </ScreenWrapper>
  );
};

export default Screen;
