import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CookieNotice = () => {
  const [hide, setHide] = useState(false);

  if (hide) return null;
  return (
    <Wrapper>
      <Inner>
        <h3>
          This site uses cookies explained in our{" "}
          <Link to="/privacy">privacy policy</Link>. By continuing to browse or
          by clicking "Accept" you agree to the storing of cookies on your
          device
        </h3>
        <button
          type="button"
          onClick={() => {
            localStorage.setItem("cookie_accept", true);
            setHide(true);
          }}
        >
          Accept
        </button>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 99999999999999;
  width: calc(100% - 1rem);
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #b1c4dd;
`;
const Inner = styled.div`
  max-width: 1380px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  h3 {
    font-size: 15px;
    color: #686a7d;
    font-weight: 400;
    margin-right: 1.5rem;
    text-align: center;
  }
  a {
    color: #262a68;
  }
  button {
    display: flex;
    cursor: pointer;
    background: #fff;
    color: #262a68;
    border-radius: 4px;
    border: 2px solid #262a68;
    padding: 1rem 3rem;
    font-family: "Inter";
    font-weight: 600;
    height: max-content;
  }
`;

export default CookieNotice;
