import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  background: #fbfdff;
  margin-right: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  @media (min-width: 1200px) {
    max-width: 1111px;
    padding: 2rem 2rem 2rem 4rem;
    margin: 6rem 0;
  }
  @media (min-width: 1440px) {
    max-width: calc(1286px + 134px);
    padding: 3rem 3rem 3rem 6rem;
  }
  @media (min-width: 1740px) {
    max-width: calc(1556px + 134px);
    padding: 4rem 4rem 4rem 8rem;
  }
  @media (min-width: 992px) {
    margin: 5rem 0;
  }
`;
export const Inner = styled.div`
  margin: 2rem 0;
  h3 {
    font-size: 18px;
    text-align: center;
    color: #363636;
    margin: 0;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 992px) {
    margin: 4rem 0;
    h3 {
      font-size: 36px;
      text-align: left;
    }
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 50px;
    }
  }
`;
export const SelectionGroup = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto 2rem auto;
  max-width: 400px;
  @media (min-width: 992px) {
    max-width: 600px;
    margin: 3rem auto;
  }
  @media (min-width: 1200px) {
    max-width: 750px;
  }
`;
export const Selection = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  background: none;
  border: none;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      cursor: default;
      color: #363636;
      border-bottom: 1px solid #ef4900;
    `}
  @media (min-width: 992px) {
    font-size: 18px;
    letter-spacing: 8px;
    padding: 0.5rem 2rem;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
    letter-spacing: 12px;
    padding: 0.5rem 3rem;
  }
`;
export const List = styled.ul`
  margin: 0;
  padding: 0;
  li {
    color: #050720;
    list-style-type: none;
    padding: 0.75rem 0.5rem 0.75rem 1.5rem;
    position: relative;
    font-size: 13px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.7rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 0 9px 14px;
      border-color: transparent transparent transparent #00b27d;
    }
    @media (min-width: 992px) {
      font-size: 14px;
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
`;
