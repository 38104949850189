import React from "react";
import Modal from "../Modal";
import Header from "../Header";
import { ModalInnerWrapper, Centered } from "../Styles";

const AddReviewSent = (props) => {
  return (
    <Modal onClose={props.onClose} small>
      <Header onClose={props.onClose} />
      <ModalInnerWrapper>
        <Centered>
          {icon}
          <h1>Thank you for your feedback</h1>
          {props.review === 0 && (
            <p
              style={{
                color: "#363636",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "15px",
                lineHeight: "1.5",
                margin: 0,
              }}
            >
              Your feedback will be reviewed by TransConnector
            </p>
          )}
        </Centered>
      </ModalInnerWrapper>
    </Modal>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="156"
    height="156"
    viewBox="0 0 156 156"
  >
    <g transform="translate(-870 -269)">
      <circle
        cx="78"
        cy="78"
        r="78"
        transform="translate(870 269)"
        fill="#fbfbfb"
      />
      <g transform="translate(7 45)">
        <g
          transform="translate(903 264)"
          fill="none"
          stroke="#00B27E"
          strokeWidth="4"
        >
          <circle cx="38" cy="38" r="38" stroke="none" />
          <circle cx="38" cy="38" r="36" fill="none" />
        </g>
        <path
          d="M13083.955-13612.08l10.612,10.611,20.426-20.426"
          transform="translate(-12158.68 13913.853)"
          fill="none"
          stroke="#00B27E"
          strokeLinecap="round"
          strokeWidth="6"
        />
      </g>
    </g>
  </svg>
);

export default AddReviewSent;
