import React from "react";
import { TableRowWrapper } from "./Styles";

const TableRow = (props) => {
  return (
    <TableRowWrapper
      clickable={props.onClick ? true : false}
      onClick={props.onClick}
    >
      {props.children}
    </TableRowWrapper>
  );
};

export default TableRow;
