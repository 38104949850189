import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { ADD_DOCUMENT } from "../../../store/actionTypes";

import "../../../styles/Dashboard/MyAccount/Documents.css";
import iDocuments from "../../../assets/icons/documents.svg";
import welcomeDocuments from "../../../assets/welcome/documents.svg";

import uuid from "uuid";
import Loader from "../UI/Loader";
import Table from "../UI/Table";
import FormBlock from "../UI/FormBlock";
import Form from "../UI/Form";
import Input from "../UI/Input";
import {
  addDocument,
  editDocument,
  removeDocument,
} from "../../../store/actions/dashboard/company";
import UserNotice from "../../UserNotice/UserNotice";
import CreateButton from "../UI/CreateButton";
import ScreenBlock from "../Screen/ScreenBlock";

const Documents = (props) => {
  const [componentState, setComponentState] = useState({
    showDocumentForm: false,
    loadingSubmission: false,
    saved: false,
    documentPreview: null,
  });
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { COMPANY } = props;

  // Handle uploading a document

  const inputChangeHandler = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const documentUploadHandler = (e) => {
    e.preventDefault();
    setComponentState((prevState) => ({
      ...prevState,
      saved: false,
      loadingSubmission: true,
    }));
    const formData = new FormData();
    formData.append("file", inputs.document);
    formData.append("name", inputs.documentName);
    dispatch(addDocument(formData))
      .then((res) => {
        // set current documents
        //props.onEditDocuments(res.data);
        dispatch({
          type: ADD_DOCUMENT,
          payload: res.data,
        });
        // clear inputs, errors, preview, saved, loader
        setInputs({});
        setErrors({});
        setComponentState((prevState) => ({
          ...prevState,
          saved: true,
          loadingSubmission: false,
          documentPreview: null,
        }));
        setTimeout(() => {
          setComponentState((prevState) => ({
            ...prevState,
            saved: false,
          }));
        }, 2000);
      })
      .catch((err) => {
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
        }));
        const errors = err?.response?.data?.errors;
        setErrors(errors);
      });
  };

  // Handle editing a document

  let editInputs = {};
  const editInputChangeHandler = (e) => {
    editInputs = { ...editInputs, [e.target.name]: e.target.value };
  };

  const editDocumentHandler = (e, id) => {
    e.preventDefault();
    if (Object.keys(editInputs)?.length > 0) {
      dispatch(editDocument(id, editInputs.documentName));
      //props.onEditDocumentName(id, editInputs.documentName);
    }
  };

  // Handle removing a document

  const removeDocumentHandler = (id) => {
    dispatch(removeDocument(id));
    //props.onRemoveDocument(id);
  };

  // ********** LOGIC FOR DROPZONE ********** //

  const onDrop = useCallback((acceptedFiles) => {
    setInputs((prevState) => ({ ...prevState, document: acceptedFiles[0] }));
    setComponentState((prevState) => ({
      ...prevState,
      documentPreview: acceptedFiles[0]?.name,
    }));
    setErrors((prevState) => ({ ...prevState, file: "" }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    //accept: "application/pdf",
    maxFiles: 1,
    maxSize: 5000000,
    onDrop,
  });

  // **************************************** //

  return (
    <ScreenBlock title="Documents" img={iDocuments}>
      {/* Create table of documents when there are documents present */}
      {COMPANY && COMPANY?.documents?.length > 0 ? (
        <Table
          head={[
            {
              key: uuid(),
              items: [{ name: "Document Name" }, { name: "Options" }],
            },
          ]}
          content={[
            {
              key: uuid(),
              items: COMPANY?.documents?.map((document) => {
                return {
                  key: document._id,
                  id: document._id,
                  item: [
                    {
                      mobileTitle: "Document Name",
                      name: document.name,
                      isLink: true,
                      linkTo: document.url,
                      isEditable: true,
                      inputs: [
                        {
                          InputType: "input",
                          type: "text",
                          name: "documentName",
                          value: document.name,
                          defaultValue: document.name,
                          placeholder: "Document name",
                          required: true,
                          onChange: (e) => editInputChangeHandler(e),
                        },
                      ],
                    },
                    {
                      options: [
                        {
                          key: uuid(),
                          items: [
                            {
                              name: "Edit",
                              editable: true,
                              onEdit: (e) =>
                                editDocumentHandler(e, document._id),
                            },
                            {
                              name: "Remove",
                              confirmationModal: true,
                              onConfirm: () =>
                                removeDocumentHandler(document._id),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                };
              }),
            },
          ]}
        />
      ) : (
        !COMPANY?.documents && <Loader />
      )}

      {COMPANY &&
        COMPANY?.documents?.length > 0 &&
        !componentState.showDocumentForm && (
          <CreateButton
            isButton
            title="Upload PDF documents"
            onClick={() =>
              setComponentState((prevState) => ({
                ...prevState,
                showDocumentForm: true,
              }))
            }
          />
        )}
      {componentState.showDocumentForm ? (
        <Form onSubmit={(e) => documentUploadHandler(e)}>
          <FormBlock title="Upload document (less than 5MB)">
            <div className="Select_File">
              <h4>Select file</h4>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Browse</p>
              </div>
            </div>
            {errors.file && (
              <div className="error">
                <p>{errors.file.msg}</p>
              </div>
            )}
            {componentState.documentPreview && (
              <div className="Files">
                <ul>
                  <li>{componentState.documentPreview}</li>
                </ul>
              </div>
            )}
            <div className="Display_Name">
              <Input
                InputType="input"
                type="text"
                placeholder="Display name"
                name="documentName"
                label="Display name"
                onChange={inputChangeHandler}
                value={inputs.documentName || ""}
                required={true}
              />
            </div>
          </FormBlock>
          <div className="submit">
            {!componentState.loadingSubmission ? (
              <input
                type="submit"
                value={componentState.saved ? "Uploaded" : "Upload"}
                disabled={componentState.saved ? true : false}
                className={componentState.saved ? "success" : ""}
              />
            ) : (
              <Loader />
            )}
          </div>
        </Form>
      ) : null}

      {/* Create Welcome mat when there are no documents */}
      {COMPANY &&
      COMPANY?.documents?.length <= 0 &&
      !componentState.showDocumentForm ? (
        <UserNotice
          image={welcomeDocuments}
          title="Upload and store your documents"
          buttons={[
            {
              name: "Upload a document",
              isButton: true,
              onClick: () =>
                setComponentState((prevState) => ({
                  ...prevState,
                  showDocumentForm: true,
                })),
            },
          ]}
        />
      ) : null}
    </ScreenBlock>
  );
};

export default Documents;
