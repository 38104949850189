import { SET_EMPTYTRUCKS } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

const token = getToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const getEmptyTrucks = () => {
  return (dispatch) => {
    axios.get(`${API_URL}/emptyTruck`, config).then((res) => {
      if (res.data && res.data?.length > 0) {
        dispatch({ type: SET_EMPTYTRUCKS, payload: res.data });
      } else {
        // TODO: SET ERROR
      }
    });
  };
};

export const getSingleEmptyTruck = (id) => {
  return () => {
    return axios.get(`${API_URL}/emptyTruck/${id}`, config);
  };
};

export const getEmptyTruckFilters = (params) => {
  return () => {
    return axios.get(`${API_URL}/emptyTruck`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
  };
};

export const createEmptyTruck = (method, data) => {
  return () => {
    return axios({
      method: method,
      url: `${API_URL}/emptyTruck`,
      data: data,
      ...config,
    });
  };
};

export const validateEmptyTruck = (data) => {
  return () => {
    return axios.post(`${API_URL}/emptytruckValidate`, data, config);
  };
};
