import React from "react";

import Hero from "../components/FrontPage/Hero/";
import Offers from "../components/FrontPage/Offers/Offers";
import Benefits from "../components/FrontPage/Benefits/Benefits";
import Testimonials from "../components/FrontPage/Testimonials/Testimonials";
import Blog from "../components/FrontPage/Blog/";
import Footer from "../components/Footer/Footer";
import Header from "../components/FrontPage/Header/Header";
import Accompaniments from "../components/FrontPage/Accompaniments";

const Home = ({ USER }) => {
  return (
    <div className="Home">
      <Header />
      <Hero USER={USER} />
      <Offers />
      <Accompaniments USER={USER} />
      <Benefits />
      <Testimonials />
      <Blog />
      <Footer />
    </div>
  );
};

export default Home;
