import React from "react";
import { FormBlockWrapper, FormBlockTitle } from "../CreateForm/Styles";

const FormBlock = (props) => {
  return (
    <FormBlockWrapper hide={props.step !== props.currentStep ? true : false}>
      {props.title && (
        <FormBlockTitle>
          <h2>{props.title}</h2>
        </FormBlockTitle>
      )}
      {props.children}
    </FormBlockWrapper>
  );
};

export default FormBlock;
