import React from "react";

import { Wrapper } from "./styles";

const Container = ({ background, gutter, children }) => (
  <Wrapper background={background} gutter={gutter}>
    {children}
  </Wrapper>
);

export default Container;
