import React, { useState } from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { RadiusWrapper } from "./Styles";

const SliderWithTooltip = createSliderWithTooltip(Slider);

const RadiusFilter = (props) => {
  const [displayValue, setDisplayValue] = useState(
    props.inputs?.[props.type] || 50
  );
  return (
    <RadiusWrapper>
      <h6>Radius ({`${displayValue}`}km)</h6>
      <SliderWithTooltip
        value={displayValue}
        defaultValue={50}
        onAfterChange={(e) => props.onInputChange(e, props.type)}
        onChange={(e) => setDisplayValue(e)}
        min={10}
        max={250}
        step={10}
        tipFormatter={(value) => <span className="tooltip">{value} km</span>}
      />
    </RadiusWrapper>
  );
};

export default RadiusFilter;
