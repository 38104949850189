import styled from "styled-components";

export const Wrapper = styled.nav`
  margin: 1.75rem 0;
  display: none;
  @media (min-width: 992px) {
    display: ${({ hide }) => (hide ? `none;` : `block;`)};
  }
`;

export const Inner = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  a {
    padding: 0.75rem 0.3rem;
    margin: 0 1.5rem;
    color: #0f1540;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    position: relative;
    &:hover {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0.4rem;
        background: #e13505;
        width: 100%;
        height: 3px;
      }
    }
  }
`;

export const InfoDropdown = styled.div`
  display: none;
  position: absolute;
  top: 150%;
  left: 0;
  background: #fff;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
  z-index: 11;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      transition: 0.15s all ease;
      a {
        display: inline-block;
        padding: 0.7rem;
        padding-right: 2rem;
        font-size: 0.85rem;
        font-weight: 500;
        margin: 0;
        background: none;
        color: #191919;
        text-transform: none;
        width: 100%;
        border-radius: 0;
        transition: 0.15s all ease;
        &:hover {
          transition: 0.15s all ease;
          background: #f9f9f9;
          text-decoration: none;
          &:before {
            display: none;
          }
        }
      }
      color: #191919;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const Info = styled.div`
  position: relative;
  &:hover {
    ${InfoDropdown} {
      display: block;
    }
  }
`;
