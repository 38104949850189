import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import EmptyTruckList from "./EmptyTruckList/EmptyTruckList";
import Filter from "./EmptyTruckFilter/Filter";
import EmptyTruckSheet from "./EmptyTruckSheet/EmptyTruckSheet";
import Modal from "../Modals/Modal";
import ModalHeader from "../Modals/Header";
import { withRouter } from "react-router-dom";
import Container from "../UI/Container";
import DashboardFooter from "../Dashboard/DashboardFooter";

const EmptyTruck = (props) => {
  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [allTrucks, setAllTrucks] = useState([]);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const USER = useSelector((state) => state.dashboard.user?.data);

  // If user has requested a truck via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    if (routeSheet && allTrucks?.length > 0) {
      if (!allTrucks.find((e) => e._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allTrucks]);

  console.log("filters", filters);

  const handleModalClose = () => {
    setShowRouteModal(false);
    setSheetID(null);
  };

  const handleSheetChange = useCallback((id) => {
    setSheetID(id);
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener("scroll", function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener("scroll", scrollEvt);
    }
  }, []);
  return (
    <>
      <Filter fixed={filterFixed} filters={filters} setFilters={setFilters} />
      <Container>
        <EmptyTruckList
          filters={filters}
          sheetID={sheetID}
          setSheetID={handleSheetChange}
          setAllTrucks={setAllTrucks}
          clearFilters={() => setFilters({})}
          USER={USER}
        />
        {allTrucks?.length > 0 && <DashboardFooter />}
      </Container>
      {allTrucks && sheetID && (
        <EmptyTruckSheet
          trucks={allTrucks}
          id={sheetID}
          setSheetID={handleSheetChange}
          USER={USER}
        />
      )}
      {showRouteModal && (
        <Modal onClose={handleModalClose} small>
          <ModalHeader
            title="Empty truck not found"
            onClose={handleModalClose}
          />
          <h4>The empty truck you requested was not found.</h4>
          <h5>Here are some reasons to why:</h5>
          <ul>
            <li>
              The empty truck you requested was removed or is currently
              inactive.
            </li>
            <li>The link you accessed was broken or missing.</li>
          </ul>
        </Modal>
      )}
    </>
  );
};

export default withRouter(EmptyTruck);
