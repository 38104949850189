import React from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  Input,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";

const Wrapper = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Input error={props.error}>
            <input
              type="text"
              name={props.name}
              value={props.value}
              placeholder={props.placeholder}
              onChange={(e) => props.onInputChange(e.target.value, props.name)}
            />
          </Input>
          {props.error && (
            <ErrorWrapper>
              <span>{props.error.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

export default Wrapper;
