import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ScreenBlock from "../Screen/ScreenBlock";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";
import Options from "../../NewTable/Options";

import moment from "moment";
import SendQuoteModal from "../../Modals/SendQuoteModal/SendQuoteModal";
import DashboardFooter from "../DashboardFooter";

const Sent = (props) => {
  const [modalInputs, setModalInputs] = useState({});
  const [optionsId, setOptionsId] = useState(null);
  const [updateModalID, setUpdateModalID] = useState(null);
  const { sentQuotations } = props.quotations;

  const handleOpenUpdateModal = (inputs, id) => {
    setModalInputs(inputs);
    setUpdateModalID(id);
  };

  return (
    <>
      <ScreenBlock title="Sent Quotations">
        <Table>
          <TableHeader>
            <TableHeaderItem flex="0.5">Loading</TableHeaderItem>
            <TableHeaderItem flex="0.5">Unloading</TableHeaderItem>
            <TableHeaderItem flex="0.3">Deadline</TableHeaderItem>
            <TableHeaderItem flex="0.4">Author</TableHeaderItem>
            <TableHeaderItem flex="0.3">Price</TableHeaderItem>
            <TableHeaderItem flex="0.3">Trucks</TableHeaderItem>
            <TableHeaderItem flex="0.3">Status</TableHeaderItem>
            <TableHeaderItem flex="0.1"></TableHeaderItem>
          </TableHeader>
          <TableContent>
            {sentQuotations.map((quotation) => {
              const { tender, offer, _id } = quotation;
              const loading = tender.loading[0];
              const unloading = tender.unloading[0];
              let status;
              if (offer.status === 0) {
                status = {
                  name: "Pending",
                  color: "#686A7D",
                };
              } else if (offer.status === 1) {
                status = {
                  name: "Accepted",
                  color: "#00B27E",
                };
              } else if (offer.status === 2) {
                status = {
                  name: "Rejected",
                  color: "#CE1E1E",
                };
              } else if (offer.status === 3) {
                status = {
                  name: "Archived",
                  color: "#8F8F8F",
                };
              }
              return (
                <TableRow
                  key={_id}
                  onClick={() =>
                    props.history.push({
                      pathname: "/tender",
                      state: {
                        sheet: tender._id,
                      },
                    })
                  }
                >
                  <TableRowItem
                    rowName="Loading"
                    image={{
                      url: loading.flagUrl,
                      alt: loading.country,
                    }}
                    title={{
                      name: loading.locationName,
                    }}
                    flex="0.5"
                  />
                  <TableRowItem
                    rowName="Unloading"
                    image={{
                      url: unloading.flagUrl,
                      alt: unloading.country,
                    }}
                    title={{
                      name: unloading.locationName,
                    }}
                    flex="0.5"
                  />
                  <TableRowItem
                    rowName="Deadline"
                    title={{
                      name: moment(tender.offerIsActive).format("DD.MM.YY"),
                    }}
                    flex="0.3"
                    mobileSameLine
                  />
                  <TableRowItem
                    rowName="Author"
                    title={{
                      name: `${offer.author}`,
                    }}
                    flex="0.4"
                    mobileSameLine
                  />
                  <TableRowItem
                    rowName="Price"
                    title={{
                      name: `${offer.price} €`,
                    }}
                    flex="0.3"
                    mobileSameLine
                  />
                  <TableRowItem
                    rowName="Period"
                    title={{
                      name: `${offer.noOfTrucks} x ${offer.period}`,
                    }}
                    flex="0.3"
                    mobileSameLine
                  />
                  <TableRowItem
                    rowName="Status"
                    title={{
                      ...status,
                    }}
                    flex="0.3"
                    mobileSameLine
                  />
                  <TableRowItem flex="0.1" overflow>
                    <Options
                      onClick={(e) => {
                        e.stopPropagation();
                        setOptionsId(offer._id);
                      }}
                      open={optionsId === offer._id}
                      onBlur={() => setOptionsId(null)}
                    >
                      <ul>
                        <li
                          onClick={() =>
                            handleOpenUpdateModal(
                              {
                                price: offer.price,
                                noOfTrucks: offer.noOfTrucks,
                                period: {
                                  value:
                                    offer.period !== "day"
                                      ? `${offer.period}ly`
                                      : "daily",
                                  label: offer.period,
                                },
                                comment: offer.comment,
                              },
                              offer._id
                            )
                          }
                        >
                          Edit
                        </li>
                      </ul>
                    </Options>
                  </TableRowItem>
                </TableRow>
              );
            })}
          </TableContent>
        </Table>
      </ScreenBlock>
      <DashboardFooter />
      {updateModalID && (
        <SendQuoteModal
          title="Update quote"
          updateQuote
          id={updateModalID}
          inputs={modalInputs}
          submitLabel="Update offer"
          successMessage="Offer updated successfully"
          onClose={() => setUpdateModalID(null)}
        />
      )}
    </>
  );
};

export default withRouter(Sent);
