import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../../styles/Dashboard/UI/CompanyProgress.css";
import iWarn from "../../../assets/icons/progress-warn-icon.svg";
import Loader from "../UI/Loader";

const CompanyProgress = (props) => {
  const [currentProgress, setCurrentProgress] = useState("");
  const { USER, COMPANY } = props;

  // Set current company progress

  useEffect(() => {
    if (COMPANY?.logo) {
      setCurrentProgress("50%");
    }
    if (COMPANY?.description) {
      setCurrentProgress("70%");
    }
    if (COMPANY?.fleet?.length > 0) {
      setCurrentProgress("100%");
    }
  }, [COMPANY]);

  if (currentProgress === "100%") {
    return <></>;
  }

  // Show loader when company data is not ready to be shown

  if (!COMPANY) {
    return (
      <div className="Card">
        <Loader />
      </div>
    );
  }

  return (
    <div className="Card Progress">
      <div className="Progress-header">
        <img src={iWarn} alt="" />
        <h3>
          Your <span>company profile</span> is {currentProgress} complete,
          please fill out the remaining data to gain more interest on
          TransConnector
        </h3>
        {USER?.privileges === "Admin" && (
          <Link to="/dashboard/my-account/company-profile">Update Profile</Link>
        )}
      </div>
      <div className="progress-bar">
        <h6>Logo 50%</h6>
        <h6>Description 70%</h6>
        <h6>Fleet 100%</h6>
        <div
          className={`progress-line ${currentProgress === "50%" ? "one" : ""} ${
            currentProgress === "70%" ? "two" : ""
          }`}
        ></div>
      </div>
    </div>
  );
};

export default CompanyProgress;
