import React from "react";
import { LocationWrapper, selectStyles } from "./Styles";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { API_URL } from "../../../constants";

const LocationFilter = (props) => {
  // Load async location options
  const loadOptions = async (query) => {
    const response = await axios.get(`${API_URL}/filterlocations`, {
      params: {
        query: query,
      },
    });
    const json = await response;
    return json.data;
  };
  return (
    <LocationWrapper>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        isClearable
        placeholder={props.placeholder}
        noOptionsMessage={() => "Search by city / postal code"}
        loadingMessage={() => "Search by city / postal code"}
        styles={selectStyles}
        name={props.type}
        value={props.filters?.[props.type] || null}
        onChange={(e) => props.onSelectChange(e, props.type)}
      />
    </LocationWrapper>
  );
};

export default LocationFilter;
