import React from "react";

import { Wrapper } from "./Styles";

const DashboardFooter = () => {
  return (
    <Wrapper>
      <a href="mailto:info@transconnector.eu">info@transconnector.eu</a>
      <a href="tel:+37256886666">+372 56 886 666</a>
      <a href="mailto:support@transconnector.eu">support@transconnector.eu</a>
    </Wrapper>
  );
};

export default DashboardFooter;
