import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";
import "../../styles/Register/Register.css";
import Header from "../../components/FrontPage/Header/Header";
import RegisterHelper from "./RegisterHelper";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepTwo from "./RegisterStepTwo";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants";
import { getUser } from "../../store/actions/dashboard/user";
import { getCompany } from "../../store/actions/dashboard/company";

const Register = (props) => {
  const [pagination, setPagination] = useState(1);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  // handle input changes

  const handleInputChange = (e) => {
    if (e.target.name === "accept") {
      setInputs({ ...inputs, [e.target.name]: e.target.checked });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
    setErrors({ ...errors, [e.target.name]: null });
  };

  // handle next step click
  const handleNextStep = () => {
    axios
      .post(`${API_URL}/validateCompany`, inputs)
      .then(() => setPagination(2))
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
  };

  // handle form submit

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(`${API_URL}/createUser`, inputs)
      .then((res) => {
        const token = res.data;
        const cookies = new Cookies();
        cookies.set("tc_token", token, {
          path: "/",
          expires: moment().add(14, "days").toDate(),
        });
        dispatch(getUser(token));
        dispatch(getCompany(token));
        props.history.push("/pricing");
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
      });
  };

  return (
    <>
      <Header />
      <div className="Register">
        <RegisterHelper pagination={pagination} />
        <div className="Register__container">
          <div className="Register--inner">
            <form onSubmit={(e) => submitHandler(e)}>
              <RegisterStepOne
                pagination={pagination}
                inputs={inputs}
                errors={errors}
                handleInputChange={handleInputChange}
                handleNextStep={handleNextStep}
                setErrors={setErrors}
                setInputs={setInputs}
              />
              <RegisterStepTwo
                pagination={pagination}
                inputs={inputs}
                errors={errors}
                setPagination={setPagination}
                handleInputChange={handleInputChange}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Register);
