import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Header from "../Header";
import Modal from "../Modal";
import { ModalInnerWrapper } from "../Styles";
import ReviewModalContent from "./ReviewModalContent";
import { API_URL } from "../../../constants";

const ReviewModal = (props) => {
  const [allReviews, setAllReviews] = useState(props.reviews || null);
  const [reviews, setReviews] = useState(props.reviews || null);
  const [name, setName] = useState(null);
  const { fetchReviews, id } = props;

  useEffect(() => {
    if (fetchReviews && id) {
      const cookies = new Cookies(),
        token = cookies.get("tc_token");
      axios({
        method: "GET",
        url: `${API_URL}/reviews/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        const { reviews, company } = res.data;
        setReviews(reviews);
        setName(company?.companyName);
        if (!allReviews) {
          setAllReviews(reviews);
        }
      });
    }
  }, [fetchReviews, id]);

  const handleReviewSort = (sort) => {
    if (sort === "All reviews") {
      setReviews(allReviews);
    } else if (sort === "Positive reviews") {
      const sorted = reviews.sort((r) =>
        r.review === 1 ? -1 : r.review === 0 ? 1 : 0
      );
      setReviews(sorted);
    } else if (sort === "Negative reviews") {
      const sorted = reviews.sort((r) =>
        r.review === 0 ? -1 : r.review === 1 ? 1 : 0
      );
      setReviews(sorted);
    }
  };

  return (
    <Modal onClose={props.onClose} medium>
      <Header title="Reviews" onClose={props.onClose} />
      <ModalInnerWrapper>
        <ReviewModalContent
          showHeader={props.showHeader}
          name={props.name || name}
          id={id}
          reviews={reviews}
          onClick={props.onClick}
          sort={handleReviewSort}
        />
      </ModalInnerWrapper>
    </Modal>
  );
};

export default ReviewModal;
