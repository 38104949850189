import {
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT,
  UPDATE_COMPANY_USER_PRIVILEGE,
  REMOVE_COMPANY_USER,
  SET_COMPANY,
} from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const getCompany = (token) => {
  return (dispatch) => {
    axios
      .get(`${API_URL}/getCompany`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({ type: SET_COMPANY, payload: res.data });
      })
      .catch((err) => {
        //dispatch(userFetchFailure());
      });
  };
};

export const getSingleCompany = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(`${API_URL}/getCompany?id=${id}`, config);
  };
};

export const editCompany = (inputs) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/editCompany`, inputs, config);
  };
};

export const addCompanyLogo = (formData) => {
  const token = getToken();
  const formDataConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/companyLogo`, formData, formDataConfig);
  };
};

export const addFleet = (truckType, count) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(
      `${API_URL}/fleet`,
      {
        truckType: truckType,
        count: parseInt(count),
      },
      config
    );
  };
};

export const removeFleet = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.delete(`${API_URL}/fleet`, {
      data: {
        id: id,
      },
      ...config,
    });
  };
};

export const addDocument = (formData) => {
  const token = getToken();
  const formDataConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/companyDocument`, formData, formDataConfig);
  };
};

export const removeDocument = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .delete(`${API_URL}/companyDocument`, {
        data: {
          documentId: id,
        },
        ...config,
      })
      .then(() => {
        // TODO: SET TOAST
        dispatch({
          type: REMOVE_DOCUMENT,
          id: id,
        });
      });
    // TODO: ADD ERROR MESSAGE
  };
};

export const editDocument = (id, newName) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .put(
        `${API_URL}/companyDocument`,
        {
          documentId: id,
          newName: newName,
        },
        config
      )
      .then((res) => {
        dispatch({
          type: UPDATE_DOCUMENT,
          payload: res.data,
        });
        // TODO: SET TOAST
      });
  };
};

export const inviteUser = (inputs) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/inviteUser`, inputs, config);
  };
};

export const editCompanyUserPrivilege = (id, newPrivilege) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .put(
        `${API_URL}/newPrivilege`,
        {
          userId: id,
          newPrivilege: newPrivilege,
        },
        config
      )
      .then((res) => {
        // TODO: ADD TOAST
        dispatch({
          type: UPDATE_COMPANY_USER_PRIVILEGE,
          payload: res.data,
        });
        // TODO: ADD TOAST TO CATCH
      });
  };
};

export const removeCompanyUser = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios.delete(`${API_URL}/removeUser/${id}`, config).then(() => {
      // TODO: SET TOAST
      dispatch({
        type: REMOVE_COMPANY_USER,
        id: id,
      });
    });
  };
};
