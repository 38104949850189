import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  font-family: "Inter";
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormBlockWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const FormBlockTitle = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dedede;
  h2 {
    margin: 0;
    font-size: 1.25rem;
    color: #262a68;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const Input = styled.div`
  position: relative;
  input,
  textarea {
    border: 1px solid #9aabc2;
    background: none;
    font-family: "Inter";
    color: #0c0c0c;
    font-size: 16px;
    height: 50px;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 6px;
    padding: 0.75rem;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
    &::placeholder {
      font-size: 14px;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  textarea {
    height: auto;
    min-height: 150px;
    width: 100%;
    resize: none;
  }
  @media (min-width: 1200px) {
    max-width: 325px;
    width: max-content;
    input[type="number"] {
      width: 115px;
    }
    textarea {
      width: 325px;
    }
  }
  ${(props) =>
    props.error &&
    css`
      input,
      textarea {
        border: 1px solid #e13505;
      }
    `}
`;

export const RadioWrapper = styled.div``;
export const Radio = styled.div`
  margin-bottom: 0.75rem;
  label {
    color: #686a7d;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    input {
      margin: 0;
      margin-right: 0.5rem;
      width: 16px;
      height: 16px;
      pointer-events: none;
      opacity: 0;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
    }
    &:before {
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: #fff;
      border: 1px solid #9aabc2;
      border-radius: 8px;
    }
  }
  ${(props) =>
    props.selected &&
    css`
      label {
        &:after {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #262a68;
          top: 4px;
          left: 4px;
        }
      }
    `}
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const InlineWrapper = styled.div`
  @media (min-width: 768px) {
    display: inline-flex;
    width: 100%;
    > ${InputWrapper}:nth-child(odd),
    ${Radio}:nth-child(odd) {
      margin-right: 1rem;
    }
    ${(props) =>
      props.size === "small" &&
      css`
        > ${InputWrapper}:nth-child(odd),
        ${Radio}:nth-child(odd) {
          width: auto;
        }
      `}
    ${({ centered }) =>
      centered &&
      css`
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      `}
  }
`;

export const LabelWrapper = styled.div`
  @media (min-width: 1200px) {
    display: inline-flex;
    width: 100%;
  }
`;

export const Label = styled.div`
  margin-bottom: 0.75rem;
  h3 {
    color: #313131;
    font-size: 15px;
    margin: 0;
    font-weight: 400;
  }
  @media (max-width: 1200px) {
    h3 {
      text-align: left !important;
    }
  }
  @media (min-width: 1200px) {
    width: 135px;
    min-width: 135px;
    margin-bottom: 0;
    h3 {
      text-align: right;
      margin-right: 1.5rem !important;
    }
  }
`;

export const DateRangeWrapper = styled.div`
  .DateRangePicker,
  .DateRangePickerInput {
    width: 100%;
  }
  .DateRangePicker {
    display: inline-flex;
    width: 100%;
    height: 50px;
  }
  .DateRangePickerInput {
    display: inline-flex;
    align-items: center;
    height: 50px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
  }
  .DateRangePickerInput__withBorder {
    border: 1px solid #9aabc2;
    border-radius: 6px;
  }
  .DateInput {
    width: 100%;
    border-radius: 6px;
  }
  .DateInput_input {
    font-family: "Inter";
    font-weight: 400;
    color: #0c0c0c;
    font-size: 16px;
    height: 48px;
    &::placeholder {
      font-size: 14px;
    }
  }
  .DateInput_input__focused {
    border-bottom: 2px solid #15198f;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #15198f;
    border: 1px double #15198f;
  }
  .CalendarDay__selected_span {
    background: #4785ff;
    border: 1px double #3a7cff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active,
  .CalendarDay__hovered_span:hover {
    background: #4785ff;
    border: 1px double #3a7cff;
    color: #fff;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #4785ff;
  }
  @media (min-width: 1200px) {
    .DateRangePicker {
      max-width: 325px;
    }
  }
  ${(props) =>
    props.error &&
    css`
      .DateRangePickerInput__withBorder {
        border: 1px solid #e13505;
      }
    `}
`;

export const SingleDateWrapper = styled.div`
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
  }
  .SingleDatePicker {
    display: inline-flex;
    width: 100%;
    height: 50px;
  }
  .SingleDatePickerInput {
    display: inline-flex;
    align-items: center;
    height: 50px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
  }
  .SingleDatePickerInput__withBorder {
    border: 1px solid #9aabc2;
    border-radius: 6px;
  }
  .DateInput {
    width: 100%;
    border-radius: 6px;
  }
  .DateInput_input {
    font-family: "Inter";
    font-weight: 400;
    color: #0c0c0c;
    font-size: 16px;
    height: 48px;
    &::placeholder {
      font-size: 14px;
    }
  }
  .DateInput_input__focused {
    border-bottom: 2px solid #15198f;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #15198f;
    border: 1px double #15198f;
  }
  .CalendarDay__selected_span {
    background: #4785ff;
    border: 1px double #3a7cff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active,
  .CalendarDay__hovered_span:hover {
    background: #4785ff;
    border: 1px double #3a7cff;
    color: #fff;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #4785ff;
  }
  @media (min-width: 1200px) {
    .DateRangePicker {
      max-width: 325px;
    }
  }
  ${(props) =>
    props.error &&
    css`
      .SingleDatePickerInput__withBorder {
        border: 1px solid #e13505;
      }
    `}
`;

export const ClickableButton = styled.div`
  margin: 0.5rem 0;
  button {
    border: none;
    background: none;
    font-family: "Inter";
    font-size: 14px;
    color: #15198f;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  @media (min-width: 1200px) {
    margin-left: 135px;
  }
  ${({ radius }) =>
    radius &&
    css`
      margin-top: 2rem;
    `}
`;

export const InputArrow = styled.div`
  ${(props) =>
    props.top &&
    css`
      top: 1px;
      button {
        border-top-right-radius: 6px;
      }
    `}
  ${(props) =>
    props.bottom &&
    css`
      bottom: 1px;
      button {
        border-bottom-right-radius: 6px;
      }
    `}
  position: absolute;
  right: 1px;
  button {
    height: 24px;
    border: none;
    background: none;
    padding: 0 12px;
    transition: 0.25s all ease;
    cursor: pointer;
    &:focus {
      transition: 0.25s all ease;
      outline: none;
      background: #f4f4f4;
    }
    &:hover {
      transition: 0.25s all ease;
      background: #f4f4f4;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 0.75rem;
  label {
    color: #686a7d;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    input {
      margin: 0;
      margin-right: 0.5rem;
      width: 16px;
      height: 16px;
      pointer-events: none;
      opacity: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: #fff;
      border: 1px solid #9aabc2;
      border-radius: 2px;
    }
  }
  ${(props) =>
    props.selected &&
    css`
      label {
        &:before {
          background: #9aabc2;
        }
      }
    `}
`;

export const CTA = styled.div`
  margin: 1rem 0;
  display: flex;
  width: 100%;
  button,
  a {
    background: #00b27e;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 4rem;
    font-family: "Inter";
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.25s ease;
    text-decoration: none;
    &:hover {
      transition: all 0.25s ease;
      background: #07ce94;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
    &:disabled {
      border: 1px solid #d9d9d9;
      background: #e8e8e8;
      color: #919191;
      cursor: not-allowed;
    }
  }
  ${(props) =>
    props.secondary &&
    css`
      button,
      a {
        padding: 0.75rem 2rem;
        background: none;
        border: 1px solid #262a68;
        color: #262a68;
        &:hover {
          background: #262a68;
          color: #fff;
        }
      }
    `}
  ${(props) =>
    props.centered &&
    css`
      justify-content: center;
    `}
`;

export const ButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media (min-width: 1200px) {
    justify-content: left;
    padding-left: 135px;
  }
  ${(props) =>
    props.children?.length > 1
      ? css`
          > ${CTA} {
            width: auto;
            margin-right: 2rem;
          }
        `
      : null}
`;
export const MultiSelectInnerWrapper = styled.div`
  width: 100%;
  ${({ radius }) =>
    radius &&
    css`
      @media (min-width: 1200px) {
        max-width: 375px;
      }
    `}
`;

export const MultiSelectOuterWrapper = styled.div`
  width: 100%;
  &:not(:last-child) {
    > ${MultiSelectInnerWrapper} {
      margin-bottom: 0.75rem;
      ~ ${ClickableButton} {
        display: none;
      }
    }
  }
  > ${MultiSelectInnerWrapper} {
    ~ ${ClickableButton} {
      margin-left: 0;
    }
  }
  ${({ radius }) =>
    radius &&
    css`
      &:not(:first-child) {
        ${MultiSelectInnerWrapper} {
          margin-top: 1rem;
          @media (min-width: 1200px) {
            margin-top: 3rem;
          }
        }
      }
    `}
`;
export const MultiSelectWrapper = styled.div`
  width: 100%;
`;

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: "auto",
    minWidth: "325px",
    "&.invalid": {
      ".styled__control": {
        border: "1px solid red",
      },
    },
    "@media (max-width: 350px)": {
      minWidth: "290px;",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "0.25rem",
    border: "1px solid #9aabc2",
    borderRadius: 6,
    boxShadow: state.isFocused ? "0 0 0 2px #a0d1fa" : "none",
    minHeight: "50px",
    marginBottom: ".75rem",
    "&:hover": {
      borderColor: "#9aabc2",
    },
    "@media (min-width: 1200px)": {
      marginBottom: "0",
      maxWidth: 325,
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "16px",
    "&:focus": {
      boxShadow: "none",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#686a7d",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#686a7d",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#0C0C0C",
    fontSize: "14px",
  }),
};

export const ErrorContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${(props) =>
    props.maxWidth &&
    css`
      width: 100%;
    `}
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.25rem;
  span {
    color: #e13505;
    font-size: 12px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1200px) {
    span {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

export const DeleteIcon = styled.div`
  position: absolute;
  right: 9px;
  bottom: -10px;
  @media (min-width: 1200px) {
    top: 16px;
    right: 1.5rem;
    bottom: unset;
  }
  svg {
    width: 12px;
    height: 12px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const RadiusWrapper = styled.div`
  @media (min-width: 1200px) {
    .rc-slider {
      max-width: 325px;
    }
  }
`;
