import {
  SHOW_MESSAGE_WINDOW,
  SET_LAST_MESSAGE_WINDOW,
  SET_CURRENT_MESSAGE_WINDOW,
  SET_ACTIVE_MINI_CONVERSATIONS,
  SHOW_MINI_MESSAGES
} from "../../actionTypes";

const initialState = {
  showMessageWindow: false,
  lastMessageWindow: "",
  currentMessageWindow: "",
  showMiniMessages: true,
  activeMiniConversations: []
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_WINDOW:
      return {
        ...state,
        showMessageWindow: action.payload
      };
    case SET_CURRENT_MESSAGE_WINDOW:
      return {
        ...state,
        currentMessageWindow: action.payload
      };
    case SET_LAST_MESSAGE_WINDOW:
      return {
        ...state,
        lastMessageWindow: action.payload
      };
    case SHOW_MINI_MESSAGES:
      return {
        ...state,
        showMiniMessages: action.payload
      }
    case SET_ACTIVE_MINI_CONVERSATIONS:
      return {
        ...state,
        activeMiniConversations: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
