import React, { useState, useEffect } from "react";
import NumberInput from "./NumberInput";
import {
  InputWrapper,
  ClickableButton,
  InlineWrapper,
} from "../CreateForm/Styles";

const AdditionalRequirements = (props) => {
  const [req, setReq] = useState(false);

  // If errors are set for inputs, make sure the required fields are shown
  useEffect(() => {
    if (props.errors?.length > 0) setReq(true);
  }, [props.errors]);

  const { height, length, width, volume } = props.inputs?.freight || {};
  const { showVolume } = props;
  // Check if pre-defined data includes height || length || width, if, then show fields
  useEffect(() => {
    if (height || length || width || (showVolume && volume)) setReq(true);
  }, [height, length, width, volume, showVolume]);

  return (
    <InputWrapper>
      <ClickableButton>
        <button type="button" onClick={() => setReq(!req)}>
          {!req
            ? "+ Show additional requirements"
            : "- Hide additional requirements"}
        </button>
      </ClickableButton>
      {req && (
        <>
          <InlineWrapper>
            <NumberInput
              label="Length"
              min={0}
              name="length"
              placeholder="Cm"
              value={props.inputs?.freight?.length || ""}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.length}
            />
            <NumberInput
              label="Width"
              min={0}
              name="width"
              placeholder="Cm"
              value={props.inputs?.freight?.width || ""}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.width}
            />
          </InlineWrapper>
          <InlineWrapper>
            <NumberInput
              label="Height"
              min={0}
              name="height"
              placeholder="Cm"
              value={props.inputs?.freight?.height || ""}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.height}
            />
            {props.showVolume && (
              <NumberInput
                label="Volume"
                min={0}
                name="volume"
                placeholder="m"
                cubic
                value={props.inputs?.freight?.volume || ""}
                onInputChange={props.onInputChange}
                descendant="freight"
                error={props.errors?.volume}
              />
            )}
          </InlineWrapper>
        </>
      )}
    </InputWrapper>
  );
};

export default AdditionalRequirements;
