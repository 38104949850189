import React from "react";
import Form from "../../UI/Form";
import Select from "react-select";
import Input from "../../UI/Input";
import Loader from "../../UI/Loader";

const FleetForm = (props) => {
  return (
    <Form onSubmit={(e) => props.onSubmit(e)}>
      <div className="input-collection">
        <div className="input-wrapper">
          <h4>Truck type</h4>
          <Select
            classNamePrefix="react-select"
            className={`dropdown ${props.errors?.truckTypes ? "invalid" : ""}`}
            placeholder="Truck type"
            name="truckType"
            options={props.truckTypes}
            value={props.inputs?.truckType || ""}
            onChange={props.onInputChange}
            required
          />
          {props.errors?.truckTypes ? (
            <div className="error">
              <p>{props.errors?.truckTypes?.msg}</p>
            </div>
          ) : null}
        </div>
      </div>
      <Input
        InputType="input"
        type="number"
        min="0"
        label="Amount"
        placeholder="Amount of trucks"
        name="count"
        value={props.inputs?.count || ""}
        pattern="[0-9]*"
        onChange={props.onInputChange}
        className={props.errors?.count ? "invalid" : ""}
        error={props.errors?.count ? props.errors?.count?.msg : null}
      />
      <div className="submit">
        {!props.loadingSubmission ? (
          <input
            type="submit"
            value={props.saved ? "Truck added" : "Add truck"}
            disabled={props.saved ? true : false}
            className={props.saved ? "success" : ""}
          />
        ) : (
          <Loader />
        )}
      </div>
    </Form>
  );
};

export default FleetForm;
