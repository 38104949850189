import styled, { css } from "styled-components";

export const Wrapper = styled.button``;
export const LinkWrapper = styled.div`
  background: #00ca8f;
  display: flex;
  max-width: max-content;
  border-radius: 4px;
  a {
    padding: 0.75rem 2.75rem;
    color: #fff;
    font-family: "Inter";
    font-weight: 600;
    text-decoration: none;
    font-size: 18px;
  }
  @media (min-width: 768px) {
    a {
      font-size: 21px;
    }
  }
  ${({ centered }) =>
    centered === "mobile"
      ? css`
          margin-left: auto;
          margin-right: auto;
          @media (min-width: 1200px) {
            margin: unset;
          }
        `
      : centered === "all"
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : null}
`;
