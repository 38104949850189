import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { connect, useDispatch } from "react-redux";
import Dashboard from "../components/Dashboard/Dashboard";
import { withRouter } from "react-router-dom";
import { getUser } from "../store/actions/dashboard/user";
import { getCompany } from "../store/actions/dashboard/company";

const DashboardPage = ({ history, USER, COMPANY }) => {
  const dispatch = useDispatch();
  const cookies = new Cookies(),
    token = cookies.get("tc_token");
  // FETCH USER AND COMPANY WHEN TOKEN IS SET
  useEffect(() => {
    if (token && !USER) {
      dispatch(getUser(token));
      if (!COMPANY) {
        dispatch(getCompany(token));
      }
    }
  }, [token, USER]);

  return <>{!USER ? history.push("/") : <Dashboard />}</>;
};

const mapStateToProps = (state) => {
  return {
    USER: state.dashboard.user?.data,
    COMPANY: state.dashboard.company?.data,
  };
};

export default connect(mapStateToProps)(withRouter(DashboardPage));
