import React from "react";
import { OptionsWrapper, OptionsIcon, OptionsDropdown } from "./Styles";

const Options = (props) => {
  return (
    <OptionsWrapper onClick={props.onClick} tabIndex="0" onBlur={props.onBlur}>
      <OptionsIcon>{icon}</OptionsIcon>
      <OptionsDropdown open={props.open}>{props.children}</OptionsDropdown>
    </OptionsWrapper>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="21"
    viewBox="0 0 5 21"
  >
    <g transform="translate(-1429 -1399)">
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(1429 1399)"
        fill="#323232"
      />
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(1429 1407)"
        fill="#323232"
      />
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(1429 1415)"
        fill="#323232"
      />
    </g>
  </svg>
);

export default Options;
