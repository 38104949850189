import React from "react";
import { isMobile } from "react-device-detect";

const RegisterHelper = (props) => {
  return (
    <div className="Register__helper">
      <div className="Register--titles">
        <div className="Register--titles--inner">
          <h1>Create Account</h1>
          {isMobile ? (
            <div className="mobile-step">
              <div className={`step-one`}>1</div>
              <div className={`step-two`}>2</div>
              <div className={`step-three`}>3</div>
            </div>
          ) : (
            <p>Set up your account by following 3 steps</p>
          )}
        </div>
      </div>
      <div className="Register__steps">
        <div className={`Register--step ${props.pagination === 1 && "active"}`}>
          <div className="Register--step--inner">
            <h2>Step 1. Company details</h2>
            <p>Fill form with your company info</p>
          </div>
        </div>
        <div className={`Register--step ${props.pagination === 2 && "active"}`}>
          <div className="Register--step--inner">
            <h2>Step 2. Your account</h2>
            <p>Fill form with your personal info</p>
          </div>
        </div>
        <div className={`Register--step ${props.pagination === 3 && "active"}`}>
          <div className="Register--step--inner">
            <h2>Step 3. Subscription</h2>
            <p>Choose your subscription plan</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterHelper;
