import React from "react";

const FormBlock = props => {
  return (
    <div className="Form--block">
      {props.title ? <h3>{props.title}</h3> : null}
      {props.children}
    </div>
  );
};

export default FormBlock;
