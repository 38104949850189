import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { API_URL } from "../../constants/index";
import { useSelector } from "react-redux";
import Container from "../UI/Container";
import { Link } from "react-router-dom";
import {
  Intro,
  RadioWrapper,
  Save,
  SaveAmount,
  PricingCards,
  PricingCard,
  CardHero,
  CardContent,
  CardFooter,
  PricingFooter,
} from "./Styles";
import RadioGroup from "../CreateForm/RadioGroup";
import { InlineWrapper } from "../CreateForm/Styles";
import RadioInput from "../CreateForm/RadioInputSecondary";
import DashboardFooter from "../Dashboard/DashboardFooter";

const Pricing = () => {
  const [inputs, setInputs] = useState({
    billing: 0,
  });
  const [data, setData] = useState(null);
  const SUBSCRIPTION = useSelector(
    (state) =>
      state?.dashboard?.company?.data?.subscriptionPlan?.BTsubscriptionId
  );

  const handleRadioChange = (value, name) => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (!data) {
      axios
        .get(`${API_URL}/subscriptionPlans`)
        .then((res) => setData(res.data));
    }
  }, []);

  if (!data) return null;

  return (
    <Container>
      <Intro>Choose the best plan for your business</Intro>
      <RadioWrapper>
        <RadioGroup maxWidth>
          <InlineWrapper centered>
            <RadioInput
              name="billing"
              value={0}
              onInputChange={handleRadioChange}
              inputs={inputs}
              label="Billed monthly"
            />
            <RadioInput
              name="billing"
              value={1}
              onInputChange={handleRadioChange}
              inputs={inputs}
              label="Billed every 6 months"
              style={{
                marginRight: "1rem",
              }}
            >
              <Save>Save 30%</Save>
            </RadioInput>
            <RadioInput
              name="billing"
              value={2}
              onInputChange={handleRadioChange}
              inputs={inputs}
              label="Billed annualy"
            >
              <Save>Save 50%</Save>
            </RadioInput>
          </InlineWrapper>
        </RadioGroup>
      </RadioWrapper>
      <PricingCards>
        {data?.map((sub, i) => {
          const { type, name, attributes, price } = sub;
          let userSubscription = false;
          return (
            <PricingCard key={i}>
              <CardHero>
                {name && price && <h3>{name}</h3>}
                {name && !price && (
                  <h2 style={{ marginTop: "31px" }}>{name}</h2>
                )}
                {price &&
                  price.map((p, i) => {
                    if (p.type === inputs?.billing && p.id === SUBSCRIPTION)
                      userSubscription = true;
                    return (
                      <Fragment key={i}>
                        {p.type === inputs?.billing && (
                          <h2>{p.value.toFixed(2)}€</h2>
                        )}{" "}
                        {p.type === inputs?.billing && p.savePercentage > 0 && (
                          <SaveAmount>
                            Save €
                            {parseFloat(
                              p.duration * price[0].value - p.duration * p.value
                            ).toFixed(2)}
                          </SaveAmount>
                        )}
                      </Fragment>
                    );
                  })}
                <p>{type === 0 ? "one month" : "per month"}</p>
              </CardHero>
              <CardContent>
                <ul>
                  {attributes?.map((attr, i) => {
                    return (
                      <li key={i}>
                        {attr.available ? tick : unavailable} {attr.value}
                      </li>
                    );
                  })}
                </ul>
              </CardContent>
              <CardFooter>
                {userSubscription || (SUBSCRIPTION === 15 && !price) ? (
                  <Link to="#!" className="inactive">
                    Current plan
                  </Link>
                ) : !SUBSCRIPTION ? (
                  <Link to="/sign-up">Select Plan</Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/checkout",
                      state: {
                        plan: name,
                        billing:
                          inputs?.billing === 0
                            ? "1"
                            : inputs?.billing === 1
                            ? "6"
                            : "12",
                        price: price?.[inputs?.billing]?.value,
                        subscriptionId: price?.[inputs?.billing]?.id,
                        currentMembershipId: SUBSCRIPTION,
                        gatewaySubscriptionId: SUBSCRIPTION,
                      },
                    }}
                  >
                    Select Plan
                  </Link>
                )}
              </CardFooter>
            </PricingCard>
          );
        })}
      </PricingCards>
      {!SUBSCRIPTION && (
        <PricingFooter>
          <h2>Transconnector offers free trial to all eligible customers</h2>
          <Link to="/free-trial">Read more about free trial</Link>
        </PricingFooter>
      )}
      <DashboardFooter />
    </Container>
  );
};

const tick = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.193"
    height="10.367"
    viewBox="0 0 14.193 10.367"
  >
    <path
      d="M-16355.592-17850.857l5.306,5.3,7.438-8.246"
      transform="translate(16356.299 17854.469)"
      fill="none"
      stroke="#00b27e"
      strokeWidth="2"
    />
  </svg>
);

const unavailable = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.193"
    height="11.487"
    viewBox="0 0 11.487 11.487"
  >
    <g transform="translate(-596.793 -999.793)">
      <line
        x2="10.073"
        y2="10.073"
        transform="translate(597.5 1000.5)"
        fill="none"
        stroke="#d40000"
        strokeWidth="2"
      />
      <line
        y1="10.073"
        x2="10.073"
        transform="translate(597.5 1000.5)"
        fill="none"
        stroke="#d40000"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default Pricing;
