import React from "react";

function RegisterInput(props) {
  return (
    <>
      <div className={props.classes}>
        <input
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder=" "
          disabled={props.isDisabled}
        />
        <label>{props.label}</label>
        {props.error ? (
          <div className="error-message">{props.error}</div>
        ) : null}
      </div>
    </>
  );
}

export default RegisterInput;
