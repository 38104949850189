export const formatConversation = data => {
  const newData = [];
  for (let i = 0; i < data.length; i++) {
    if (i + 1 < data.length) {
      if (data[i].type === data[i + 1]?.type) {
        if (i === 0) {
          data[i].wrapStart = true;
          data[i].wrapEnd = false;
        } else {
          if (data[i - 1]?.type !== data[i].type) {
            data[i].wrapStart = true;
            data[i].wrapEnd = false;
          } else {
            data[i].wrapStart = false;
            data[i].wrapEnd = false;
          }
        }
        newData.push(data[i]);
      } else if (
        i > 0 &&
        data[i].type === data[i - 1]?.type &&
        data[i].type !== data[i + 1]?.type
      ) {
        data[i].wrapStart = false;
        data[i].wrapEnd = true;
        newData.push(data[i]);
      } else {
        data[i].wrapStart = true;
        data[i].wrapEnd = true;
        newData.push(data[i]);
      }
    } else {
      if (data[i].type === data[i - 1]?.type) {
        data[i].wrapStart = false;
        data[i].wrapEnd = true;
        newData.push(data[i]);
      } else {
        data[i].wrapStart = true;
        data[i].wrapEnd = true;
        newData.push(data[i]);
      }
    }
  }
  return newData;
};
