import { toast } from "react-toastify";
export const CreateToast = ({
  message,
  type,
  position,
  autoClose,
  onClick,
}) => {
  return toast(message, {
    type: type,
    position: position || "top-right",
    autoClose: autoClose || 3000,
    pauseOnHover: false,
    onClick: onClick || null,
  });
};
