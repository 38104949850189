import React from "react";

import { ItemWrapper, ItemFooter, Poster } from "./Styles";

const Item = (props) => {
  return (
    <ItemWrapper>
      <p>{props.children}</p>
      <ItemFooter>
        <img src={props.img} alt="" />
        <Poster>
          <h4>{props.representative}</h4>
          <h4>{props.company}</h4>
        </Poster>
      </ItemFooter>
    </ItemWrapper>
  );
};

export default Item;
