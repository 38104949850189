import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import formatLocationName from "../../../helpers/LocationName"

import {
  ListItemWrapper,
  Item,
  ItemRow,
  ItemRowWrapper,
  ItemRowInline,
  CargoType,
  ViewMore,
  Arrow,
  CompanyRating,
  ThumbsUp,
  ThumbsDown,
} from "../../PageWithList/List/Styles";
import moment from "moment";

const SpotloadListItem = React.memo((props) => {
  const [loadingCount, setLoadingCount] = useState(0);
  const [unloadingCount, setUnloadingCount] = useState(0);
  const history = useHistory();

  const {
    _id,
    loading,
    unloading,
    truckType,
    freight,
    createdAt,
    temperature,
    targetPrice,
    distanceFromFilterLoading,
    distanceFromFilterUnloading,
    company,
  } = props.spotload;

  const { USER } = props;

  useEffect(() => {
    setLoadingCount(0);
    setUnloadingCount(0);
    loading.locations.forEach((e) =>
      setLoadingCount((prevState) => prevState + 1)
    );
    unloading.locations.forEach((e) =>
      setUnloadingCount((prevState) => prevState + 1)
    );
  }, [loading, unloading, setLoadingCount, setUnloadingCount]);

  return (
    <ListItemWrapper viewed={props.viewed}>
      <Item
        onClick={() => {
          history.push(`/spotload/${_id}`);
          props.setSheetID(_id);
        }}
        active={props.sheetID}
      >
        <ItemRow flex="0.5">
          <>
            <h3>Loading</h3>
            <ItemRowWrapper>
              <ItemRowInline>
                <img
                  src={loading.locations[0].flagUrl}
                  alt={loading.locations[0].country}
                />
                <h4>
                  {formatLocationName(loading.locations[0].locationName)}{" "}
                  {loadingCount > 1 && <span> (+{loadingCount - 1})</span>}
                </h4>
              </ItemRowInline>
              <Arrow>{arrow}</Arrow>
            </ItemRowWrapper>
            <ItemRowWrapper>
              <h5>
                Date: {moment(loading?.date.start).format("DD.MM")} -{" "}
                {moment(loading.date.end).format("DD.MM")}
              </h5>
            </ItemRowWrapper>
          </>
          {{ distanceFromFilterLoading } &&
            distanceFromFilterLoading !== 0 &&
            typeof distanceFromFilterLoading !== "undefined" &&
            props?.location?.loading && (
              <ItemRowWrapper>
                <h6>
                  {`${
                    distanceFromFilterLoading && distanceFromFilterLoading
                  } km from ${props.location.loading}`}
                </h6>
              </ItemRowWrapper>
            )}
        </ItemRow>
        <ItemRow flex="0.5">
          <h3>Unloading</h3>
          <ItemRowWrapper>
            <ItemRowInline>
              <img
                src={unloading.locations[0].flagUrl}
                alt={unloading.locations[0].country}
              />
              <h4>
                {formatLocationName(unloading.locations[0].locationName)}{" "}
                {unloadingCount > 1 && <span> (+{unloadingCount - 1})</span>}
              </h4>
            </ItemRowInline>
          </ItemRowWrapper>
          <ItemRowWrapper>
            <h5>
              Date: {moment(unloading.date.start).format("DD.MM")} -{" "}
              {moment(unloading.date.end).format("DD.MM")}
            </h5>
          </ItemRowWrapper>
          {{ distanceFromFilterUnloading } &&
            distanceFromFilterUnloading !== 0 &&
            typeof distanceFromFilterUnloading !== "undefined" &&
            props?.location?.unloading && (
              <ItemRowWrapper>
                <h6>
                  {`${distanceFromFilterUnloading} km from ${props.location.unloading}`}
                </h6>
              </ItemRowWrapper>
            )}
        </ItemRow>
        <ItemRow flex="0.4">
          <h3>Truck type</h3>
          <ItemRowWrapper>
            <h4>
              {truckType
                .map((element) => {
                  if (element.value === "Refrigerator" && temperature) {
                    return `${element.value} (${temperature}°C)`;
                  }
                  return element.value;
                })
                .join(", ")
                .replace(/_/g, " ")}
            </h4>
            <CargoType
              FTL={freight.typeOfLoad === "FTL"}
              LTL={freight.typeOfLoad === "LTL"}
            >
              <span>
                {freight.typeOfLoad}{" "}
                {freight?.weight && freight?.weight > 100
                  ? ` · ${freight.weight}kg`
                  : ` · ${freight.weight}000kg`}
                {freight?.loadingMeters && ` · LDM: ${freight.loadingMeters}m`}
              </span>
            </CargoType>
          </ItemRowWrapper>
        </ItemRow>
        <ItemRow flex="0.2">
          {targetPrice && targetPrice !== 0 && (
            <>
              <h3>Target price</h3>
              <ItemRowWrapper>
                <h4>{targetPrice}€</h4>
              </ItemRowWrapper>
            </>
          )}
        </ItemRow>
        <ItemRow flex="0.3">
          <h3>Published</h3>
          <ItemRowWrapper>
            <h4>{moment(createdAt).fromNow()}</h4>
          </ItemRowWrapper>
        </ItemRow>
        {USER ? (
          <ItemRow flex="0.3">
            <h3>Publisher</h3>
            <ItemRowWrapper>
              <ItemRowInline>
                <img
                  src={`https://transconnector.eu/public/images/flags/${company?.country
                    .toLowerCase()
                    .replace(" ", "-")}.svg`}
                  alt=""
                />
                <h2>{company?.companyName}</h2>
              </ItemRowInline>
            </ItemRowWrapper>
            <ItemRowWrapper
              onClick={(e) => {
                if (company?.positive > 0 || company?.negative > 0) {
                  e.stopPropagation();
                  props.setReviewModal(company._id);
                }
              }}
            >
              <CompanyRating>
                <ThumbsUp>
                  {thumbsUp} (<span>{company?.positive}</span>)
                </ThumbsUp>
                <ThumbsDown>
                  {thumbsDown} (<span>{company?.negative}</span>)
                </ThumbsDown>
              </CompanyRating>
            </ItemRowWrapper>
          </ItemRow>
        ) : (
          <ItemRow flex="0.3">
            <h3>Publisher</h3>
            <ItemRowWrapper blur>
              <ItemRowInline>
                <div style={{ paddingTop: "6px", paddingLeft: "2px" }}>
                  <img
                    src={`https://transconnector.eu/public/images/flags/${company?.country
                      .toLowerCase()
                      .replace(" ", "-")}.svg`}
                    alt=""
                  />
                </div>
                <h2>xxxxxxxxxxxxxx</h2>
              </ItemRowInline>
            </ItemRowWrapper>
            <ItemRowWrapper blur>
              <CompanyRating>
                <ThumbsUp>
                  {thumbsUp} (<span>x</span>)
                </ThumbsUp>
                <ThumbsDown>
                  {thumbsDown} (<span>x</span>)
                </ThumbsDown>
              </CompanyRating>
            </ItemRowWrapper>
          </ItemRow>
        )}
        <ViewMore>View more</ViewMore>
      </Item>
    </ListItemWrapper>
  );
});

const arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.802"
    height="9.789"
    viewBox="0 0 18.802 9.789"
  >
    <g transform="translate(-1655.804 -1513.822)">
      <g>
        <g transform="translate(1050.065 890)">
          <path
            d="M614.285,633.5h17.449"
            transform="translate(-8.547 -5)"
            fill="none"
            stroke="#e5e5e5"
            strokeWidth="2"
          />
          <path
            d="M618.938,624.529l4.187,4.187-4.187,4.187"
            fill="none"
            stroke="#e5e5e5"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);

const thumbsUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.8"
    height="14.8"
    viewBox="0 0 14.8 14.8"
  >
    <path
      d="M3.006,6.475H.694A.694.694,0,0,0,0,7.169v6.938a.694.694,0,0,0,.694.694H3.006a.694.694,0,0,0,.694-.694V7.169A.694.694,0,0,0,3.006,6.475ZM1.85,13.644a.694.694,0,1,1,.694-.694A.694.694,0,0,1,1.85,13.644ZM11.1,2.354c0,1.226-.751,1.914-.962,2.733h2.94A1.727,1.727,0,0,1,14.8,6.767a2.093,2.093,0,0,1-.562,1.422l0,0a2.415,2.415,0,0,1-.269,2.3,2.286,2.286,0,0,1-.474,2.161,1.535,1.535,0,0,1-.178,1.29c-.59.848-2.052.86-3.289.86H9.944a8.3,8.3,0,0,1-3.456-.917,4.549,4.549,0,0,0-1.522-.468.347.347,0,0,1-.341-.347V6.889a.346.346,0,0,1,.1-.246C5.873,5.511,6.365,4.313,7.3,3.373a3.5,3.5,0,0,0,.734-1.7C8.166,1.136,8.435,0,9.019,0,9.713,0,11.1.231,11.1,2.354Z"
      fill="#3fbc61"
    />
  </svg>
);

const thumbsDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.8"
    height="14.8"
    viewBox="0 0 14.8 14.8"
  >
    <path
      d="M3.006,8.325H.694A.694.694,0,0,1,0,7.631V.694A.694.694,0,0,1,.694,0H3.006A.694.694,0,0,1,3.7.694V7.631A.694.694,0,0,1,3.006,8.325ZM1.85,1.156a.694.694,0,1,0,.694.694A.694.694,0,0,0,1.85,1.156ZM11.1,12.445c0-1.226-.751-1.914-.962-2.733h2.94A1.727,1.727,0,0,0,14.8,8.033a2.093,2.093,0,0,0-.562-1.422l0,0a2.415,2.415,0,0,0-.269-2.3,2.286,2.286,0,0,0-.474-2.161A1.534,1.534,0,0,0,13.315.86C12.725.012,11.262,0,10.026,0H9.944A8.3,8.3,0,0,0,6.488.917a4.549,4.549,0,0,1-1.522.468.347.347,0,0,0-.341.347V7.911a.346.346,0,0,0,.1.246c1.145,1.131,1.637,2.329,2.576,3.27a3.5,3.5,0,0,1,.734,1.7c.129.535.4,1.671.981,1.671C9.713,14.8,11.1,14.569,11.1,12.445Z"
      fill="#ca2e2e"
    />
  </svg>
);

export default SpotloadListItem;
