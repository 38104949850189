import styled from "styled-components";

export const StandardQuillWrapper = styled.div`
  .quill {
    min-width: 245px;
    max-width: 600px;
    .ql-container {
      min-height: 160px;
      max-height: 240px;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: #fbfbfc;
      }
      &::-webkit-scrollbar {
        background-color: #eaeaea;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c5c6d3;
        border: 4px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
      }
      &.ql-snow {
        border: 1px solid #9aabc2;
        border-radius: 6px;
      }
    }
    .ql-toolbar.ql-snow {
      display: none;
    }
    .ql-tooltip {
      left: 0 !important;
      font-family: "Inter";
    }
    .ql-editor {
      font-family: "Inter";
      min-height: 160px;
      font-size: 16px;
      &:before {
        font-size: 14px;
      }
      &.ql-blank {
        &:before {
          font-style: normal;
          color: #686a7d;
          font-size: 14px;
        }
      }
    }
    &.invalid {
      .ql-container {
        &.ql-snow {
          border: 1px solid #e13505;
        }
      }
      .ql-toolbar.ql-snow {
        border: 1px solid #e13505;
        border-bottom: none;
      }
    }
  }
  @media (min-width: 992px) {
    .quill {
      min-width: 325px;
      max-width: 325px;
    }
  }
`;
