import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import image from "../images/login.png";
import "../styles/Forgot/Forgot.css";
import logo from "../images/logo-white.svg";
import axios from "axios";
import Loader from "../components/Dashboard/UI/Loader";
import { API_URL } from "../constants";
import { getUser } from "../store/actions/dashboard/user";
import { getCompany } from "../store/actions/dashboard/company";

const Invited = (props) => {
  const [userError, setUserError] = useState(false);
  const [userErrorMessage, setUserErrorMessage] = useState();
  const [inputs, setInputs] = useState({});
  const [inviteId] = useState(props.match.params.id);
  const [notAllowed, setNotAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [userId, setUserId] = useState();
  const dispatch = useDispatch();
  // check if user is allowed to visit

  useEffect(() => {
    axios
      .get(`${API_URL}/invitedUser/${inviteId}`)
      .then((res) => {
        setNotAllowed(false);
        setLoading(false);

        if (res.data.active === false) {
          setUserId(res.data._id);
        } else {
          setNotAllowed(true);
          setLoading(true);
        }
      })
      .catch(() => setNotAllowed(true));
  }, [inviteId]);
  // Handle input change

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  // handle form submission

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserError(false);
    setUserErrorMessage();
    if (inputs.repeatPassword !== inputs.password) {
      setUserError(true);
      setUserErrorMessage("Passwords do not match");
    } else if (inputs.password?.length < 7) {
      setUserError(true);
      setUserErrorMessage("Password too short. Min 8 characters");
    } else {
      axios
        .post(`${API_URL}/updateInvitedUser`, {
          userId: userId,
          password: inputs.password,
        })
        .then((res) => {
          const token = res.data;
          const cookies = new Cookies();
          cookies.set("tc_token", token, {
            path: "/",
            expires: moment().add(14, "days").toDate(),
          });
          dispatch(getUser(token));
          dispatch(getCompany(token));
          setSuccess(true);
        })
        .catch(() => {
          setUserError(true);
          setUserErrorMessage("An unexpected error occurred. Try again later.");
        });
    }
  };

  // If id doesn't exist or is incorrect, redirect to homepage.

  if (!inviteId || notAllowed) {
    return <Redirect to="/" />;
  }

  // If form submission returns 200, redirect to dashboard

  if (success) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <div className="Login" style={{ marginTop: 0 }}>
        <div className="Login__Image">
          <img src={logo} alt="TransConnector" />
          <img src={image} alt="TransConnector" />
        </div>
        <div className="Login--inner">
          {!loading ? (
            <>
              <h2>
                Enter your <br /> password
              </h2>
              <h4>Welcome to Transconnector</h4>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className={`Login__Error animated ${userError}`}>
                  <p>{userErrorMessage}</p>
                </div>
                <div className="floating-label">
                  <input
                    type="password"
                    placeholder=" "
                    value={inputs.password || ""}
                    name="password"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label>Password</label>
                </div>
                <div className="floating-label">
                  <input
                    type="password"
                    placeholder=" "
                    value={inputs.repeatPassword || ""}
                    name="repeatPassword"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <label>Repeat password</label>
                </div>
                <div className="Submit__Form">
                  <input
                    //className={`${loader}`}
                    type="submit"
                    value="Confirm"
                  />
                </div>
              </form>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default Invited;
