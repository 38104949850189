import {
  SET_COMPANY,
  EDIT_COMPANY,
  ADD_FLEET,
  REMOVE_FLEET,
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  REMOVE_DOCUMENT,
  ADD_COMPANY_USER,
  UPDATE_COMPANY_USER_PRIVILEGE,
  REMOVE_COMPANY_USER,
} from "../../actionTypes";

const initialState = {
  data: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        data: action.payload,
      };
    case EDIT_COMPANY:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_FLEET:
      return {
        ...state,
        data: {
          ...state.data,
          fleet: [...state.data?.fleet, action.payload],
        },
      };
    case REMOVE_FLEET:
      return {
        ...state,
        data: {
          ...state.data,
          fleet: state.data?.fleet?.filter((fleet) => fleet._id !== action.id),
        },
      };
    case ADD_DOCUMENT:
      return {
        ...state,
        data: {
          ...state.data,
          documents: [...state.data.documents, action.payload],
        },
      };
    case UPDATE_DOCUMENT:
      const newDocumentIndex = state.data.documents.findIndex(
        (document) => document._id === action.payload._id
      );
      const newDocuments = state.data.documents.map((document, index) => {
        if (index === newDocumentIndex) {
          return action.payload;
        }
        return document;
      });
      return {
        ...state,
        data: {
          ...state.data,
          documents: newDocuments,
        },
      };
    case REMOVE_DOCUMENT:
      return {
        ...state,
        data: {
          ...state.data,
          documents: state.data.documents.filter(
            (document) => document._id !== action.id
          ),
        },
      };
    case ADD_COMPANY_USER:
      return {
        ...state,
        data: {
          ...state.data,
          users: [...state.data.users, action.payload],
        },
      };
    case UPDATE_COMPANY_USER_PRIVILEGE:
      const newUserIndex = state.data.users.findIndex(
        (user) => user._id === action.payload._id
      );
      const newUsers = state.data.users.map((user, index) => {
        if (index === newUserIndex) {
          return action.payload;
        }
        return user;
      });
      return {
        ...state,
        data: {
          ...state.data,
          users: newUsers,
        },
      };
    case REMOVE_COMPANY_USER:
      return {
        ...state,
        data: {
          ...state.data,
          users: state.data.users.filter((user) => user._id !== action.id),
        },
      };
    default:
      return state;
  }
};

export default reducer;
