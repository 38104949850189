import React, { useState } from "react";
import moment from "moment";
import { sanitize } from "dompurify";

import CreateButton from "../../Dashboard/UI/CreateButton";
import DropdownFilter from "../../Filters/DropdownFilter";
import {
  ModalInnerHeaderWrapper,
  CompanyTitle,
  CompanyLetters,
  ReviewWrapper,
  FilterWrapper,
  InlineWrapper,
  SingleReview,
  SingleReviewHeader,
  SingleReviewHeaderInline,
  ReviewLettersWrapper,
  ReviewLetters,
} from "./Styles";
import { Link } from "react-router-dom";

const ReviewModalContent = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ModalInnerHeaderWrapper>
        {props.showHeader && (
          <InlineWrapper>
            <CompanyTitle>
              {props.name && (
                <>
                  <CompanyLetters>
                    {props?.name?.charAt(0) + props?.name?.charAt(1)}
                  </CompanyLetters>
                  <h3>{props?.name}</h3>
                </>
              )}
            </CompanyTitle>
            <CreateButton isButton title="Add Review" onClick={props.onClick} />
          </InlineWrapper>
        )}
        {props.reviews?.length > 1 && (
          <FilterWrapper>
            <p>Sort: </p>
            <DropdownFilter
              setSelectedFilter={(val) => props.sort(val)}
              show={show}
              setShow={setShow}
              filters={["All reviews", "Positive reviews", "Negative reviews"]}
            />
          </FilterWrapper>
        )}
      </ModalInnerHeaderWrapper>
      {props.reviews?.length > 0 && (
        <ReviewWrapper>
          {props.reviews?.map((review, i) => {
            return (
              <SingleReview key={i}>
                <SingleReviewHeader>
                  <Link
                    to={`/dashboard/company/${review?.authorCompany?._id}`}
                    target="_blank"
                  >
                    <ReviewLettersWrapper>
                      <ReviewLetters>
                        {review?.authorCompany?.companyName?.charAt(0)}
                      </ReviewLetters>
                      <h4>{review?.authorCompany?.companyName}</h4>
                    </ReviewLettersWrapper>
                  </Link>
                  <SingleReviewHeaderInline>
                    <h5>{moment(review.date).format("DD.MM.YYYY")}</h5>
                    <h4>
                      Rating: {review.review === 1 ? thumbsUp : thumbsDown}
                    </h4>
                  </SingleReviewHeaderInline>
                </SingleReviewHeader>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(review?.comment),
                  }}
                ></div>
              </SingleReview>
            );
          })}
        </ReviewWrapper>
      )}
    </>
  );
};

const thumbsUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.8"
    height="14.8"
    viewBox="0 0 14.8 14.8"
  >
    <path
      d="M3.006,6.475H.694A.694.694,0,0,0,0,7.169v6.938a.694.694,0,0,0,.694.694H3.006a.694.694,0,0,0,.694-.694V7.169A.694.694,0,0,0,3.006,6.475ZM1.85,13.644a.694.694,0,1,1,.694-.694A.694.694,0,0,1,1.85,13.644ZM11.1,2.354c0,1.226-.751,1.914-.962,2.733h2.94A1.727,1.727,0,0,1,14.8,6.767a2.093,2.093,0,0,1-.562,1.422l0,0a2.415,2.415,0,0,1-.269,2.3,2.286,2.286,0,0,1-.474,2.161,1.535,1.535,0,0,1-.178,1.29c-.59.848-2.052.86-3.289.86H9.944a8.3,8.3,0,0,1-3.456-.917,4.549,4.549,0,0,0-1.522-.468.347.347,0,0,1-.341-.347V6.889a.346.346,0,0,1,.1-.246C5.873,5.511,6.365,4.313,7.3,3.373a3.5,3.5,0,0,0,.734-1.7C8.166,1.136,8.435,0,9.019,0,9.713,0,11.1.231,11.1,2.354Z"
      fill="#3fbc61"
    />
  </svg>
);

const thumbsDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.8"
    height="14.8"
    viewBox="0 0 14.8 14.8"
  >
    <path
      d="M3.006,8.325H.694A.694.694,0,0,1,0,7.631V.694A.694.694,0,0,1,.694,0H3.006A.694.694,0,0,1,3.7.694V7.631A.694.694,0,0,1,3.006,8.325ZM1.85,1.156a.694.694,0,1,0,.694.694A.694.694,0,0,0,1.85,1.156ZM11.1,12.445c0-1.226-.751-1.914-.962-2.733h2.94A1.727,1.727,0,0,0,14.8,8.033a2.093,2.093,0,0,0-.562-1.422l0,0a2.415,2.415,0,0,0-.269-2.3,2.286,2.286,0,0,0-.474-2.161A1.534,1.534,0,0,0,13.315.86C12.725.012,11.262,0,10.026,0H9.944A8.3,8.3,0,0,0,6.488.917a4.549,4.549,0,0,1-1.522.468.347.347,0,0,0-.341.347V7.911a.346.346,0,0,0,.1.246c1.145,1.131,1.637,2.329,2.576,3.27a3.5,3.5,0,0,1,.734,1.7c.129.535.4,1.671.981,1.671C9.713,14.8,11.1,14.569,11.1,12.445Z"
      fill="#ca2e2e"
    />
  </svg>
);

export default ReviewModalContent;
