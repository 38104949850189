import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../../images/logo.svg";

import { Wrapper, Inner, Letters, MobileNav } from "./Styles";
import Routes from "./Routes";

const Dashboard = (props) => {
  const [letters, setLetters] = useState();
  const COMPANY = useSelector((state) => state.dashboard.company?.data);

  // Generate company letters
  useEffect(() => {
    if (COMPANY && !letters) {
      // company letters
      if (COMPANY.companyName) {
        const companyLetters = COMPANY.companyName.replace(/ /, "");
        let firstLetter = companyLetters.charAt(0);
        let secondLetter = companyLetters.charAt(1);
        setLetters(firstLetter + secondLetter);
      }
    }
  }, [COMPANY, letters]);

  return (
    <Wrapper>
      <MobileNav>
        <Link to="/dashboard">
          <img src={logo} alt="Transconnector" />
        </Link>
        {COMPANY && COMPANY.companyName && (
          <Link to="/dashboard">
            <Letters>{letters}</Letters>
          </Link>
        )}
      </MobileNav>
      <Inner>
        <Routes />
      </Inner>
    </Wrapper>
  );
};

export default Dashboard;
