import React from "react";
import { ListHeaderWrapper, ListHeaderItems } from "./Styles";

const ListHeader = (props) => {
  return (
    <ListHeaderWrapper>
      <ListHeaderItems>{props.children}</ListHeaderItems>
    </ListHeaderWrapper>
  );
};

export default ListHeader;
