import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import io from "socket.io-client";
import dompurify from "dompurify";
import Loader from "../UI/Loader";
import iSeen from "../../../assets/icons/seen.svg";
import iSend from "../../../assets/icons/send.svg";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { formatConversation } from "./FormatConversation";
// wysiwyg
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { API_URL } from "../../../constants";

const socket = io(`${API_URL}`, {
  transports: ["websocket"],
  secure: true,
});

const MessageBox = (props) => {
  const [s3Hash, setS3Hash] = useState();
  const [message, setMessage] = useState("");
  const [height, setHeight] = useState(80);

  const heightRef = useRef(null);
  const sanitizer = dompurify.sanitize;
  const [formatedConversation, setFormatedConversation] = useState([]);

  useEffect(() => {
    axios.get(`${API_URL}/s3hash`).then((res) => setS3Hash(res.data));
  }, []);

  // Set the viewport height of the message box
  useEffect(() => {
    if (heightRef.current.clientHeight === 0 && !isMobile) {
      // fix error for clientheight not being available at render
      setHeight(80);
    } else {
      setHeight(heightRef.current.clientHeight);
    }
  }, [message]);

  // Listen to message submissions
  const userId = props.USER_DATA?.id;
  const { MESSAGE_DATA, updateConversation, CURRENT_MESSAGE_WINDOW } = props;
  useEffect(() => {
    socket.off(MESSAGE_DATA.currentMessageWindow);
    socket.on(MESSAGE_DATA.currentMessageWindow, (res) => {
      const newMessage = {
        type: userId === res.sender ? "sender" : "reciever",
        message: res,
      };
      updateConversation(newMessage);
    });
  }, [CURRENT_MESSAGE_WINDOW, MESSAGE_DATA, userId, updateConversation]);

  // Reformat message structure whenever messages change
  useEffect(() => {
    if (props.conversation.messages) {
      const data = props.conversation.messages;
      setFormatedConversation(formatConversation(data));
    }
  }, [props.conversation.messages]);

  // Handle sending the message
  const sendMessage = () => {
    if (message?.length > 0) {
      const cookies = new Cookies(),
        token = cookies.get("tc_token");
      axios.post(
        `${API_URL}/msg`,
        {
          conversationId: props.MESSAGE_DATA.currentMessageWindow,
          message: sanitizer(message, {
            ALLOWED_ATTR: ["target", "href", "src"],
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage("");
    }
  };

  return (
    <div className="Current_Message_Box">
      <div
        className="Current_Message_Box--inner"
        style={{
          height: `calc((100vh - ${
            isMobile ? (height === 0 ? "216px" : "136px") : "106px"
          }) - ${isMobile ? height - 80 : height}px)`,
        }}
      >
        {formatedConversation ? (
          <div className="Conversation">
            {formatedConversation.map((c) => {
              return (
                <div
                  key={c.message._id}
                  className="sent-message"
                  data-start={c.wrapStart ? "true" : null}
                  data-end={c.wrapEnd ? "true" : null}
                >
                  <div
                    className={c.type}
                    data-created={moment(c.message.createdAt)
                      .startOf("minute")
                      .fromNow()}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(c.message.content, {
                        ALLOWED_ATTR: ["target", "href", "src"],
                      }),
                    }}
                  ></div>
                  {c.wrapEnd && c.type === "reciever" ? (
                    <img
                      src={props.conversation?.reciever?.recieverAvatar}
                      className="reciever-avatar"
                      alt=""
                    />
                  ) : null}
                  {c.seen && <img src={iSeen} alt="Seen" className="seen" />}
                </div>
              );
            })}
          </div>
        ) : formatedConversation?.length <= 0 ? (
          "No messages yet"
        ) : (
          <Loader />
        )}
      </div>
      <div className="Send_Message">
        <div className="Send_Message--inner" ref={heightRef}>
          {s3Hash && (
            <FroalaEditorComponent
              tag="textarea"
              model={message}
              config={{
                placeholderText: "Type your message...",
                charCounterCount: false,
                buttons: ["insertImage", "insertFile"],
                toolbarButtons: {
                  moreRich: {
                    buttons: ["insertImage", "insertFile"],
                    buttonsVisible: 2,
                    imageAllowedTypes: ["jpeg", "jpg", "png"],
                  },
                },
                autofocus: true,
                imageAllowedTypes: ["jpeg", "jpg", "png"],
                toolbarSticky: false,
                wordPasteKeepFormatting: true,
                wordPasteModal: false,
                attribution: false,
                quickInsertEnabled: false,
                enter: FroalaEditorComponent.ENTER_BR,
                zIndex: 90,
                heightMin: 48,
                imageUploadToS3: s3Hash,
                fileUploadToS3: s3Hash,
                imageMaxSize: 5 * 1024 * 1024, // 5MB
                key:
                  "hWA2C-7F2C2B1D5C2D2vD-8B-7lc1A-16sD-13C-11A-9qvbxjA2F-11ng1G3I3A2B6D6B3D3E4B2G1==",
                events: {
                  focus: function () {
                    //setMessageBoxHasFocus(true);
                  },
                  blur: function () {
                    //setMessageBoxHasFocus(false);
                  },
                  "image.uploadedToS3": function (link, key, response) {
                    // Do something here.
                    // this is the editor instance.
                  },
                },
              }}
              onModelChange={(e) => setMessage(e)}
            />
          )}
          <div className="Send_Icon" onClick={sendMessage}>
            <img src={iSend} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    MESSAGE_DATA: state.dashboard.message,
    USER_DATA: state.dashboard.user.data,
    CURRENT_MESSAGE_WINDOW: state.dashboard.message.currentMessageWindow,
  };
};

export default connect(mapStateToProps)(MessageBox);
