import React, { useState, useEffect } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import {
  InputWrapper,
  Label,
  LabelWrapper,
  MultiSelectWrapper,
  MultiSelectOuterWrapper,
  MultiSelectInnerWrapper,
  ClickableButton,
  selectStyles,
  ErrorContainer,
  ErrorWrapper,
  DeleteIcon,
  RadiusWrapper,
} from "../CreateForm/Styles";
import RadiusFilter from "../PageWithList/Filter/RadiusFilter";
import { API_URL } from "../../constants";

const AsyncInput = (props) => {
  const [inputCount, setInputCount] = useState(1);

  // Set the count of inputs from pre-defined values
  const { maxInputCount, type, inputs, showRadius } = props;
  useEffect(() => {
    for (let i = 0; i < maxInputCount; ++i) {
      if (inputs?.[`${type}${i}`] && i < maxInputCount - 1) {
        setInputCount((prevState) => prevState + 1);
      }
    }
  }, [maxInputCount, type]);

  // Load async location options
  const loadOptions = async (query) => {
    const response = await axios.get(
      `${API_URL}${!props.endpoint ? "/locations" : props.endpoint}`,
      {
        params: {
          query: query,
        },
      }
    );
    const json = await response;
    return json.data;
  };

  const handleSelectDelete = (type) => {
    props.handleInputDelete(type);
    setInputCount((prevState) => prevState - 1);
  };

  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <MultiSelectWrapper>
          {Array.from(Array(inputCount), (e, i) => {
            return (
              <MultiSelectOuterWrapper key={i} radius={showRadius ? 1 : null}>
                <MultiSelectInnerWrapper radius={showRadius ? 1 : null}>
                  <ErrorContainer>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptions}
                      isClearable
                      placeholder="City, postal code"
                      noOptionsMessage={() => "Search by city / postal code"}
                      loadingMessage={() => "Search by city / postal code"}
                      styles={selectStyles}
                      name={type}
                      classNamePrefix="styled"
                      className={props.errors?.[`${type}${i}`] && "invalid"}
                      value={inputs?.[`${type}${i}`] || null}
                      onChange={(e) => props.onSelectChange(e, `${type}${i}`)}
                    />
                    {i !== 0 && inputCount === i + 1 && (
                      <DeleteIcon
                        onClick={() => handleSelectDelete(`${type}${i}`)}
                      >
                        {deleteIcon}
                      </DeleteIcon>
                    )}
                    {showRadius && (
                      <RadiusWrapper>
                        <RadiusFilter
                          type={`${type}radius${i}`}
                          inputs={inputs}
                          onInputChange={props.handleInputChange}
                        />
                      </RadiusWrapper>
                    )}
                    {props.errors?.[`${type}${i}`] && (
                      <ErrorWrapper>
                        <span>{props.errors?.[`${type}${i}`].msg}</span>
                      </ErrorWrapper>
                    )}
                  </ErrorContainer>
                </MultiSelectInnerWrapper>
                {inputCount < maxInputCount &&
                  inputs?.[`${type}${i}`] &&
                  !inputs?.[`${type}${i + 1}`] && (
                    <ClickableButton radius={showRadius ? 1 : null}>
                      <button
                        type="button"
                        onClick={() =>
                          setInputCount((prevState) => prevState + 1)
                        }
                      >
                        + Add {type} location
                      </button>
                    </ClickableButton>
                  )}
              </MultiSelectOuterWrapper>
            );
          })}
        </MultiSelectWrapper>
      </LabelWrapper>
    </InputWrapper>
  );
};

const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.782"
    height="18.781"
    viewBox="0 0 18.782 18.781"
  >
    <g transform="translate(-9770.725 15104.32)">
      <g transform="translate(8438 -15328)">
        <path
          d="M-5412.861-16947.906l15.954,15.953"
          transform="translate(6747 17173)"
          fill="none"
          stroke="#ce1e1e"
          strokeWidth="4"
        />
        <path
          d="M-5396.907-16947.906l-15.954,15.953"
          transform="translate(6747 17173)"
          fill="none"
          stroke="#ce1e1e"
          strokeWidth="4"
        />
      </g>
    </g>
  </svg>
);

export default AsyncInput;
