import React from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  Input,
  InputArrow,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";

const NumberInput = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Input error={props.error}>
            <input
              type="number"
              placeholder={
                !props.cubic ? props.placeholder : `${props.placeholder}³`
              }
              min={props.min}
              max={props.max}
              step={props.step || 0.01}
              name={props.name}
              value={props.value}
              onChange={(e) =>
                !props.descendant
                  ? props.onInputChange(+e.target.value, props.name)
                  : props.onInputChange(
                      +e.target.value,
                      props.name,
                      props.descendant
                    )
              }
            />
            <InputArrow top>
              <button
                type="button"
                onClick={() =>
                  !props.descendant
                    ? props.onInputChange(
                        parseFloat(
                          props.value + ((props.step && props.step) || 1)
                        ),
                        props.name
                      )
                    : props.onInputChange(
                        parseFloat(
                          props.value + ((props.step && props.step) || 1)
                        ),
                        props.name,
                        props.descendant
                      )
                }
              >
                {incrementArrow}
              </button>
            </InputArrow>
            <InputArrow bottom>
              <button
                type="button"
                onClick={() =>
                  !props.descendant
                    ? parseFloat(props.value) >= 0
                      ? props.onInputChange(
                          parseFloat(
                            props.value - ((props.step && props.step) || 1)
                          ),
                          props.name
                        )
                      : null
                    : parseFloat(props.value) >= 0
                    ? props.onInputChange(
                        parseFloat(
                          props.value - ((props.step && props.step) || 1)
                        ),
                        props.name,
                        props.descendant
                      )
                    : null
                }
              >
                {decrementArrow}
              </button>
            </InputArrow>
          </Input>
          {props.error && (
            <ErrorWrapper>
              <span>{props.error?.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

const incrementArrow = (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#686A7D"
      d="M15.5,13.7c-0.4,0.4-1,0.5-1.6,0L10,9.9l-3.9,3.7c-0.5,0.5-1.1,0.4-1.6,0c-0.4-0.4-0.4-1.2,0-1.6
	c0.4-0.4,4.7-4.5,4.7-4.5C9.4,7.3,9.7,7.2,10,7.2s0.6,0.1,0.8,0.3c0,0,4.3,4.1,4.7,4.5S15.9,13.2,15.5,13.7z"
    />
  </svg>
);

const decrementArrow = (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#686A7D"
      d="M4.5,7.5c0.4-0.4,1-0.5,1.6,0l3.9,3.7l3.9-3.7c0.5-0.5,1.1-0.4,1.6,0c0.4,0.4,0.4,1.2,0,1.6
	c-0.4,0.4-4.7,4.5-4.7,4.5C10.6,13.9,10.3,14,10,14s-0.6-0.1-0.8-0.3c0,0-4.3-4.1-4.7-4.5S4.1,8,4.5,7.5z"
    />
  </svg>
);

export default NumberInput;
