import React from "react";
import Container from "../../components/UI/Container";
import { Static, PrivacyList } from "./Styles";

const Privacy = () => {
  return (
    <Container gutter="large">
      <Static>
        <h1>PRIVACY</h1>
        <PrivacyList>
          <h3>As of June 11, 2019</h3>
          <p>
            Transconnector is pleased that you have chosen our website and that
            you are interested in our company, products, and services. It is
            important for us to protect your personal data during handling
            throughout the entire business process. We want you to feel secure
            while using our website. <br />
            In the following, we explain what information we collect while using
            our website and how this information is used.
          </p>
          <h3>Definition of personal data</h3>
          <p>
            Personal data is specific information about personal or factual
            characteristics relating to a certain natural person or a natural
            person who can be specified. This includes information such as your
            real name, address, telephone number and date of birth. Information
            that cannot be directly linked to your real identity – such as
            favorite websites or number of users of a site – is not considered
            personal data.
          </p>
          <h3>Collection and processing of personal data</h3>
          <p>
            Transconnector is committed to preserving the privacy of users of
            our website. While using our website, our servers may temporarily
            save for security purposes the connection data of the computer
            connecting to our servers, a list of the pages or functions that you
            use within our website, the date and duration of your use, the
            identification data of the type of device and operating system used.
          </p>
          <p>
            Additional personal information such as your name, address,
            telephone number or e-mail address is not collected unless you
            provide this data voluntarily, e.g. while completing an online
            contact form, as part of account registration, fulfillment of a
            contract or an information request.
          </p>
          <p>
            Certain areas of the Transconnector websites require registration or
            a password for access. Information obtained from users of these
            areas may also be used for Transconnector marketing purposes within
            the limits of national law. Transconnector provides a right of
            access and rectification of personal data under the applicable
            legislation.
          </p>
          <h3>Google Analytics</h3>
          <p>
            We use Google Analytics, a web analytics service provided by Google
            Inc. ("Google"). Google uses cookies. Google will use this
            information on our behalf to evaluate the use of our online offering
            by users, to compile reports on the activities within this online
            offering and to provide us with other services related to the use of
            this online offer and internet usage. In this case, pseudonymous
            user profiles of the processed data can be created.
          </p>
          <p>
            We only use Google Analytics with activated IP anonymization. This
            means that the IP address of the users will be shortened by Google
            within member states of the European Union or in other contracting
            states of the Agreement on the European Economic Area. Only in
            exceptional cases will the full IP address be sent to a Google
            server in the US and shortened there. The IP address submitted by
            the user’s browser will not be merged with other data provided by
            Google. Users can prevent the storage of cookies by setting their
            browser software accordingly;
          </p>
          <p>
            Users may also prevent the collection by Google of the data
            generated by the cookie and related to their use of the online offer
            and the processing of such data by Google by downloading and
            installing the browser plug-in available under the following link:{" "}
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tools.google.com/dlpage/gaoptout?hl=en.
            </a>{" "}
            <br /> <br /> For more information about Google’s use of data,
            recruitment and opt-out options, please visit Google’s websites:
          </p>
          <ul>
            <li>
              <a
                href="https://www.google.com/intl/de/policies/privacy/partners/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/intl/de/policies/privacy/partners/
              </a>{" "}
              ("Google uses your data when you use websites or apps from our
              partners")
            </li>
            <li>
              <a
                href="http://www.google.com/policies/technologies/ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.google.com/policies/technologies/ads
              </a>{" "}
              ("Use of data for advertising purposes")
            </li>
            <li>
              <a
                href="http://www.google.de/settings/ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.google.de/settings/ads
              </a>{" "}
              ("Manage information Google uses to show you advertising")
            </li>
            <li>
              <a
                href="http://www.google.com/ads/preferences/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.google.com/ads/preferences/
              </a>{" "}
              ("Determine which Google advertising shows you")
            </li>
          </ul>
          <p>
            To opt out of being tracked by Google Analytics across all websites,
            visit:
          </p>
          <h4>Google Analytics Opt-out Browser Add-on</h4>
          <p>
            Not everyone who visits our site will do so using a web browser. For
            example, some users will access Transconnector websites or
            applications using a mobile device. If so, it may not be possible to
            disable cookies or adjust web browser settings.
          </p>
          <h4>Google Tag Manager</h4>
          <p>
            We use Google Tag Manager, a web service provided by Google to
            manage website tags. The Google Tag Manager tool itself (which
            implements the tags) is a cookieless domain and does not collect
            personally identifiable information. The tool triggers other tags,
            which may collect data. Google Tag Manager does not access this
            data. If disabled at the domain or cookie level, it will remain in
            effect for all tracking tags implemented with Google Tag Manager.
            You can read more about Tag Manager in the Google Tag Manager
            information.
          </p>
          <h3>Contact</h3>
          <p>
            If you have questions in regard to the processing of your personal
            data or in cases of information requests, suggestions or complaints,
            please contact us{" "}
            <a href="mailto:support@transconnector.com">
              support@transconnector.com.
            </a>
          </p>
        </PrivacyList>
      </Static>
    </Container>
  );
};

export default Privacy;
