import React, { useState, useCallback, useEffect } from "react";
import { UPDATE_USER } from "../../../store/actionTypes";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";

import iMyAccount from "../../../assets/icons/my-account.svg";
import Form from "../UI/Form";
import FormBlock from "../UI/FormBlock";
import Input from "../UI/Input";
import Loader from "../UI/Loader";
import {
  updateUserImage,
  updateUser,
} from "../../../store/actions/dashboard/user";
import ScreenBlock from "../Screen/ScreenBlock";

const MyAccount = (props) => {
  const [componentState, setComponentState] = useState({
    avatarPreview: [],
    profilePictureError: null,
    profilePictureLoading: false,
    loadingSubmission: false,
    saved: false,
  });
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { USER } = props;

  // ********** LOGIC FOR DROPZONE ********** //

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    setComponentState((prevState) => ({
      ...prevState,
      profilePictureError: null,
      profilePictureLoading: true,
    }));
    dispatch(updateUserImage(formData))
      .then((res) => {
        // Set avatar preview on uploading image and set loading to false
        setComponentState((prevState) => ({
          ...prevState,
          avatarPreview: res.data.pictureUrl,
          profilePictureLoading: false,
        }));
        // Add image to state upon uploading
        setInputs((prevState) => ({
          ...prevState,
          profilePicture: res.data.pictureUrl,
        }));
      })
      .catch((err) => {
        setComponentState((prevState) => ({
          ...prevState,
          profilePictureError: err.response.data.errors[0].title,
          profilePictureLoading: false,
        }));
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    maxSize: 2000000,
    onDrop,
  });

  // **************************************** //

  // Load inputs and show avatar preview

  useEffect(() => {
    if (USER) {
      // Set default input values
      Object.keys(USER).forEach((key) => {
        if (!inputs.hasOwnProperty(key)) {
          setInputs((prevState) => ({
            ...prevState,
            [key]: USER[key],
          }));
        }
      });
      // Set default user avatar
      if (USER.pictureUrl) {
        setComponentState((prevState) => ({
          ...prevState,
          avatarPreview: USER.pictureUrl,
        }));
      }
    }
  }, [USER]);

  // Handle input changes

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    // clear error message
    setErrors({ ...errors, [e.target.name]: "" });
  };

  // Handle editing a user

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    setComponentState((prevState) => ({
      ...prevState,
      saved: false,
      loadingSubmission: true,
    }));
    dispatch(updateUser(inputs))
      .then((res) => {
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
          saved: true,
        }));
        setTimeout(() => {
          setComponentState((prevState) => ({
            ...prevState,
            saved: false,
          }));
        }, 2000);
        // TODO: SET TOAST
      })
      .catch((err) => {
        // TODO: SET TOAST
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
        }));
        const errors = err.response.data.errors;
        setErrors(errors);
      });
  };

  // Show loader when user data is not ready to be shown

  if (!USER) {
    return (
      <ScreenBlock>
        <Loader />
      </ScreenBlock>
    );
  }

  return (
    <ScreenBlock title="My Account" img={iMyAccount}>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FormBlock title="Account information">
          <Input
            InputType="input"
            type="text"
            label="First name"
            placeholder="First name"
            name="firstName"
            onChange={handleInputChange}
            value={inputs.firstName || ""}
            required
            className={(errors.firstName && "invalid") || ""}
            error={errors.firstName && errors.firstName.msg}
          />
          <Input
            InputType="input"
            type="text"
            label="Last name"
            placeholder="Last name"
            name="lastName"
            onChange={handleInputChange}
            value={inputs.lastName || ""}
            required
            className={(errors.lastName && "invalid") || ""}
            error={errors.lastName && errors.lastName.msg}
          />
          <Input
            InputType="input"
            type="text"
            label="E-Mail"
            placeholder="E-Mail address"
            name="email"
            autocomplete="email"
            value={inputs.email || ""}
            required
            disabled
            className={(errors.email && "invalid") || ""}
            error={errors.email && errors.email.msg}
          />
          <Input
            InputType="input"
            type="phone"
            label="Phone"
            placeholder="Phone number"
            name="phone"
            value={inputs.phone || ""}
            onChange={handleInputChange}
            required
            className={(errors.phone && "invalid") || ""}
            error={errors.phone && errors.phone.msg}
          />
          <div className="input-collection">
            <div className="input-wrapper">
              <h4>User avatar</h4>
              {!componentState.profilePictureLoading ? (
                <div className="input">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Click to edit</p>
                    <div className="avatar-preview">
                      <img src={componentState.avatarPreview} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
            {componentState.profilePictureError && (
              <div className="error">
                <p>{componentState.profilePictureError}</p>
              </div>
            )}
          </div>
        </FormBlock>
        <FormBlock title="Change your password">
          <Input
            InputType="input"
            type="password"
            label="Current Password"
            placeholder="Current password"
            name="currentPassword"
            autocomplete="current-password"
            value={inputs.currentPassword || ""}
            onChange={handleInputChange}
            className={(errors.currentPassword && "invalid") || ""}
            error={errors.currentPassword && errors.currentPassword.msg}
          />
          <Input
            InputType="input"
            type="password"
            label="New Password"
            placeholder="New Password"
            name="newPassword"
            autocomplete="new-password"
            value={inputs.newPassword || ""}
            onChange={handleInputChange}
            className={(errors.newPassword && "invalid") || ""}
            error={errors.newPassword && errors.newPassword.msg}
          />
          <Input
            InputType="input"
            type="password"
            label="Repeat new Password"
            placeholder="Repeat new Password"
            name="newPassword2"
            autocomplete="new-password"
            value={inputs.newPassword2 || "" || ""}
            onChange={handleInputChange}
            className={
              (inputs.newPassword !== inputs.newPassword2 && "invalid") || ""
            }
            error={
              inputs.newPassword !== inputs.newPassword2 &&
              "Passwords do not match"
            }
          />
        </FormBlock>
        <div className="submit">
          {!componentState.loadingSubmission ? (
            <input
              type="submit"
              value={componentState.saved ? "Saved" : "Save"}
              disabled={componentState.saved ? true : false}
              className={(componentState.saved && "success") || ""}
            />
          ) : (
            <Loader />
          )}
        </div>
      </Form>
    </ScreenBlock>
  );
};

export default MyAccount;
