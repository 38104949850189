import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import io from "socket.io-client";

import { formatConversation } from "./FormatConversation";
import "../../../styles/Dashboard/Messages/Minimessages.css";
import iOptions from "../../../assets/icons/options-horizontal.svg";
import iClose from "../../../assets/icons/close.svg";

import MinimessagesBox from "./MinimessagesBox";

import { SET_ACTIVE_MINI_CONVERSATIONS } from "../../../store/actionTypes";
import { API_URL } from "../../../constants";
const socket = io(`${API_URL}`, {
  transports: ["websocket"],
  secure: true,
});

const Minimessages = (props) => {
  const [visible, setVisible] = useState(false);

  // Start listening to user messages
  const { USER_DATA, ACTIVE_CONVERSATIONS, onSetActiveConversations } = props;
  useEffect(() => {
    if (USER_DATA?.id) {
      socket.on(USER_DATA?.id, (res) => {
        if (
          ACTIVE_CONVERSATIONS?.length > 0 &&
          ACTIVE_CONVERSATIONS?.length <= 3
        ) {
          let found = false;
          ACTIVE_CONVERSATIONS.some((e) =>
            e.conversationId === res.conversationId
              ? (found = true)
              : (found = false)
          );
          if (
            !found &&
            ACTIVE_CONVERSATIONS?.length > 0 &&
            ACTIVE_CONVERSATIONS?.length < 3
          ) {
            onSetActiveConversations([
              ...ACTIVE_CONVERSATIONS,
              { ...res, messages: formatConversation(res.messages) },
            ]);
          } else {
            const conversationIndex = ACTIVE_CONVERSATIONS.findIndex(
              (x) => x.conversationId === res.conversationId
            );
            if (ACTIVE_CONVERSATIONS[conversationIndex]?.messages) {
              ACTIVE_CONVERSATIONS[
                conversationIndex
              ].messages = formatConversation(res.messages);
              const updated = [
                ...ACTIVE_CONVERSATIONS.slice(0, conversationIndex),
                ACTIVE_CONVERSATIONS[conversationIndex],
                ...ACTIVE_CONVERSATIONS.slice(conversationIndex + 1),
              ];
              onSetActiveConversations(updated);
            }
          }
        } else if (ACTIVE_CONVERSATIONS?.length === 0) {
          onSetActiveConversations([
            ...ACTIVE_CONVERSATIONS,
            {
              ...res,
              messages: formatConversation(res.messages),
            },
          ]);
        }
      });
      return () => socket.off(USER_DATA?.id);
    }
  }, [USER_DATA, ACTIVE_CONVERSATIONS, onSetActiveConversations]);

  const [minimizedConversations, setMinimizedConversations] = useState([]);
  const minimizeConversationHandler = (e) => {
    const id = e.target.dataset.id;
    if (minimizedConversations.includes(id)) {
      setMinimizedConversations(minimizedConversations.filter((e) => e !== id));
    } else {
      setMinimizedConversations([...minimizedConversations, id]);
    }
  };

  const [dropdownId, setDropdownId] = useState();
  const useOutsideClick = () => {
    const handleClick = (e) => {
      if (e.target.getAttribute("class") === "options true") {
        setDropdownId();
        setVisible(false);
      } else if (e.target.getAttribute("class") === "options false") {
        setVisible(true);
      } else {
        setDropdownId();
        setVisible(false);
      }
    };
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);
  };
  useOutsideClick();
  const dropdownConversationHandler = (e) => {
    const id = e.target.dataset.id;
    if (!minimizedConversations.includes(id)) {
      dropdownId !== id ? setDropdownId(id) : setDropdownId();
    }
  };

  const closeConversationHandler = (id) => {
    props.onSetActiveConversations(
      props.ACTIVE_CONVERSATIONS.filter((o) => o.conversationId !== id)
    );
  };

  return (
    <div
      className={`Mini_Messages ${!props.SHOW_MINI_MESSAGES ? "hide" : ""} ${
        props.TENDER_MODAL_OPEN ? "pos-adjusted" : ""
      }`}
    >
      <div className="Mini_Messages--inner">
        {props.ACTIVE_CONVERSATIONS?.length > 0 &&
          props.ACTIVE_CONVERSATIONS?.map((conversation) => {
            return (
              <div
                className={`Mini_Message ${
                  minimizedConversations.includes(conversation.conversationId)
                    ? "minimize"
                    : ""
                }`}
                key={conversation.conversationId}
              >
                <div className="Mini_Message--header">
                  <div
                    className="Message--header-minimize"
                    data-id={conversation.conversationId}
                    onClick={(e) => minimizeConversationHandler(e)}
                  >
                    <div className="Message--header--image">
                      <img
                        src={conversation?.reciever?.recieverAvatar}
                        alt=""
                      />
                    </div>
                    <div className="Message--header--recipent">
                      <h3>{conversation.offer.title}</h3>
                      <h4>{conversation.reciever.recieverName}</h4>
                    </div>
                  </div>
                  <div className="Message--header--actions">
                    <div
                      className={`options ${visible}`}
                      data-id={conversation.conversationId}
                      onClick={(e) => dropdownConversationHandler(e)}
                    >
                      <img src={iOptions} alt="Options" />
                      {dropdownId === conversation.conversationId && (
                        <div className="options-dropdown">
                          <ul>
                            <li>
                              <Link
                                to={{
                                  pathname: "/dashboard/messages",
                                  state: {
                                    showMessage: conversation.conversationId,
                                  },
                                }}
                              >
                                Go to messages
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/dashboard/company/${conversation.reciever.recieverCompanyId}`}
                              >
                                View company profile
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div
                      className="close"
                      onClick={() =>
                        closeConversationHandler(conversation.conversationId)
                      }
                    >
                      <img src={iClose} alt="Close" />
                    </div>
                  </div>
                </div>
                <MinimessagesBox
                  minimizedConversations={minimizedConversations}
                  conversation={conversation}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    USER_DATA: state.dashboard.user.data,
    ACTIVE_CONVERSATIONS: state.dashboard.message.activeMiniConversations,
    SHOW_MINI_MESSAGES: state.dashboard.message.showMiniMessages,
    TENDER_MODAL_OPEN: state.app.misc.tenderModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveConversations: (payload) =>
      dispatch({ type: SET_ACTIVE_MINI_CONVERSATIONS, payload: payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Minimessages);
