import styled, { css } from "styled-components";

export const FilterWrapper = styled.div`
  font-family: "Inter";
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  z-index: 10;
  @media (min-width: 992px) {
    top: 134px;
    position: absolute;
  }
  @media (min-width: 1200px) {
    display: flex;
    padding: 1.25rem 0;
    border-top: 1px solid #e0e6ed;
    border-bottom: 1px solid #e0e6ed;
    background: #fff;
    ${(props) =>
      props.fixed &&
      css`
        position: fixed;
        top: 0;
      `}
  }
`;

export const FilterWrapperInner = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    flex-direction: column;
    padding: 0 1rem;
  }
  @media (min-width: 1440px) {
    max-width: 1286px;
  }
  @media (min-width: 1740px) {
    max-width: 1556px;
  }
`;

export const FilterInner = styled.div``;

export const FilterToggler = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fafbfc;
  border-top: 1px solid #e0e6ed;
  border-bottom: 1px solid #e0e6ed;
  padding: 1rem;
  svg {
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
      margin-left: 1.5rem;
    }
  }
  h4 {
    font-weight: 600;
    font-size: 1rem;
    color: #0f1540;
    margin: 0;
  }
  @media (min-width: 1200px) {
    width: auto;
    padding: 0;
    background: none;
    border: none;
    justify-content: flex-start;
    margin-bottom: 1rem;
    svg:last-child {
      display: none;
    }
    svg {
      margin-right: 1rem;
    }
  }
  ${(props) =>
    props.open &&
    css`
      svg:last-child {
        transform: rotate(180deg);
      }
    `}
  ${(props) =>
    props.fixed &&
    css`
      @media (min-width: 992px) {
        position: fixed;
        top: 0;
      }
      @media (min-width: 1200px) {
        position: relative;
      }
    `}
`;

export const OptionsWrapper = styled.div`
  position: fixed;
  top: calc(64px + 53.2px - 1px);
  width: 100%;
  background: #fff;
  box-shadow: 0px 30px 30px 0px rgba(151, 180, 203, 0.16);
  padding: 3rem 1rem;
  @media (max-width: 350px) {
    max-height: 350px;
  }
  @media (max-width: 400px) {
    max-height: 425px;
    overflow-y: auto;
  }
  @media (min-width: 992px) {
    top: 53.2px;
    position: absolute;
    padding: 3rem calc(84px + 1rem);
  }
  @media (min-width: 1200px) {
    display: inline-flex;
    width: 100%;
    padding: 0;
    top: 0;
    box-shadow: none;
    background: none;
    position: relative;
    align-items: flex-start;
  }
  @media (max-width: 1200px) {
    ${(props) =>
      !props.open &&
      css`
        display: none;
      `}
  }
  ${(props) =>
    props.fixed &&
    css`
      @media (min-width: 992px) {
        position: fixed;
        top: 53.2px;
      }
      @media (min-width: 1200px) {
        top: 0;
        position: relative;
      }
    `}
`;

export const FilterOptionsWrapper = styled.div``;

export const FilterItemWrapper = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 200px;
    margin-right: 2rem;
    position: relative;
  }
  @media (min-width: 1320px) {
    max-width: 250px;
  }
  ${({ marginMobile }) =>
    marginMobile &&
    css`
      @media (max-width: 1200px) {
        margin: ${marginMobile};
      }
    `}
`;

export const RadiusWrapper = styled.div`
  margin-bottom: 0.75rem;
  h6 {
    margin: 0;
    padding-bottom: 0.25rem;
    position: relative;
    color: #707070;
    font-size: 0.7rem;
    font-weight: 600;
    padding-top: 0.25rem;
  }
  @media (min-width: 1200px) {
    margin-bottom: 0;
    .rc-slider {
      position: absolute;
      bottom: -32.5px;
      left: 0;
    }
    h6 {
      position: absolute;
      left: 0;
      bottom: -16px;
      padding: 0;
      user-select: none;
    }
  }
`;

export const ClearFilters = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  color: #686a7d;
  font-size: 1rem;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #686a7d;
    height: 1px;
  }
  @media (min-width: 992px) {
    right: 6.25rem;
  }
  @media (min-width: 1200px) {
    top: -2.15rem;
    left: 7.5rem;
    width: max-content;
    &:hover {
      cursor: pointer;
    }
  }
`;
export const LocationWrapper = styled.div`
  width: 100%;
`;

export const RadioGroup = styled.div`
  display: inline-flex;
  margin: 0.5rem 0;
`;
export const Radio = styled.div`
  input {
    width: 30px;
    height: 30px;
    opacity: 0;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
  }
  label {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.25rem;
    color: #686a7d;
    font-weight: 400;
    font-size: 16px;
    &:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #9aabc2;
      border-radius: 3px;
      top: 0.25rem;
      left: 0.25rem;
    }
    &:hover {
      cursor: pointer;
    }
  }
  ${(props) =>
    props.checked &&
    css`
      label {
        &:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: solid #262a68;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 0.5rem;
          left: 0.75rem;
        }
      }
    `}
`;

export const ViewResults = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  button {
    font-family: "Inter";
    width: 150px;
    color: #686a7d;
    height: 50px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: 250,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "0.25rem",
    border: "1px solid #9aabc2",
    borderRadius: 6,
    boxShadow: state.isFocused ? "0 0 0 2px #a0d1fa" : "none",
    minHeight: "50px",
    "&:hover": {
      borderColor: "#9aabc2",
    },
    "@media (min-width: 1200px)": {
      marginBottom: "0",
      maxWidth: 250,
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "14px",
    "&:focus": {
      boxShadow: "none",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#686a7d",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#686a7d",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#0C0C0C",
    fontSize: "14px",
  }),
};

export const DateWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    display: inline-flex;
    align-items: center;
    height: 50px;
    width: 100%;
  }
  .DateRangePickerInput__withBorder {
    border: 1px solid #9aabc2;
    border-radius: 6px;
  }
  .DateInput {
    width: 100%;
    height: 48px;
    margin-left: 3px;
    margin-right: 3px;
  }
  .DateInput_input {
    font-family: "Inter";
    font-weight: 400;
    color: #686a7d;
    font-size: 14px;
    height: 48px;
    padding: 11px 8px 9px 8px;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid #15198f;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #15198f;
    border: 1px double #15198f;
  }
  .CalendarDay__selected_span {
    background: #4785ff;
    border: 1px double #3a7cff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active,
  .CalendarDay__hovered_span:hover {
    background: #4785ff;
    border: 1px double #3a7cff;
    color: #fff;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #4785ff;
  }
  @media (min-width: 1200px) {
    margin-right: 2rem;
    margin-bottom: 0;
    .DateRangePickerInput {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: auto;
      width: 100%;
    }
    .DateRangePickerInput_arrow {
      display: none;
    }
    .DateInput {
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        input {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
      }
      &:last-child {
        border-top: 1px solid #9aabc2;
        margin-bottom: 1px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        input {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
`;

export const ClearableWrapper = styled.div`
  max-width: 225px;
  @media (min-width: 1200px) {
    margin-right: 2rem;
    width: 100%;
    .DateRangePickerInput__showClearDates {
      padding-right: 0;
    }
    .DateRangePickerInput_clearDates {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fafafa;
      &:hover {
        background: #f4f4f4;
      }
    }
  }
`;
