import React from "react";
import ReactDOM from "react-dom";
import "./polyfills";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import store from "./store/store";
import NotificationWrapper from "./helpers/NotificationWrapper";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://6d1f38d6d0b746bb83574c4afd1abbad@o414079.ingest.sentry.io/5302913",
  });
}

ReactDOM.render(
  <Provider store={store}>
    <NotificationWrapper>
      <App />
    </NotificationWrapper>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
