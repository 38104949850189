import { getToken } from "../../../helpers/getToken";
import axios from "axios";
import { API_URL } from "../../../constants";

export const getQuotations = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(`${API_URL}/quotations`, config);
  };
};

export const sortQuotations = (sortBy, order) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(
      `${API_URL}/quotations?sortBy=${sortBy}&order=${order}`,
      config
    );
  };
};

export const setQuotationStatus = (id, status) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.put(
      `${API_URL}/quotationStatus`,
      {
        offerId: id,
        status: status,
      },
      config
    );
  };
};

export const sendQuotation = (
  method,
  tenderId,
  offerId,
  price,
  noOfTrucks,
  period,
  comment
) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios({
      method: method,
      url: `${API_URL}/quotations`,
      data: {
        tenderId,
        offerId,
        price,
        noOfTrucks,
        period,
        comment,
      },
      ...config,
    });
  };
};
