import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/Dashboard/UI/Card.css";

const Card = (props) => {
  return (
    <div className={`Card ${props.className ? props.className : ""}`}>
      {props.title ? (
        <div className="Card-header">
          {props.icon && <img src={props.icon} alt="" />}
          <h3>{props.title}</h3>
          {props.edit && props.privileges === "Admin" ? (
            <Link to={props.edit}>Edit</Link>
          ) : null}
        </div>
      ) : null}
      <div className="Card-content">{props.children}</div>
    </div>
  );
};

export default Card;
