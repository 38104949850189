import {
  UPDATE_USER_NOTIFICATION,
  SET_USER_SUBSCRIPTION,
  SET_USER,
} from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

/* const token = getToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const formDataConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
}; */

export const getUser = (token) => {
  return (dispatch) => {
    axios
      .get(`${API_URL}/getUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({ type: SET_USER, payload: res.data });
      })
      .catch((err) => {
        //dispatch(userFetchFailure());
      });
  };
};

export const updateUser = (inputs) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/updateUser`, inputs, config);
  };
};

export const updateUserImage = (formData) => {
  const token = getToken();
  const formDataConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(
      `${API_URL}/profilePictureUpload`,
      formData,
      formDataConfig
    );
  };
};

export const getUserSubscription = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios.get(`${API_URL}/userSubscription`, config).then((res) => {
      dispatch({
        type: SET_USER_SUBSCRIPTION,
        payload: res.data,
      });
    });
  };
};

export const cancelUserSubscription = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .post(`${API_URL}/cancelSubscription`, { id: id }, config)
      .then((res) => {
        dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: res.data,
        });
      });
  };
};

export const getInvoice = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(`${API_URL}/invoice?invoiceNumber=${id}`, {
      ...config,
      responseType: "blob",
    });
  };
};

export const changeUserNotifications = (id, value) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .put(
        `${API_URL}/notifications`,
        {
          notificationId: id,
          value: value,
        },
        config
      )
      .then((res) => {
        dispatch({
          type: UPDATE_USER_NOTIFICATION,
          payload: res.data,
        });
      });
  };
};
