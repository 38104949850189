import React from "react";
import { Link } from "react-router-dom";
import {
  SheetHeader,
  Back,
  BackWrapper,
  FavouriteWrapper,
  FavouriteTooltip,
  Favourite,
} from "../../PageWithList/Sheet/Styles";
import { isMobile } from "react-device-detect";
const SpotloadSheetHeader = (props) => {
  const { company } = props.spotload;
  return (
    <>
      <SheetHeader size={props.size}>
        {(!props.denyActions || isMobile) && (
          <BackWrapper onClick={() => props.setSheetID(null)}>
            <Back>{back}</Back>
          </BackWrapper>
        )}
        <Link to={`/dashboard/company/${company._id}`}>
          <img src={company.logo} alt={company.companyName} />
          <h3>{company.companyName}</h3>
        </Link>
        <FavouriteWrapper
          onClick={
            props.isFavourited
              ? props.removeFromFavourites
              : props.addToFavourites
          }
        >
          <Favourite isFavourited={props.isFavourited}>{star}</Favourite>
          <FavouriteTooltip>
            <h6>
              {props.isFavourited
                ? "Remove spot freight from favourites"
                : "Add spot freight to favourites"}
            </h6>
          </FavouriteTooltip>
        </FavouriteWrapper>
      </SheetHeader>
    </>
  );
};

const back = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.361"
    height="16.672"
    viewBox="0 0 19.361 16.672"
  >
    <g transform="translate(-25.139 -16.283)">
      <g id="Group_883" data-name="Group 883">
        <path
          d="M20664.182-22224.01l-7.629,7.629,7.629,7.629"
          transform="translate(-20630 22241)"
          fill="none"
          stroke="#aeaeae"
          strokeWidth="2"
        />
        <path
          d="M0,0H17"
          transform="translate(27.5 24.5)"
          fill="none"
          stroke="#aeaeae"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20.02"
    viewBox="0 0 21 20.02"
  >
    <path
      d="M11,1l3.09,6.26L21,8.27l-5,4.87,1.18,6.88L11,16.77,4.82,20.02,6,13.14,1,8.27,7.91,7.26Z"
      transform="translate(-0.5 -0.5)"
      fill="none"
      stroke="#ffbc1a"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </svg>
);

export default SpotloadSheetHeader;
