import axios from "axios";
import { API_URL } from "../../constants";
import { SET_UNREAD } from "../actionTypes";
import { getToken } from "../../helpers/getToken";

export const getUnread = (token) => {
  return (dispatch) => {
    axios
      .get(`${API_URL}/unreadCount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { unread } = res.data;
        dispatch({ type: SET_UNREAD, payload: unread });
      })
      .catch((err) => {});
  };
};

export const sendUserMessage = (
  id,
  title,
  content,
  type,
  post_originate,
  isQuotation
) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios({
      method: "POST",
      url: `${API_URL}/conversations`,
      data: {
        recieverId: id,
        title,
        content,
        type,
        post_originate,
        isQuotation,
      },
      ...config,
    });
  };
};
