import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ListWrapper,
  LinkWrapper,
  PageTitle,
  ListItems,
  ListHeaderItem,
} from "../../PageWithList/List/Styles";
import CreateButton from "../../Dashboard/UI/CreateButton";
import ListHeader from "../../PageWithList/List/ListHeader";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";

const TenderList = (props) => {
  const { USER } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  return (
    <>
      <ListWrapper>
        <PageTitle>
          <h1>All tenders</h1>
          <LinkWrapper>
            {USER && !isExpired ? (
              <CreateButton
                title="Create Tender"
                to="/dashboard/tender/create"
              />
            ) : USER && isExpired ? (
              <CreateButton
                title="Create Tender"
                isButton
                onClick={() => setExpiredModalOpen(true)}
              />
            ) : (
              <CreateButton
                title="Create Tender"
                isButton
                onClick={() => setLoggedModalOpen(true)}
              />
            )}
          </LinkWrapper>
        </PageTitle>
        <ListHeader>
          <ListHeaderItem flex="0.5">Loading</ListHeaderItem>
          <ListHeaderItem flex="0.5">Unloading</ListHeaderItem>
          <ListHeaderItem flex="0.4">Truck type</ListHeaderItem>
          <ListHeaderItem flex="0.2">Price</ListHeaderItem>
          <ListHeaderItem flex="0.2">Deadline</ListHeaderItem>
          <ListHeaderItem flex="0.3">Publisher</ListHeaderItem>
        </ListHeader>
        <ListItems>{props.children}</ListItems>
      </ListWrapper>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
    </>
  );
};

export default TenderList;
