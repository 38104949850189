import React, { useState, useEffect } from "react";
import { SheetItem } from "./Styles";

const DetailsSpecialRequests = (props) => {
  const [specialRequests, setSpecialRequests] = useState([]);

  const { specialRequests: specialRequestEntries } = props;
  useEffect(() => {
    if (specialRequests?.length <= 0 && specialRequestEntries) {
      const arr = [];
      Object.entries(specialRequestEntries).forEach((entry) => {
        const key = entry[0],
          value = entry[1];
        if (key !== "otherReq" && key !== "otherRequirements") {
          if (value) {
            if (key === "euroPalletExchange" || key === "euroPallet") {
              arr.push("Euro Pallet Exchange");
            }
            if (key === "liveTracking") {
              arr.push("Live tracking");
            }
            if (key === "nonStackable") {
              arr.push("Non stackable");
            }
            if (key === "TIRCable") {
              arr.push("TIR Cable");
            }
            if (key === "wasteLoad") {
              if (!props.renameWaste) {
                arr.push("Waste load");
              } else {
                arr.push("Waste license");
              }
            }
            if (key === "aShield") {
              arr.push("A-shield");
            }
            if (key === "ADR") {
              arr.push(key);
            }
          }
        }
      });
      setSpecialRequests(arr);
    }
  }, [specialRequestEntries]);

  if (specialRequests?.length <= 0) return null;
  return (
    <SheetItem>
      <h3>{props.title || "Special requests"}</h3>
      <h4>{specialRequests?.map((el) => el).join(", ")}</h4>
    </SheetItem>
  );
};

export default DetailsSpecialRequests;
