import styled, { css } from "styled-components";

export const Static = styled.div`
  padding: 7rem 0;
  h1 {
    font-size: 22px;
    color: #000000;
    line-height: 1.4;
  }
  h5 {
    color: #7a7a7a;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1rem;
  }
  @media (min-width: 992px) {
    padding-left: 1rem;
    padding-right: 1rem;
    h1 {
      font-size: 28px;
      max-width: 75%;
    }
    h5 {
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 1200px) {
    padding-top: 4rem;
    padding-bottom: 10rem;
    h1 {
      font-size: 36px;
      max-width: 60%;
    }
  }
`;
export const Background = styled.div`
  background: ${({ background }) => background && background};
  padding: ${({ padding }) => padding && padding};
`;
export const AboutList = styled.div`
  h2 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
  }
`;
export const AboutListContent = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  p {
    color: #000000;
    line-height: 1.5;
    margin: 1rem 0;
    max-width: 630px;
  }
  @media (min-width: 992px) {
    p {
      font-size: 16px;
      margin: 0;
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 1200px) {
    p {
      margin-bottom: 3rem;
    }
  }
`;
export const AboutListItem = styled.div`
  margin: 1.5rem 0;
  &:nth-child(2),
  &:nth-child(3) {
    ${AboutListContent} {
      border-bottom: 1px solid #707070;
    }
  }
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }
`;
export const AboutListTitle = styled.div`
  padding: 1rem 2rem;
  background: #ffffff;
  font-size: 18px;
  color: #ef4900;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0 3px 15px rgba(102, 156, 210, 0.19);
  width: max-content;
  height: min-content;
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  @media (min-width: 992px) {
    width: 212px;
    min-width: 212px;
    max-width: 212px;
    margin-right: 2rem;
    font-size: 20px;
    margin-left: 0;
  }
  @media (min-width: 1200px) {
    margin-right: 4rem;
    width: 241px;
    min-width: 241px;
    max-width: 241px;
    font-size: 24px;
  }
  @media (min-width: 1440px) {
    margin-right: 5rem;
  }
`;
export const CTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  h4 {
    font-size: 1rem;
    color: #262a68;
    text-align: center;
    font-weight: 400;
    line-height: 1.4;
  }
  h3 {
    color: #363636;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 992px) {
    h4 {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 3rem;
    }
  }
  @media (min-width: 1200px) {
    h4 {
      font-size: 28px;
    }
    h3 {
      font-size: 20px;
    }
  }
`;

export const FAQList = styled.section``;
export const FAQInfo = styled.div`
  max-height: 0;
  overflow: hidden;
  p {
    margin: 0;
    line-height: 1.6;
    font-size: 14px;
    color: #000000;
    margin: 1rem 2rem;
  }
  a {
    color: #ef4900;
  }
  @media (min-width: 992px) {
    p {
      max-width: 80%;
      line-height: 1.7;
      margin: 2rem;
      margin-top: 1rem;
    }
  }
`;
export const FAQItem = styled.div`
  background: #ffffff;
  box-shadow: 0 3px 12px rgba(159, 184, 220, 0.16);
  border-radius: 8px;
  margin-bottom: 2rem;
  ${({ active }) =>
    active &&
    css`
      ${FAQInfo} {
        max-height: 9999px;
      }
    `}
`;
export const FAQButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  font-family: "Inter";
  font-weight: 700;
  color: #262a68;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
  padding: 2rem 1.5rem;
`;

export const TermsList = styled.section`
  h3 {
    color: #262a68;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  ul {
    margin: 1.5rem 0;
    padding: 0;
    li {
      padding: 0.5rem 0;
      list-style-type: none;
      line-height: 1.5;
      color: #000000;
      font-size: 14px;
    }
  }
  a,
  p {
    color: #000000;
  }
  p {
    line-height: 1.5;
    font-size: 14px;
  }
  @media (min-width: 992px) {
    h3 {
      font-size: 24px;
    }
    ul {
      li {
        line-height: 1.6;
      }
    }
    p {
      line-height: 1.6;
    }
  }
`;

export const PrivacyList = styled.section`
  h3 {
    margin: 2rem 0;
    color: #262a68;
    font-size: 18px;
  }
  h4 {
    margin: 1.5rem 0;
    font-size: 16px;
    color: #262a68;
  }
  p {
    line-height: 1.6;
    font-size: 14px;
  }
  ul {
    margin: 1rem 0;
    padding: 0;
    li {
      font-size: 14px;
      list-style-type: none;
      padding: 0.5rem 0;
    }
  }
`;

export const FreeTrialList = styled.div`
  max-width: 400px;
  margin-bottom: 3rem;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(147, 150, 170, 0.18);
      &:first-child {
        color: #686a7d;
      }
    }
  }
`;
export const FreeTrialContent = styled.div`
  margin-bottom: 2.5rem;
  a {
    color: #000;
  }
  p {
    line-height: 1.5;
    margin: 1.5rem 0;
  }
`;
