import React from "react";
import AsyncLocationInput from "../CreateForm/AsyncLocationInput";
import DateSelect from "../CreateForm/DateSelect";
import TrucktypeSelect from "../CreateForm/TrucktypeSelect";
import { Wrapper } from "../CreateForm/Styles";

const EmptyTruckCreatorStepOne = (props) => {
  return (
    <Wrapper>
      <TrucktypeSelect
        onSelectChange={props.onSelectChange}
        onInputChange={props.onInputChange}
        inputs={props.inputs}
        noMulti
        noTemp
        errors={props.errors}
        label="Truck type"
        name="truckType"
      />
      {/* TODO: not more than 1 week */}
      <DateSelect
        label="Truck available"
        type="loadingDate"
        startDatePlaceholderText="From"
        endDatePlaceholderText="Until"
        onInputChange={props.onInputChange}
        error={props.errors?.loadingDate}
        inputs={props.inputs}
      />
      <AsyncLocationInput
        label="From"
        type="loading"
        onSelectChange={props.onSelectChange}
        handleInputChange={props.onInputChange}
        handleInputDelete={props.handleInputDelete}
        errors={props.errors}
        inputs={props.inputs}
        maxInputCount={1}
        showRadius
      />
      <div style={{ marginTop: "1rem" }}>
        <AsyncLocationInput
          label="To"
          type="unloading"
          endpoint="/filterlocations"
          onSelectChange={props.onSelectChange}
          handleInputChange={props.onInputChange}
          handleInputDelete={props.handleInputDelete}
          errors={props.errors}
          inputs={props.inputs}
          maxInputCount={10}
          showRadius
        />
      </div>
    </Wrapper>
  );
};

export default EmptyTruckCreatorStepOne;
