import React from "react";
import IntroImage from "../../../images/hero.png";
import Container from "../../UI/Container";
import LinkButton from "../../Buttons/NewButton/LinkButton";

import { Content, TextContent, HeroImage } from "./Styles";

const Hero = ({ USER }) => {
  return (
    <Container gutter="medium">
      <Content>
        <TextContent>
          <h1>
            Platform designed to connect
            <span> hauliers</span>, <span>spedition companies </span>
            and <span>cargo owners.</span>{" "}
          </h1>
          <p>
            You can publish your Round-trips, Tenders and Spot freights. <br />
            Get an overview of current prices on the market and contact the
            hauliers from all around the Europe.
          </p>
          {!USER && (
            <LinkButton to="/sign-up" centered="mobile">
              Join now
            </LinkButton>
          )}
        </TextContent>
        <HeroImage>
          <img src={IntroImage} alt="" />
        </HeroImage>
      </Content>
    </Container>
  );
};

export default Hero;
