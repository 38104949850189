import React from "react";
import "../../../styles/Dashboard/MyAccount/SubMenu.css";

const SubMenu = props => {
  return (
    <div className="Sub_Menu">
      <div className="Sub_Menu--inner">{props.children}</div>
    </div>
  );
};

export default SubMenu;

