import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Filter from "./Filter/Filter";
import TenderList from "./TenderList/TenderList";
import TenderSheet from "./Sheet/TenderSheet";
import Modal from "../Modals/Modal";
import ModalHeader from "../Modals/Header";
import Container from "../UI/Container";
import DashboardFooter from "../Dashboard/DashboardFooter";

const Tender = (props) => {
  const USER = useSelector((state) => state.dashboard.user?.data);
  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [allTenders, setAllTenders] = useState([]);
  const [filteredTenderCount, setFilteredTenderCount] = useState(null);
  const [showRouteModal, setShowRouteModal] = useState(false);

  // If user has requested a tender via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    if (routeSheet && allTenders?.length > 0) {
      if (!allTenders.find((t) => t._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allTenders]);

  const handleModalClose = () => {
    setShowRouteModal(false);
    setSheetID(null);
  };

  const handleSheetChange = useCallback((id) => {
    setSheetID(id);
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener("scroll", function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener("scroll", scrollEvt);
    }
  }, []);

  return (
    <>
      <Filter
        fixed={filterFixed}
        filters={filters}
        setFilters={setFilters}
        tenderCount={filteredTenderCount}
      />
      <Container>
        <TenderList
          filters={filters}
          sheetID={sheetID}
          setSheetID={handleSheetChange}
          setAllTenders={setAllTenders}
          setTenderCount={setFilteredTenderCount}
          clearFilters={() => setFilters({})}
          USER={USER}
        />
        {allTenders?.length > 0 && <DashboardFooter />}
      </Container>
      {sheetID && (
        <TenderSheet
          tenders={allTenders}
          id={sheetID}
          setSheetID={handleSheetChange}
          USER={USER}
        />
      )}
      {showRouteModal && (
        <Modal onClose={handleModalClose} small>
          <ModalHeader title="Tender not found" onClose={handleModalClose} />
          <h4>The tender you requested was not found.</h4>
          <h5>Here are some reasons to why:</h5>
          <ul>
            <li>
              The tender you requested was removed or is currently inactive.
            </li>
            <li>The link you accessed was broken or missing.</li>
          </ul>
        </Modal>
      )}
    </>
  );
};

export default withRouter(Tender);
