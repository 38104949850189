import React from "react";
import LanguageSelect from "../../CreateForm/LanguageSelect.js";
import CheckboxGroup from "../../CreateForm/CheckboxGroup";
import Checkbox from "../../CreateForm/Checkbox";
import { Wrapper } from "../../CreateForm/Styles";
import StandardQuill from "../../UI/StandardQuill/";

const RoundtripCreatorStepThree = (props) => {
  return (
    <Wrapper>
      <CheckboxGroup
        label="Requirements for the carrier"
        error={props.errors?.requirements}
      >
        <Checkbox
          label="ADR"
          value="ADR"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="Euro pallet exchange"
          value="euroPallet"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="TIR Cable"
          value="TIRCable"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="Live tracking"
          value="liveTracking"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="A-shield"
          value="aShield"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
      </CheckboxGroup>
      <StandardQuill
        label="Other requirements"
        name="otherRequirements"
        placeholder="Other requirements for carrier"
        value={props.inputs?.requirements?.otherRequirements}
        onInputChange={(value, name) =>
          props.onInputChange(value, name, "requirements")
        }
        error={props.errors?.otherRequirements}
      />
      <LanguageSelect
        label="Languages of communication"
        name="languages"
        placeholder="Select languages"
        isMulti
        onSelectChange={props.onSelectChange}
        onInputChange={props.onInputChange}
        inputs={props.inputs}
        error={props.errors?.languages}
      />
      <StandardQuill
        label="Additional information"
        name="additionalInformation"
        placeholder="Enter any additional information about roundtrip"
        value={props.inputs?.additionalInformation}
        onInputChange={props.onInputChange}
        error={props.errors?.additionalInformation}
      />
    </Wrapper>
  );
};

export default RoundtripCreatorStepThree;
