import React, { useState } from "react";
import axios from "axios";

import Modal from "../Modal";
import Header from "../Header";
import StandardQuill from "../../UI/StandardQuill";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  ErrorContainer,
  ErrorWrapper,
} from "../../CreateForm/Styles";
import { ReviewButtons, ReviewButton, LargeButtonSpacing } from "./Styles";
import Footer from "../Footer";
import LargeButton from "../../CreateForm/LargeButton";
import AddReviewSent from "./AddReviewSent";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

const AddReview = (props) => {
  const [inputs, setInputs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (value, name) => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
    if (errors?.[name]) {
      setErrors((prevState) => ({ ...prevState, [name]: null }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "POST",
      url: `${API_URL}/review`,
      data: { ...inputs, id: props.id },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        const { success } = res.data;
        if (success) {
          setSuccess(true);
          if (props.addReview && res.data?.review.review === 1) {
            props.addReview(res.data?.review);
          }
        }
      })
      .catch((err) => {
        const { errors } = err.response.data;
        setErrors(errors);
      });
  };

  if (success)
    return <AddReviewSent onClose={props.onClose} review={inputs?.review} />;

  return (
    <Modal onClose={props.onClose} medium>
      <Header
        noBorder
        title={`Add review ${props.name ? "for " + props.name : ""}`}
        onClose={props.onClose}
      />
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <LabelWrapper>
            <Label>
              <h3>Your experience</h3>
            </Label>
            <ErrorContainer>
              <ReviewButtons>
                <ReviewButton
                  active={inputs?.review === 1 ? 1 : null}
                  type="button"
                  onClick={() => {
                    handleInputChange(1, "review");
                    setErrors((prevState) => ({ ...prevState, review: null }));
                  }}
                  mr="1rem"
                >
                  {thumbsUp}
                </ReviewButton>
                <ReviewButton
                  active={inputs?.review === 0 ? 1 : null}
                  type="button"
                  onClick={() => {
                    handleInputChange(0, "review");
                    setErrors((prevState) => ({ ...prevState, review: null }));
                  }}
                >
                  {thumbsDown}
                </ReviewButton>
              </ReviewButtons>
              {errors?.review && (
                <ErrorWrapper>
                  <span>{errors?.review.msg}</span>
                </ErrorWrapper>
              )}
            </ErrorContainer>
          </LabelWrapper>
        </InputWrapper>
        <StandardQuill
          label="Comment"
          name="comment"
          placeholder="Enter your comment"
          value={inputs?.comment}
          onInputChange={handleInputChange}
          error={errors?.comment}
        />
        <Footer>
          <LargeButtonSpacing>
            <LargeButton type="submit" label="Submit" />
          </LargeButtonSpacing>
        </Footer>
      </form>
    </Modal>
  );
};

const thumbsUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.893"
    height="44.893"
    viewBox="0 0 44.893 44.893"
  >
    <path
      d="M9.119,19.641H2.1a2.1,2.1,0,0,0-2.1,2.1V42.789a2.1,2.1,0,0,0,2.1,2.1H9.119a2.1,2.1,0,0,0,2.1-2.1V21.745A2.1,2.1,0,0,0,9.119,19.641ZM5.612,41.386a2.1,2.1,0,1,1,2.1-2.1A2.1,2.1,0,0,1,5.612,41.386ZM33.67,7.142c0,3.719-2.277,5.805-2.918,8.29h8.919a5.239,5.239,0,0,1,5.222,5.094,6.348,6.348,0,0,1-1.7,4.314l-.01.01a7.324,7.324,0,0,1-.816,6.968,6.934,6.934,0,0,1-1.436,6.555,4.655,4.655,0,0,1-.539,3.913c-1.79,2.571-6.225,2.607-9.976,2.607h-.249c-4.234,0-7.7-1.543-10.483-2.782a13.8,13.8,0,0,0-4.617-1.419,1.052,1.052,0,0,1-1.033-1.052V20.9a1.051,1.051,0,0,1,.312-.747c3.473-3.432,4.967-7.066,7.814-9.918,1.3-1.3,1.77-3.265,2.227-5.165C24.772,3.445,25.587,0,27.357,0,29.461,0,33.67.7,33.67,7.142Z"
      fill="#3fbc61"
    />
  </svg>
);

const thumbsDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.893"
    height="44.893"
    viewBox="0 0 44.893 44.893"
  >
    <path
      d="M9.119,25.252H2.1a2.1,2.1,0,0,1-2.1-2.1V2.1A2.1,2.1,0,0,1,2.1,0H9.119a2.1,2.1,0,0,1,2.1,2.1V23.148A2.1,2.1,0,0,1,9.119,25.252ZM5.612,3.507a2.1,2.1,0,1,0,2.1,2.1A2.1,2.1,0,0,0,5.612,3.507ZM33.67,37.751c0-3.719-2.277-5.805-2.918-8.29h8.919a5.239,5.239,0,0,0,5.222-5.094,6.348,6.348,0,0,0-1.7-4.314l-.01-.01a7.324,7.324,0,0,0-.816-6.968,6.933,6.933,0,0,0-1.436-6.555,4.655,4.655,0,0,0-.539-3.913C38.6.036,34.162,0,30.412,0h-.249c-4.234,0-7.7,1.543-10.483,2.782A13.8,13.8,0,0,1,15.062,4.2a1.052,1.052,0,0,0-1.033,1.052V24a1.051,1.051,0,0,0,.312.747c3.473,3.432,4.967,7.066,7.814,9.918,1.3,1.3,1.77,3.265,2.227,5.165.39,1.622,1.206,5.067,2.975,5.067C29.461,44.893,33.67,44.191,33.67,37.751Z"
      fill="#ca2e2e"
    />
  </svg>
);

export default AddReview;
