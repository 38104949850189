import styled, { css } from "styled-components";

export const ScreenWrapperInner = styled.div`
  padding: 0 1rem;
  margin-top: calc(64px + 1.5rem);
  margin-bottom: 6rem;
  @media (min-width: 992px) {
    margin-top: 1rem;
    max-width: 1030px;
    margin-left: 84px;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 1111px;
    margin-left: 270px;
  }
  @media (min-width: 1440px) {
    max-width: 1240px;
  }
  @media (min-width: 1600px) {
    margin-left: 320px;
  }
  @media (min-width: 1740px) {
    max-width: 1420px;
  }
`;

export const ScreenWrapper = styled.div`
  width: 100%;
  ${({ small }) =>
    small &&
    css`
      ${ScreenWrapperInner} {
        @media (min-width: 1200px) {
          margin-left: 84px;
        }
        @media (min-width: 1600px) {
          margin-left: 84px;
        }
      }
    `}
  ${({ centered }) =>
    centered &&
    css`
      ${ScreenWrapperInner} {
        @media (min-width: 1200px) {
          margin-left: auto;
        }
        @media (min-width: 1600px) {
          margin-left: auto;
        }
      }
    `}
`;

export const ScreenBlockWrapper = styled.div`
  @media (min-width: 992px) {
    padding: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
  }
  @media (min-width: 1200px) {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
    border: 1px solid #eeeeee;
  }
`;

export const ScreenBlockTitle = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 0.5rem;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  h2 {
    color: #262a68;
    margin: 0;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    img {
      margin-right: 1rem;
    }
  }
  @media (min-width: 992px) {
    border: none;
    h2 {
      font-size: 1.75rem;
    }
  }
`;
