import React from "react";
import Select from "react-select";
import { selectStyles } from "./Styles";
import trucktypesJSON from "../../../data/trucktypes.json";
const truckTypes = trucktypesJSON.types;

const TrucktypeFilter = (props) => {
  return (
    <Select
      placeholder={props.placeholder}
      options={truckTypes}
      isMulti
      closeMenuOnSelect={false}
      isClearable={false}
      styles={selectStyles}
      name={props.type}
      value={props.filters?.[props.type] || null}
      onChange={(e) => props.onSelectChange(e, props.type)}
    />
  );
};

export default TrucktypeFilter;
