import React from "react";
import styled, { css } from "styled-components";
import CreateButton from "../UI/CreateButton";
import { Link } from "react-router-dom";

const Filter = (props) => {
  return (
    <Wrapper>
      <ButtonWrapper>
        <h4>Filters</h4>
        <Button
          active={props.filter === 0}
          onClick={() => (props.filter !== 0 ? props.setFilter(0) : null)}
        >
          All {props.all ? `(${props.all})` : ""}
        </Button>
        {props.active > 0 && (
          <Button
            active={props.filter === 1}
            onClick={() => (props.filter !== 1 ? props.setFilter(1) : null)}
          >
            Active {props.active ? `(${props.active})` : ""}
          </Button>
        )}
        {props.inActive > 0 && (
          <Button
            active={props.filter === 2}
            onClick={() => (props.filter !== 2 ? props.setFilter(2) : null)}
          >
            Inactive {props.inActive ? `(${props.inActive})` : ""}
          </Button>
        )}
      </ButtonWrapper>
      <LinkWrapper>
        {props.to && !props.isExpired && (
          <Link to={props.to}>
            Add {props.type} {add}
          </Link>
        )}
        {props.redirect && !props.isExpired && (
          <a href={props.redirect}>
            Add {props.type} {add}
          </a>
        )}
        {props.isExpired && (
          <CreateButton
            title={`Add ${props.type}`}
            isButton
            onClick={() => props.setExpiredModal(true)}
          />
        )}
      </LinkWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 578px) {
    width: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  @media (min-width: 1600px) {
    padding: 0 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
  > h4 {
    color: #686a7d;
    font-family: "Inter";
    font-weight: 500;
    font-size: 13px;
    margin: 0;
    margin-right: 0.5rem;
  }
  @media (max-width: 375px) {
    h4 {
      display: none;
    }
  }
  @media (min-width: 578px) {
    margin: 0;
    > h4 {
      margin-right: 1.5rem;
    }
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid #262a68;
  border-radius: 4px;
  font-family: "Inter";
  color: #262a68;
  cursor: pointer;
  margin-right: 0.5rem;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background: #00b27e;
      border: 1px solid #00b27e;
      cursor: default;
    `}
  @media (min-width: 992px) {
    margin-right: 1rem;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  > a {
    color: #fff;
    background: #4785ff;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    font-family: "Inter";
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    > svg {
      margin-left: 0.5rem;
    }
  }
  @media (min-width: 578px) {
    width: auto;
    > a {
      width: max-content;
      white-space: nowrap;
    }
  }
`;

const add = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="29"
    viewBox="0 0 32 29"
  >
    <g transform="translate(-9473 19430)">
      <rect
        width="32"
        height="29"
        rx="4"
        transform="translate(9473 -19430)"
        fill="#97baff"
      />
      <path
        d="M-1.051-1.016h2.1V-5.25H5.285v-2.1H1.051v-4.227h-2.1v4.227H-5.277v2.1h4.227Z"
        transform="translate(9489 -19409)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Filter;
