import React, { useState, useEffect } from "react";
import {
  SHOW_MESSAGE_WINDOW,
  SET_CURRENT_MESSAGE_WINDOW,
} from "../../../store/actionTypes";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { isBrowser, isMobile } from "react-device-detect";
import axios from "axios";

import iInfo from "../../../assets/icons/info-btn.svg";
import iBack from "../../../assets/icons/back.svg";
import Loader from "../UI/Loader";
import TenderSheet from "../../NewTender/Sheet/TenderSheet";
import SpotloadSheet from "../../Spotload/SpotloadSheet/SpotloadSheet";
import RoundtripSheet from "../../Roundtrip/RoundtripSheet/RoundtripSheet";
import MessageBox from "./MessageBox";
import { API_URL } from "../../../constants";

const MessageWindow = (props) => {
  const [currentConversation, setCurrentConversation] = useState([]);
  const USER = useSelector((state) => state.dashboard?.user?.data);

  // Handle showing modal information
  const [tenderModal, setTenderModal] = useState();
  const [roundtripModal, setRoundtripModal] = useState();
  const [spotloadModal, setSpotloadModal] = useState();
  const [requestedTenderModal, setRequestedTenderModal] = useState(false);
  const [requestedRoundtripModal, setRequestedRoundtripModal] = useState(false);
  const [requestedSpotloadModal, setRequestedSpotloadModal] = useState(false);

  // Get current conversation on window load
  useEffect(() => {
    if (props.MESSAGE_DATA.currentMessageWindow) {
      const cookies = new Cookies(),
        token = cookies.get("tc_token");
      axios({
        method: "GET",
        url: `${API_URL}/messages`,
        params: {
          id: props.MESSAGE_DATA.currentMessageWindow,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setCurrentConversation(res.data);
        setTenderModal();
        setSpotloadModal();
        setRoundtripModal();
        setRequestedTenderModal(false);
        setRequestedRoundtripModal(false);
        setRequestedSpotloadModal(false);
        // SET RIGHT DRAWER MODAL
        if (res.data?.offer?.type === "Tender") {
          axios
            .get(`${API_URL}/getTenderByID?id=${res.data.offer.id}`)
            .then((res) => {
              setTenderModal(res.data);
              if (isBrowser) {
                setRequestedTenderModal(true);
              }
            });
        } else if (res.data?.offer?.type === "Roundtrip") {
          axios
            .get(`${API_URL}/roundtrip/${res.data.offer.id}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              setRoundtripModal(res.data.roundtrip);
              if (isBrowser) {
                setRequestedRoundtripModal(true);
              }
            });
        } else if (res.data?.offer?.type === "Spotload") {
          axios
            .get(`${API_URL}/spotLoads/${res.data.offer.id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setSpotloadModal(res.data);
              if (isBrowser) {
                setRequestedSpotloadModal(true);
              }
            });
        }
      });
    }
  }, [props.MESSAGE_DATA.currentMessageWindow]);

  const [newMessage, setNewMessage] = useState();
  // Handle updating the conversation via child props
  const handleConversationUpdate = (newMessage) => {
    setNewMessage(newMessage);
  };
  useEffect(() => {
    if (currentConversation?.messages) {
      setCurrentConversation({
        ...currentConversation,
        messages: [...currentConversation.messages, newMessage],
      });
    }
  }, [newMessage]);

  // Handle closing the current message window
  const closeMessageWindowHandler = () => {
    props.onShowMessageWindow(false);
    props.onClearCurrentMessageWindow("");
    setRequestedRoundtripModal(false);
    setRequestedTenderModal(false);
    setRequestedSpotloadModal(false);
  };

  // If is desktop run showmodalhandler

  useEffect(() => {
    if (currentConversation && isBrowser) {
      return showModalHandler();
    }
  }, []);

  // Handle sheet change
  const handleSheetChange = () => {
    if (isMobile) {
      if (requestedTenderModal) {
        setRequestedTenderModal(false);
      } else {
        setRequestedTenderModal(true);
      }
      if (requestedRoundtripModal) {
        setRequestedRoundtripModal(false);
      } else {
        setRequestedRoundtripModal(true);
      }
      if (requestedSpotloadModal) {
        setRequestedSpotloadModal(false);
      } else {
        setRequestedSpotloadModal(true);
      }
    }
  };

  const showModalHandler = () => {
    setRequestedTenderModal(true);
    setRequestedRoundtripModal(true);
    setRequestedSpotloadModal(false);
  };

  return (
    <>
      <div className="Current_Message_Window">
        <div className="Current_Message_Window--inner">
          <div className="Current_Message_Window--header">
            {currentConversation?.reciever ? (
              <>
                <div className="Back" onClick={closeMessageWindowHandler}>
                  <img src={iBack} alt="Back" />
                </div>
                <div className="Messager-image">
                  <img
                    src={currentConversation?.reciever?.recieverAvatar}
                    alt=""
                  />
                </div>
                <div className="Messager-info">
                  <h2>{currentConversation.offer.title}</h2>
                  <div className="Messager-sub">
                    <h4>{currentConversation.reciever.recieverFullName}</h4>
                    <Link
                      to={`/dashboard/company/${currentConversation.reciever.recieverCompanyId}`}
                      target="_blank"
                    >
                      {currentConversation.reciever.recieverCompanyName}
                    </Link>
                  </div>
                </div>
                {(tenderModal || roundtripModal) && (
                  <div className="Buttons">
                    <img src={iInfo} alt="Info" onClick={showModalHandler} />
                  </div>
                )}
              </>
            ) : (
              !currentConversation && <Loader />
            )}
          </div>
          <MessageBox
            conversation={currentConversation}
            updateConversation={handleConversationUpdate}
          />
        </div>
      </div>
      {tenderModal && requestedTenderModal && (
        <TenderSheet
          USER={USER}
          tender={tenderModal}
          denyActions
          size="small"
          id={tenderModal._id}
          setSheetID={handleSheetChange}
        />
      )}
      {roundtripModal && requestedRoundtripModal && (
        <RoundtripSheet
          USER={USER}
          roundtrip={roundtripModal}
          denyActions
          size="small"
          id={roundtripModal._id}
          setSheetID={handleSheetChange}
        />
      )}
      {spotloadModal && requestedSpotloadModal && (
        <SpotloadSheet
          USER={USER}
          spotload={spotloadModal}
          denyActions
          size="small"
          id={spotloadModal._id}
          setSheetID={handleSheetChange}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MESSAGE_DATA: state.dashboard.message,
    USER_DATA: state.dashboard.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessageWindow: (payload) =>
      dispatch({ type: SHOW_MESSAGE_WINDOW, payload: payload }),
    onClearCurrentMessageWindow: (payload) =>
      dispatch({ type: SET_CURRENT_MESSAGE_WINDOW, payload: payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageWindow);
