import React from "react";
import Select from "react-select";
import { selectStyles } from "./Styles";
import specialFeatures from "../../../data/specialFeatures.json";
const options = specialFeatures.specialFeatures;

const TrucktypeFilter = (props) => {
  return (
    <Select
      placeholder={props.placeholder}
      options={options}
      isMulti
      closeMenuOnSelect={false}
      isClearable={false}
      styles={selectStyles}
      name={props.type}
      value={props.filters?.[props.type] || null}
      onChange={(e) => props.onSelectChange(e, props.type)}
    />
  );
};

export default TrucktypeFilter;
