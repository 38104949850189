import React from "react";
import Select from "react-select";
import {
  selectStyles,
  InputWrapper,
  LabelWrapper,
  Label,
  ErrorContainer,
  ErrorWrapper,
} from "./Styles";
import allCountries from "../../data/allCountries.json";
const countries = allCountries.countries;

const CountrySelect = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Select
            placeholder={props.placeholder}
            options={countries}
            isMulti
            closeMenuOnSelect={false}
            isClearable={false}
            styles={selectStyles}
            name={props.type}
            classNamePrefix="styled"
            value={props.inputs?.[props.type] || null}
            onChange={(e) => props.onSelectChange(e, props.type)}
            className={props.error && "invalid"}
          />
          {props.error && (
            <ErrorWrapper>
              <span>{props.error.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

export default CountrySelect;
