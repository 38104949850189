export const FormatSpotloadEditData = (data) => {
  const {
    loading,
    unloading,
    truckType,
    temperature,
    freight,
    specialRequests,
    extraInfo,
    targetPrice,
  } = data;
  const loadingLocations = {};
  const unloadingLocations = {};
  loading.locations.forEach((l, i) => {
    loadingLocations[`loading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
  });
  unloading.locations.forEach((l, i) => {
    unloadingLocations[`unloading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
  });
  return {
    ...loadingLocations,
    ...unloadingLocations,
    loadingDate: {
      startDate: loading.date.start,
      endDate: loading.date.end,
    },
    unloadingDate: {
      startDate: unloading.date.start,
      endDate: unloading.date.end,
    },
    truckType,
    temperature,
    typeOfLoad: freight.typeOfLoad,
    freight: {
      weight: freight?.weight,
      loadingMeters: freight?.loadingMeters,
      height: freight?.height,
      length: freight?.length,
      width: freight?.width,
    },
    specialRequests,
    cargoType: freight.typeOfCargo,
    extraInfo,
    targetPrice,
  };
};
