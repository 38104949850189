import { combineReducers } from "redux";
import user from "./reducers/dashboard/user";
import company from "./reducers/dashboard/company";
import offers from "./reducers/dashboard/offers";
import quotations from "./reducers/dashboard/quotations";
import spotload from "./reducers/app/spotloads";
import tender from "./reducers/app/tenders";
import emptytrucks from "./reducers/app/emptytrucks";
import roundtrip from "./reducers/app/roundtrips";
import message from "./reducers/dashboard/messages";
import misc from "./reducers/app/misc";

const rootReducer = combineReducers({
  dashboard: combineReducers({
    company,
    user,
    message,
    offers,
    quotations,
  }),
  app: combineReducers({
    spotload,
    tender,
    roundtrip,
    emptytrucks,
    misc,
  }),
});

export default rootReducer;
