import styled, { css } from "styled-components";

export const ListHeaderWrapper = styled.div`
  overflow: hidden;
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const ListHeaderItems = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #e0e6ed;
`;
export const ListHeaderItem = styled.div`
  font-family: "Inter";
  font-weight: 400;
  color: #686a7d;
  font-size: 16px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 7.5px;
  }
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.isSortable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const ListWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 3rem;
  @media (min-width: 1200px) {
    margin-top: calc(176.2px + 53.4px);
    flex-direction: column;
    margin-bottom: 5rem;
  }
`;

export const ListItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  @media (min-width: 1200px) {
    border: none;
    margin: 0;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const PageTitle = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 1rem;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: 578px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  h1 {
    margin: 0;
    margin-bottom: 1rem;
    white-space: nowrap;
    font-size: 27px;
    color: #262a68;
    position: relative;
    @media (min-width: 578px) {
      margin-bottom: 0;
    }
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  @media (min-width: 650px) {
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    border-bottom: 1px solid #f1f1f1;
    max-width: 100%;
    display: inline-flex;
    flex-direction: row;
    padding: 0;
    &:nth-child(even) {
      background: #fafbfc;
    }
    &:hover {
      cursor: pointer;
      background: #f0f3f6;
    }
  }
  ${(props) =>
    props.viewed &&
    css`
      background: #fafafa !important;
      h1,
      h2,
      h3,
      h4,
      h5,
      span,
      strong {
        font-weight: 400 !important;
      }
      @media (min-width: 1200px) {
        background: #f9f9f9 !important;
      }
    `}
`;
export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 1rem 0;
  overflow: hidden;
  h3 {
    margin: 0;
    color: #686a7d;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
  h4 {
    margin: 0;
  }
  ${({ allowWrap }) => allowWrap && `h4 {white-space: normal !important; }`}
  @media (min-width: 1200px) {
    margin: 0;
    h3,
    h4 {
      margin: 0;
    }
    h3 {
      display: none;
    }
  }
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.onlyLargeDesktop &&
    css`
      @media (max-width: 1440px) {
        display: none;
      }
    `}
    ${({ blur }) =>
    blur &&
    css`
      filter: blur(3px);
    `}
`;

export const CargoType = styled.div`
  max-width: max-content;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  span {
    color: #fff !important;
    font-size: 12px;
    padding: 0.3rem 0.75rem;
    border-radius: 14px;
  }
  ${(props) =>
    props.FTL &&
    css`
      span {
        background: #6398e7;
      }
    `}
  ${(props) =>
    props.LTL &&
    css`
      span {
        background: #4da19c;
      }
    `}
    @media (min-width: 1200px) {
    margin-top: 0;
    span {
      background: none;
      color: #050720 !important;
      font-weight: 400;
      padding: 0 !important;
      font-size: 14px !important;
    }
  }
`;

export const ItemRowWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  h2,
  h4,
  h5,
  h6 {
    margin: 0;
    color: #050720;
    font-weight: 600;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.25rem;
    margin-left: 30px;
    strong {
      font-weight: 700;
    }
  }
  h6,
  span {
    margin: 0;
    color: #050720;
    font-size: 12px;
    font-weight: 700;
  }
  h6 {
    margin-left: 30px;
    margin-top: 6px;
  }
  h4 span {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    font-size: 12px;
    font-weight: 500;
  }
  @media (min-width: 1200px) {
    h2,
    h4,
    h5,
    h6,
    span {
      white-space: nowrap;
    }
    h4,
    h5 {
      padding-right: 2.5rem;
    }
    > ${CargoType} {
      display: block;
    }
    span {
      padding-right: 0.75rem;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
  ${({ strong }) => strong && `h4 {font-weight: 600 !important;}`}
  ${({ blur }) =>
    blur &&
    css`
      filter: blur(3px);
      padding-left: 4px;
    `}
`;

export const ItemRowInline = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
`;

export const Arrow = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const Item = styled.div`
  > ${ItemRow}:first-child {
    margin-top: 0;
  }
  @media (min-width: 1200px) {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 1rem;
    > ${ItemRowWrapper} {
      display: inline-flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }
    > ${ItemRow} {
      flex-direction: column;
      display: flex;
      &:first-child {
        h4 {
          padding-right: 4.25rem;
          span {
            right: 1.75rem;
          }
        }
      }
    }
    > ${ItemRowInline} {
      display: flex;
      flex: 1;
      > ${ItemRow}:nth-child(1) {
        width: 0;
        margin: 0;
      }
      > ${ItemRow} {
        margin: 0;
        width: 100%;
      }
    }
    ${(props) =>
      props.active &&
      css`
        background: #f1f5f9;
        ${Arrow} {
          svg path {
            stroke: #4f4f4f;
          }
        }
      `}
  }
`;

export const ViewMore = styled.div`
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #262a68;
  font-weight: 500;
  max-width: 150px;
  margin: 1rem auto;
  position: relative;
  border: 1px solid #262a68;
  white-space: nowrap;
  @media (min-width: 1200px) {
    display: none;
  }
`;

export const CompanyRating = styled.div`
  margin-top: 0.5rem;
  display: inline-flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0.35rem 0.5rem;
  font-size: 11px;
  color: #050720;
  max-width: 110px;
  ${({ paddingNone }) =>
    paddingNone &&
    css`
      padding-top: 0;
    `}
  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: 1rem;
    `}
  ${({ posAbs }) =>
    posAbs &&
    css`
      @media (max-width: 420px) {
        position: absolute;
        top: 50px;
        padding-left: 0.35rem;
      }
    `}
`;

export const ThumbsUp = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  svg {
    margin-right: 0.25rem;
  }
  span {
    padding: 0;
    color: #3fbc61;
  }
`;
export const ThumbsDown = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.25rem;
  }
  span {
    padding: 0;
    color: #ca2e2e;
  }
`;
