import styled from "styled-components";

export const Intro = styled.h1`
  text-align: center;
  color: #050720;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  label {
    font-size: 16px;
    color: #050720;
    margin-bottom: 2rem;
  }
`;

export const Save = styled.div`
  position: absolute;
  left: calc(16px + 0.5rem);
  top: 21px;
  font-size: 0.9rem;
  color: #15198f;
  font-weight: 500;
`;

export const PricingCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0 3rem 1rem;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
export const PricingCard = styled.div`
  position: relative;
  margin: 0.5rem;
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #c5cbd2;
  border-radius: 3px;
`;

export const CardHero = styled.div`
  margin-bottom: 2rem;
  h3 {
    margin: 0;
    text-align: center;
  }
  h2 {
    font-size: 2.5rem;
    text-align: center;
    margin: 0.5rem 0;
  }
  p {
    margin: 0;
    text-align: center;
    color: #9a9a9a;
  }
`;

export const CardContent = styled.div`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  border-top: 1px solid #707070;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 0.25rem 0;
      line-height: 1.5;
      svg {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const CardFooter = styled.div`
  margin-top: 1.25rem;
  a {
    background: #00b27e;
    padding: 1rem 3rem;
    border-radius: 2rem;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin: 0 1.25rem;
    &.inactive {
      background: #eaeaea;
      color: #000;
      cursor: default;
    }
  }
`;

export const SaveAmount = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background: #e13505;
  border-radius: 50%;
  font-size: 0.75rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PricingFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
  margin-bottom: 10rem;
  h2 {
    padding: 0 23rem;
    text-align: center;
  }
  a {
    position: relative;
    color: #15198f;
    font-size: 16px;
    font-weight: 500;
  }
`;
