import styled from "styled-components";

export const RightArrow = styled.div`
  padding: 0 0.75rem;
  display: flex;
  @media (min-width: 1200px) {
    padding: 0 1rem;
  }
`;

export const QuotationInnerHeaderWrapper = styled.div``;

export const QuotationInnerHeader = styled.div``;

export const TenderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #fff;
  z-index: 1;
  border-bottom: 1px solid #e0e6ed;
  h4 {
    font-size: 14px;
    font-weight: 400;
    display: none;
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    &:nth-child(7) {
      padding-right: 1rem;
      margin-right: auto;
    }
  }
  img {
    max-width: 20px;
    max-height: 20px;
    margin: 0;
    margin-right: 0.5rem;
  }
  @media (min-width: 1200px) {
    position: relative;
    height: unset;
    justify-content: flex-start;
    border-bottom: unset;
    h4 {
      color: #686a7d;
      display: block;
      padding-left: 0;
    }
    p {
      font-size: 1rem;
      max-width: unset;
    }
    img {
      max-width: 25px;
      max-height: 25px;
      margin: 0rem 0.5rem;
    }
  }
`;

export const HeaderSubInfo = styled.div`
  display: flex;
  div {
    display: flex;
    h4 {
      font-size: 14px;
      color: #686a7d;
      padding-left: 0;
      font-weight: 400;
    }
    h5 {
      font-weight: 600;
    }
  }
  @media (min-width: 1200px) {
    margin-top: 1rem;
    div {
      margin-right: 3rem;
    }
  }
`;

export const QuotationTableHeaderWrapper = styled.div`
  margin-top: 1rem;
  h2 {
    color: #4785ff;
  }
`;

export const QuotationTableHeader = styled.div`
  display: none;
  border-bottom: 1px solid #9aabc2;
  h5 {
    padding-right: 1rem;
    padding-left: 0;
    display: flex;
    align-items: center;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  @media (min-width: 1200px) {
    display: flex;
    h5 {
      &:not(:last-child) {
        width: 110px;
      }
      &:nth-child(3) {
        width: 100px;
      }
      &:nth-child(2) {
        width: 130px;
      }
    }
  }
`;

export const QuotationTableWrapper = styled.div``;

export const RowItem = styled.div`
  display: flex;
  align-items: center;
  p {
    padding-right: 0.5rem;
    max-width: 450px;
    line-height: 1.5;
  }
  h4 {
    padding-left: 0;
  }
  @media (min-width: 1200px) {
    align-items: unset;
    h4 {
      display: none;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e1e1e1;
  @media (min-width: 1200px) {
    flex-direction: row;
    ${RowItem} {
      &:not(:nth-child(5)) {
        width: 110px;
        min-width: 110px;
      }
      &:nth-child(3) {
        width: 100px;
      }
      &:nth-child(2) {
        width: 130px;
        min-width: 130px;
      }
      &:last-child {
        width: unset;
      }
    }
  }
  ${RowItem}:nth-last-child(2) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
