import React from "react";
import Modal from "../Modal";
import Header from "../Header";
import { ModalInnerWrapper, Centered } from "../Styles";
import LargeButton from "../../CreateForm/LargeButton";

const SubscribeGateway = (props) => {
  return (
    <Modal onClose={props.onClose} small>
      <Header onClose={props.onClose} />
      <ModalInnerWrapper>
        <Centered>
          {icon}
          <h3>Your account has expired, please upgrade your membership</h3>
          <LargeButton to="/pricing" label="Continue" secondary centered />
        </Centered>
      </ModalInnerWrapper>
    </Modal>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="250"
    height="250"
    viewBox="0 0 250 250"
  >
    <g transform="translate(-11130 20451)">
      <circle
        cx="125"
        cy="125"
        r="125"
        transform="translate(11130 -20451)"
        fill="#fbfbfb"
      />
      <g transform="translate(10362.582 -20926.752)">
        <path
          d="M874.317,582.628a29.782,29.782,0,0,1-27.336-41.612,29.77,29.77,0,1,0,49.4,31.789A29.678,29.678,0,0,1,874.317,582.628Z"
          transform="translate(81.595 44.631)"
          fill="#e2e5ec"
        />
        <path
          d="M797.321,551.167c0,5.137,2.916,14.234,6.51,14.234h99.477s-4.275-9.1-4.275-14.234"
          transform="translate(13.271 61.169)"
          fill="#e2e5ec"
        />
        <path
          d="M898.078,571.053H813.716a2.629,2.629,0,0,1,0-5.258h84.362a2.629,2.629,0,0,1,0,5.258Z"
          transform="translate(35.698 85)"
          fill="#2f4265"
        />
        <path
          d="M833.987,571.053H791.8a2.629,2.629,0,0,1,0-5.258h42.182a2.629,2.629,0,0,1,0,5.258Z"
          transform="translate(0 85)"
          fill="#2f4265"
        />
        <path
          d="M894.809,571.053H852.626a2.629,2.629,0,0,1,0-5.258h42.182a2.629,2.629,0,1,1,0,5.258Z"
          transform="translate(99.089 85)"
          fill="#2f4265"
        />
        <path
          d="M903.6,616.668H808.252a11.946,11.946,0,0,1-11.931-11.931v-71.49a11.948,11.948,0,0,1,11.931-11.934H934.968A11.948,11.948,0,0,1,946.9,533.247v15.52a2.629,2.629,0,0,1-5.258,0v-15.52a6.682,6.682,0,0,0-6.673-6.675H808.252a6.683,6.683,0,0,0-6.673,6.675v71.49a6.682,6.682,0,0,0,6.673,6.673H903.6a2.629,2.629,0,1,1,0,5.258Z"
          transform="translate(11.642 12.531)"
          fill="#2f4265"
        />
        <g transform="translate(837.263 513.621)">
          <rect
            width="18.203"
            height="44.128"
            rx="3.462"
            transform="translate(2.628 2.629)"
            fill="#fff"
          />
          <path
            d="M819.194,563.008a11.744,11.744,0,0,1-11.729-11.732V525.352a11.73,11.73,0,1,1,23.46,0v25.924A11.746,11.746,0,0,1,819.194,563.008Zm0-44.128a6.478,6.478,0,0,0-6.47,6.473v25.924a6.472,6.472,0,1,0,12.943,0V525.352A6.482,6.482,0,0,0,819.194,518.879Z"
            transform="translate(-807.465 -513.621)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(906.318 513.621)">
          <rect
            width="18.203"
            height="44.128"
            rx="3.462"
            transform="translate(2.63 2.629)"
            fill="#fff"
          />
          <path
            d="M845.462,563.008a11.746,11.746,0,0,1-11.731-11.732V525.352a11.73,11.73,0,1,1,23.46,0v25.924A11.744,11.744,0,0,1,845.462,563.008Zm0-44.128a6.482,6.482,0,0,0-6.473,6.473v25.924a6.472,6.472,0,1,0,12.943,0V525.352A6.478,6.478,0,0,0,845.462,518.879Z"
            transform="translate(-833.73 -513.621)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(828.878 569.885)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.631 2.628)"
            fill="#fff"
          />
          <path
            d="M816.3,552.829H810.06a5.789,5.789,0,0,1-5.784-5.782V540.8a5.789,5.789,0,0,1,5.784-5.781H816.3a5.788,5.788,0,0,1,5.781,5.781v6.244A5.788,5.788,0,0,1,816.3,552.829Zm-6.244-12.549a.525.525,0,0,0-.526.523v6.244a.527.527,0,0,0,.526.523H816.3a.526.526,0,0,0,.523-.523V540.8a.524.524,0,0,0-.523-.523Z"
            transform="translate(-804.276 -535.021)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(852.186 569.885)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.629 2.628)"
            fill="#fff"
          />
          <path
            d="M825.167,552.829h-6.244a5.787,5.787,0,0,1-5.782-5.782V540.8a5.787,5.787,0,0,1,5.782-5.781h6.244a5.786,5.786,0,0,1,5.781,5.781v6.244A5.787,5.787,0,0,1,825.167,552.829Zm-6.244-12.549a.523.523,0,0,0-.523.523v6.244a.525.525,0,0,0,.523.523h6.244a.526.526,0,0,0,.523-.523V540.8a.525.525,0,0,0-.523-.523Z"
            transform="translate(-813.141 -535.021)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(875.491 569.885)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.629 2.628)"
            fill="#fff"
          />
          <path
            d="M834.031,552.829h-6.247a5.786,5.786,0,0,1-5.779-5.782V540.8a5.786,5.786,0,0,1,5.779-5.781h6.247a5.786,5.786,0,0,1,5.779,5.781v6.244A5.786,5.786,0,0,1,834.031,552.829Zm-6.247-12.549a.522.522,0,0,0-.521.523v6.244a.524.524,0,0,0,.521.523h6.247a.524.524,0,0,0,.521-.523V540.8a.522.522,0,0,0-.521-.523Z"
            transform="translate(-822.005 -535.021)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(898.796 569.885)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.629 2.628)"
            fill="#fff"
          />
          <path
            d="M842.895,552.829h-6.247a5.786,5.786,0,0,1-5.779-5.782V540.8a5.786,5.786,0,0,1,5.779-5.781h6.247a5.788,5.788,0,0,1,5.782,5.781v6.244A5.788,5.788,0,0,1,842.895,552.829Zm-6.247-12.549a.522.522,0,0,0-.521.523v6.244a.524.524,0,0,0,.521.523h6.247a.525.525,0,0,0,.523-.523V540.8a.523.523,0,0,0-.523-.523Z"
            transform="translate(-830.869 -535.021)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(922.098 569.885)">
          <path
            d="M840.733,545.612v-6.636a2.955,2.955,0,0,1,2.958-2.955h6.633"
            transform="translate(-838.101 -533.392)"
            fill="#fff"
          />
          <path
            d="M842.361,549.871a2.628,2.628,0,0,1-2.629-2.629v-6.633a5.594,5.594,0,0,1,5.587-5.587h6.636a2.629,2.629,0,0,1,0,5.258h-6.636a.327.327,0,0,0-.329.329v6.633A2.627,2.627,0,0,1,842.361,549.871Z"
            transform="translate(-839.732 -535.021)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(828.878 592.362)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.631 2.629)"
            fill="#fff"
          />
          <path
            d="M816.3,561.377H810.06a5.79,5.79,0,0,1-5.784-5.779v-6.247a5.791,5.791,0,0,1,5.784-5.781H816.3a5.79,5.79,0,0,1,5.781,5.781V555.6A5.789,5.789,0,0,1,816.3,561.377Zm-6.244-12.549a.525.525,0,0,0-.526.523V555.6a.525.525,0,0,0,.526.521H816.3a.524.524,0,0,0,.523-.521v-6.247a.524.524,0,0,0-.523-.523Z"
            transform="translate(-804.276 -543.57)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(852.186 592.362)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.629 2.629)"
            fill="#fff"
          />
          <path
            d="M825.167,561.377h-6.244a5.788,5.788,0,0,1-5.782-5.779v-6.247a5.788,5.788,0,0,1,5.782-5.781h6.244a5.788,5.788,0,0,1,5.781,5.781V555.6A5.788,5.788,0,0,1,825.167,561.377Zm-6.244-12.549a.523.523,0,0,0-.523.523V555.6a.522.522,0,0,0,.523.521h6.244a.524.524,0,0,0,.523-.521v-6.247a.525.525,0,0,0-.523-.523Z"
            transform="translate(-813.141 -543.57)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(875.491 592.362)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.629 2.629)"
            fill="#fff"
          />
          <path
            d="M834.031,561.377h-6.247a5.787,5.787,0,0,1-5.779-5.779v-6.247a5.788,5.788,0,0,1,5.779-5.781h6.247a5.788,5.788,0,0,1,5.779,5.781V555.6A5.787,5.787,0,0,1,834.031,561.377Zm-6.247-12.549a.522.522,0,0,0-.521.523V555.6a.522.522,0,0,0,.521.521h6.247a.522.522,0,0,0,.521-.521v-6.247a.522.522,0,0,0-.521-.523Z"
            transform="translate(-822.005 -543.57)"
            fill="#2f4265"
          />
        </g>
        <g transform="translate(898.796 592.362)">
          <rect
            width="12.549"
            height="12.549"
            rx="1.199"
            transform="translate(2.629 2.629)"
            fill="#fff"
          />
          <path
            d="M842.895,561.377h-6.247a5.787,5.787,0,0,1-5.779-5.779v-6.247a5.788,5.788,0,0,1,5.779-5.781h6.247a5.79,5.79,0,0,1,5.782,5.781V555.6A5.79,5.79,0,0,1,842.895,561.377Zm-6.247-12.549a.522.522,0,0,0-.521.523V555.6a.522.522,0,0,0,.521.521h6.247a.522.522,0,0,0,.523-.521v-6.247a.523.523,0,0,0-.523-.523Z"
            transform="translate(-830.869 -543.57)"
            fill="#2f4265"
          />
        </g>
        <path
          d="M870.674,601.112a32.416,32.416,0,1,1,32.415-32.418A32.455,32.455,0,0,1,870.674,601.112Zm0-59.574a27.158,27.158,0,1,0,27.157,27.157A27.189,27.189,0,0,0,870.674,541.537Z"
          transform="translate(79.966 36.914)"
          fill="#2f4265"
        />
        <g transform="translate(945.453 585.731)">
          <path
            d="M855.064,580.8h-2.592a3.842,3.842,0,0,1-3.857-3.857v-2.222a3.988,3.988,0,0,1,1.165-2.753,3.776,3.776,0,0,1,2.755-1.167H855a3.86,3.86,0,0,1,2.75,1.133,3.743,3.743,0,0,1,1.167,2.724v2.285a3.842,3.842,0,0,1-3.857,3.857Zm-2.529-7.47a1.242,1.242,0,0,0-.949.407,1.422,1.422,0,0,0-.442,1.018v2.188a1.32,1.32,0,0,0,1.328,1.33h2.592a1.32,1.32,0,0,0,1.33-1.33v-2.285a1.209,1.209,0,0,0-.41-.917,1.31,1.31,0,0,0-.981-.41Zm1.325-4.414a3.73,3.73,0,0,1-2.789-1.17,4.059,4.059,0,0,1-1.125-2.613l-1.267-20.153a3.544,3.544,0,0,1,1.1-2.8,3.876,3.876,0,0,1,2.755-1.133h2.592a3.782,3.782,0,0,1,2.777,1.207,3.633,3.633,0,0,1,1.078,2.787v.005l-1.265,20.095a4.135,4.135,0,0,1-1.2,2.666A3.686,3.686,0,0,1,853.86,568.92Zm-1.325-25.342a1.32,1.32,0,0,0-.986.41,1.013,1.013,0,0,0-.347.844l1.267,20.153a1.563,1.563,0,0,0,.444,1.033,1.194,1.194,0,0,0,.946.373,1.15,1.15,0,0,0,.868-.371,1.616,1.616,0,0,0,.468-1.062l1.262-20.069a1.1,1.1,0,0,0-.373-.881,1.258,1.258,0,0,0-.957-.431Z"
            transform="translate(-848.615 -541.048)"
            fill="#2f4265"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SubscribeGateway;
