import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ListWrapper,
  PageTitle,
  LinkWrapper,
  ListItems,
  ListHeaderItem,
} from "../../PageWithList/List/Styles";
import ListHeader from "../../PageWithList/List/ListHeader";
import CreateButton from "../../Dashboard/UI/CreateButton";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";

const EmptyTruckList = (props) => {
  const { USER } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  /*   const handleSorting = (type) => {
    if (isBrowser)
      props.setSorting((prevState) => ({
        sortBy: type,
        order:
          prevState.sortBy !== type
            ? "DESC"
            : prevState.order === "DESC"
            ? "ASC"
            : "DESC",
      }));
  }; */

  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  return (
    <>
      <ListWrapper>
        <PageTitle>
          <h1>All empty trucks</h1>
          <LinkWrapper>
            {USER && !isExpired ? (
              <CreateButton
                title="Create Empty truck"
                to="/dashboard/trucks/create"
              />
            ) : USER && isExpired ? (
              <CreateButton
                title="Create Empty truck"
                isButton
                onClick={() => setExpiredModalOpen(true)}
              />
            ) : (
              <CreateButton
                title="Create Empty truck"
                isButton
                onClick={() => setLoggedModalOpen(true)}
              />
            )}
          </LinkWrapper>
        </PageTitle>
        <ListHeader>
          <ListHeaderItem flex="0.4">From</ListHeaderItem>
          <ListHeaderItem flex="0.4">To</ListHeaderItem>
          <ListHeaderItem flex="0.4">Truck type</ListHeaderItem>
          <ListHeaderItem flex="0.4">Special features</ListHeaderItem>
          <ListHeaderItem flex="0.3">Publisher</ListHeaderItem>
        </ListHeader>
        <ListItems>{props.children}</ListItems>
      </ListWrapper>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
    </>
  );
};

/* const sortIconUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path d="M4,0,8,6H0Z" fill="#686a7d" />
  </svg>
);

const sortIconDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="6"
    viewBox="0 0 8 6"
  >
    <path
      d="M4,0,8,6H0Z"
      transform="translate(8 6) rotate(180)"
      fill="#686a7d"
    />
  </svg>
); */

export default EmptyTruckList;
