// SETTERS
export const SET_TENDERS = "SET_TENDERS";
export const SET_ROUNDTRIPS = "SET_ROUNDTRIPS";
export const SET_SPOTLOADS = "SET_SPOTLOADS";
export const SET_EMPTYTRUCKS = "SET_EMPTYTRUCKS";

// FLEET
export const ADD_FLEET = "ADD_FLEET";
export const REMOVE_FLEET = "REMOVE_FLEET";

// DOCUMENT
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";

// USER
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

// SUBSCRIPTION
export const SET_USER_SUBSCRIPTION = "SET_USER_SUBSCRIPTION";

// NOTIFICATIONS
export const UPDATE_USER_NOTIFICATION = "UPDATE_USER_NOTIFICATION";

// FAVOURITE

export const ADD_FAVOURITE = "ADD_FAVOURITE";
export const REMOVE_FAVOURITE = "REMOVE_FAVOURITE";

// COMPANY
export const SET_COMPANY = "SET_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const ADD_COMPANY_USER = "ADD_COMPANY_USER";
export const REMOVE_USER = "REMOVE_USER";
export const UPDATE_COMPANY_USER_PRIVILEGE = "UPDATE_COMPANY_USER_PRIVILEGE";
export const REMOVE_COMPANY_USER = "REMOVE_COMPANY_USER";

// OFFERS

export const SET_OFFERS = "SET_OFFERS";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const REMOVE_OFFER = "REMOVE_OFFER";

export const SET_QUOTATIONS = "SET_QUOTATIONS";

export const SHOW_MESSAGE_WINDOW = "SHOW_MESSAGE_WINDOW";
export const SET_LAST_MESSAGE_WINDOW = "SET_LAST_MESSAGE_WINDOW";
export const SET_CURRENT_MESSAGE_WINDOW = "SET_CURRENT_MESSAGE_WINDOW";
export const SHOW_MINI_MESSAGES = "SHOW_MINI_MESSAGES";
export const SET_ACTIVE_MINI_CONVERSATIONS = "SET_ACTIVE_MINI_CONVERSATIONS";

// MISC

export const SET_UNREAD = "SET_UNREAD";
