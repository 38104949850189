import React, { useState, useEffect } from "react";
import uuid from "uuid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/Dashboard/Subscription/Subscription.css";
import iSubscription from "../../../assets/icons/subscription.svg";

import CardDetails from "./CardDetails";
import Table from "../UI/Table";
import Card from "../UI/Card";
import Loader from "../UI/Loader";
import {
  cancelUserSubscription,
  getUserSubscription,
  getInvoice,
} from "../../../store/actions/dashboard/user";
import LeftDrawer from "../Drawers/LeftDrawer";
import Screen from "../Screen/Screen";
import ScreenBlock from "../Screen/ScreenBlock";
import Navbar from "../Navbar";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";
const FileDownload = require("js-file-download");

const Subscription = () => {
  const [cardDetailsModal, setCardDetailsModal] = useState(false);
  const SUBSCRIPTION = useSelector(
    (state) => state.dashboard.user?.subscription
  );
  const dispatch = useDispatch();

  // Get current subscription
  useEffect(() => {
    if (!SUBSCRIPTION) {
      dispatch(getUserSubscription());
    }
  }, [SUBSCRIPTION]);

  const showPaymentInvoice = (id) => {
    dispatch(getInvoice(id)).then((response) => {
      FileDownload(response.data, `invoice${id}.pdf`);
    });
  };

  return (
    <Screen>
      <Navbar />
      <ScreenBlock title="Subscription" img={iSubscription}>
        {/* Display current subscription table */}
        {SUBSCRIPTION ? (
          <Table
            head={[
              {
                key: uuid(),
                items: [
                  { name: "Membership" },
                  { name: "Status" },
                  { name: "Plan Expiry" },
                  { name: "Options" },
                ],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: [
                  {
                    key: SUBSCRIPTION._id,
                    id: SUBSCRIPTION._id,
                    item: [
                      {
                        mobileTitle: "Membership",
                        name: SUBSCRIPTION.subscriptionName,
                        subtitle: SUBSCRIPTION.duration,
                      },
                      {
                        mobileTitle: "Status",
                        togglerStatus: SUBSCRIPTION.status,
                      },
                      {
                        mobileTitle: "Plan Expiry",
                        name: moment(SUBSCRIPTION.expirationDate).format(
                          "DD-MM-YY"
                        ),
                      },
                      {
                        options: [
                          {
                            key: uuid(),
                            items: [
                              {
                                name: "Upgrade Plan",
                                redirect: true,
                                to: "/pricing",
                              },
                              {
                                name: "Cancel Plan",
                                confirmationModal: true,
                                onConfirm: () =>
                                  dispatch(
                                    cancelUserSubscription(SUBSCRIPTION._id)
                                  ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        ) : (
          !SUBSCRIPTION && <Loader />
        )}
        {/* Display line break if current subscription exists and its not cancelled or  */}
        {SUBSCRIPTION?.subscriptionName !== "Free trial" &&
          SUBSCRIPTION?.status !== "Cancelled" &&
          SUBSCRIPTION?.status !== "Expired" && <hr />}

        {/* Display payment information if current subscription exists and its not cancelled or expired */}
        {SUBSCRIPTION &&
          SUBSCRIPTION?.subscriptionName !== "Free trial" &&
          SUBSCRIPTION?.status !== "Cancelled" &&
          SUBSCRIPTION?.status !== "Expired" && (
            <div className="Table">
              <div className="Table--inner">
                <div className="Table-head only-desktop">
                  <h4>Payment information</h4>
                </div>
                <div className="Table-content">
                  <div className="Table-row">
                    <div className="item">
                      <h6 className="only-mobile">Payment Information</h6>
                      <h4>
                        Next payment scheduled{" "}
                        {moment(SUBSCRIPTION.expirationDate).format(
                          "DD MMMM YYYY"
                        )}
                      </h4>
                    </div>
                    {/*
                        <div className="item">
                          <div
                            className={`options ${cardDetailsDropdown}`}
                            onClick={() =>
                              setCardDetailsDropdown(!cardDetailsDropdown)
                            }
                          >
                            <img src={iOptions} alt="Options" />
                            <div className="options-dropdown">
                              <ul>
                                <li
                                  onClick={() =>
                                    setCardDetailsModal(!cardDetailsModal)
                                  }
                                >
                                  Card Details
                              </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                          */}
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* Show card details modal if current subscription exists and its not cancelled or expired */}
        {SUBSCRIPTION?.status !== "Cancelled" &&
          SUBSCRIPTION?.status !== "Expired" &&
          cardDetailsModal && (
            <CardDetails
              modal={cardDetailsModal}
              setModal={setCardDetailsModal}
            />
          )}
      </ScreenBlock>
      {/* Display billing history table */}
      {SUBSCRIPTION?.Payments && SUBSCRIPTION?.Payments?.length > 0 && (
        <Card title="Billing history">
          <Table
            head={[
              {
                key: uuid(),
                items: [
                  { name: "Invoice #" },
                  { name: "Date" },
                  { name: "Amount" },
                  { name: "Options" },
                ],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: SUBSCRIPTION.Payments.map((payment) => {
                  return {
                    item: [
                      {
                        mobileTitle: "Invoice #",
                        name: payment.invoiceNumber,
                      },
                      {
                        mobileTitle: "Date",
                        name: moment(SUBSCRIPTION.expirationDate).format(
                          "DD-MM-YY"
                        ),
                      },
                      {
                        mobileTitle: "Amount",
                        name: payment.amount + "€",
                      },
                      {
                        options: [
                          {
                            key: uuid(),
                            items: [
                              {
                                name: "Download invoice",
                                isLink: true,
                                //to: ``,
                                onClick: () => showPaymentInvoice(payment._id),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  };
                }),
              },
            ]}
          />
        </Card>
      )}
      {isBrowser && <Minimessages />}
      <LeftDrawer />
      <DashboardFooter />
    </Screen>
  );
};

export default Subscription;
