import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { NavLink, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../images/logo-white.svg";
import { isMobile } from "react-device-detect";
import { ReactSVG } from "react-svg";
import "../../../styles/Dashboard/Drawers.css";

import {
  SET_USER,
  SET_COMPANY,
  SET_USER_SUBSCRIPTION,
} from "../../../store/actionTypes";

import iTender from "../../../assets/icons/drawer-tender.svg";
import iMobileTender from "../../../assets/icons/mobile-drawer-tender.svg";
import iMobileRoundtrip from "../../../assets/icons/mobile-drawer-roundtrip.svg";
import iMenu from "../../../assets/icons/drawer-menu.svg";
import iMessages from "../../../assets/icons/drawer-messages.svg";
import iQuotations from "../../../assets/icons/drawer-quotations.svg";
import iOffers from "../../../assets/icons/drawer-offers.svg";
import iFavourites from "../../../assets/icons/drawer-favourites.svg";
import iSubscription from "../../../assets/icons/drawer-subscription.svg";
import iUsers from "../../../assets/icons/drawer-users.svg";
import iAccount from "../../../assets/icons/drawer-account.svg";
import iEmpty from "../../../assets/icons/icon-empty.svg";
import iLogout from "../../../assets/icons/icon-logout.svg";
import iLeftTender from "../../../assets/icons/left-drawer-tender.svg";
import iLeftRoundtrip from "../../../assets/icons/left-drawer-roundtrip.svg";
import iLeftTruck from "../../../assets/icons/left-drawer-trucks.svg";
import iLeftSpotload from "../../../assets/icons/left-drawer-spotload.svg";

import logoIcon from "../../../assets/icons/logo-icon.svg";

const LeftDrawer = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [letters, setLetters] = useState();
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const UNREAD = useSelector((state) => state.app.misc?.unread);
  const dispatch = useDispatch();

  useEffect(() => {
    // company letters
    if (COMPANY?.companyName) {
      const companyLetters = COMPANY.companyName.replace(/ /, "");
      let firstLetter = companyLetters.charAt(0);
      let secondLetter = companyLetters.charAt(1);
      setLetters(firstLetter + secondLetter);
    }
  }, [COMPANY]);

  const handleLogOut = () => {
    const cookies = new Cookies();
    cookies.remove("tc_token", { path: "/" });
    dispatch({
      type: SET_USER,
      payload: null,
    });
    dispatch({
      type: SET_COMPANY,
      payload: null,
    });
    dispatch({
      type: SET_USER_SUBSCRIPTION,
      payload: null,
    });
    props.history.push("/");
  };

  //Listen to route changes

  return (
    <>
      <div className="Left_Drawer" id={props.small && "left-drawer-small"}>
        <div className="Left_Drawer--inner">
          {isMobile && (
            <div className="Left_Drawer--mobile-nav">
              <ul>
                <li>
                  <NavLink to="/spotload">
                    <ReactSVG src={iTender} />
                    Exchange
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/roundtrip">
                    <ReactSVG src={iMobileRoundtrip} />
                    Roundtrip
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/tender">
                    <ReactSVG src={iMobileTender} />
                    Tender
                  </NavLink>
                </li>
                <li onClick={() => setOpenDrawer(!openDrawer)}>
                  <ReactSVG src={iMenu} />
                  Menu
                </li>
              </ul>
            </div>
          )}
          {!isMobile && (
            <>
              <div className="Drawer--logo">
                <NavLink to="/dashboard">
                  <img src={logo} alt="Transconnector" />
                  <img src={logoIcon} alt="Transconnector" />
                </NavLink>
              </div>
              <div className="Drawer--company">
                <NavLink to="/dashboard/my-company">
                  <div className="company-letters">{letters}</div>
                  My Company
                </NavLink>
              </div>
            </>
          )}
          <div
            className={`Drawer--items ${isMobile && openDrawer}`}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <ul>
              {isMobile && (
                <li>
                  {" "}
                  <NavLink activeClassName="active" to="/dashboard/my-company">
                    <div className="company-letters">{letters}</div>My Company
                  </NavLink>
                </li>
              )}
              {!isMobile && (
                <div
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  <li>
                    <NavLink activeClassName="active" to="/spotload">
                      <ReactSVG src={iLeftSpotload} />
                      Load Exchange
                    </NavLink>
                    <div className="link-tooltip">Load Exchange</div>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/roundtrip">
                      <ReactSVG src={iLeftRoundtrip} />
                      Roundtrip
                    </NavLink>
                    <div className="link-tooltip">Roundtrip</div>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/tender">
                      <ReactSVG src={iLeftTender} />
                      Tender
                    </NavLink>
                    <div className="link-tooltip">Tender</div>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/trucks">
                      <ReactSVG src={iLeftTruck} />
                      Empty trucks
                    </NavLink>
                    <div className="link-tooltip">Empty trucks</div>
                  </li>
                </div>
              )}
              <li>
                <NavLink activeClassName="active" to="/dashboard/messages">
                  <ReactSVG src={iMessages} />
                  {UNREAD > 0 && <span className="unread-count">{UNREAD}</span>}
                  Messages
                </NavLink>
                <div className="link-tooltip">Messages</div>
              </li>
              <li>
                <NavLink activeClassName="active" to="/dashboard/quotations">
                  <ReactSVG src={iQuotations} />
                  Quotations
                </NavLink>
                <div className="link-tooltip">Quotations</div>
              </li>
              <li>
                <NavLink activeClassName="active" to="/dashboard/my-offers">
                  <ReactSVG src={iOffers} />
                  My Offers
                </NavLink>
                <div className="link-tooltip">My Offers</div>
              </li>
              <li>
                <NavLink activeClassName="active" to="/dashboard/favourites">
                  <ReactSVG src={iFavourites} />
                  Favourites
                </NavLink>
                <div className="link-tooltip">Favourites</div>
              </li>
              {USER && USER.privileges === "Admin" && (
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/dashboard/subscription"
                  >
                    <ReactSVG src={iSubscription} />
                    Subscription
                  </NavLink>
                  <div className="link-tooltip">Subscription</div>
                </li>
              )}
              {USER && USER.privileges === "Admin" && (
                <li>
                  <NavLink activeClassName="active" to="/dashboard/users">
                    <ReactSVG src={iUsers} />
                    Users
                  </NavLink>
                  <div className="link-tooltip">Users</div>
                </li>
              )}
              <li>
                <NavLink activeClassName="active" to="/dashboard/my-account">
                  <ReactSVG src={iAccount} />
                  My Account
                </NavLink>
                <div className="link-tooltip">My Account</div>
              </li>
              <div
                style={{
                  marginTop: "1.5rem",
                }}
              >
                <li>
                  <NavLink activeClassName="" to="/" onClick={handleLogOut}>
                    <ReactSVG src={iLogout} />
                    Log Out
                  </NavLink>
                  <div className="link-tooltip">Log Out</div>
                </li>
              </div>
              <div className="only-mobile">
                <hr />
                <li>
                  <NavLink activeClassName="active" to="/spotload">
                    <ReactSVG src={iTender} />
                    Exchange
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/tender">
                    <ReactSVG src={iMobileTender} />
                    Tender
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/roundtrip">
                    <ReactSVG src={iMobileRoundtrip} />
                    Roundtrip
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/trucks">
                    <ReactSVG src={iMobileRoundtrip} />
                    Empty trucks
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/pricing">
                    <ReactSVG src={iEmpty} />
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/blog">
                    <ReactSVG src={iEmpty} />
                    Blog
                  </NavLink>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {isMobile && (
        <div
          className={`Left_Drawer-close ${isMobile && openDrawer}`}
          onClick={() => setOpenDrawer(!openDrawer)}
        ></div>
      )}
    </>
  );
};

export default withRouter(LeftDrawer);
