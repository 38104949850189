import React from "react";
import logo from "../../images/logo-light.svg";
import Container from "../UI/Container";

import {
  Wrapper,
  Logo,
  Content,
  Inner,
  InlineItems,
  Item,
  Social,
} from "./Styles";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Logo>
            <Link to="/">
              <img src={logo} alt="TransConnector" />
            </Link>
          </Logo>
          <Content>
            <InlineItems>
              <Item>
                <h3>Product</h3>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                </ul>
              </Item>
              <Item>
                <h3>Other</h3>
                <ul>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </Item>
            </InlineItems>
            <Item>
              <h3>Support</h3>
              <h4>
                Our customer service team is 24/7{" "}
                <a href="mailto:support@transconnector.eu">
                  support@transconnector.eu
                </a>
              </h4>
            </Item>
            <Item>
              <h3>Contact</h3>
              <ol>
                <li>
                  <a href="mailto:info@transconnector.eu">
                    info@transconnector.eu
                  </a>
                </li>
                <li>
                  <a href="tel:+37256886666">+372 56 886 666</a>
                </li>
              </ol>
              <span>Tartu, Küütri 3, 51005, Estonia</span>
              <Social>
                <a
                  href="https://www.linkedin.com/company/transconnector/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {linkedin}
                </a>
                <a
                  href="https://www.facebook.com/Transconnector/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {fb}
                </a>
                <a
                  href="https://www.instagram.com/transconnector.eu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {insta}
                </a>
              </Social>
            </Item>
          </Content>
        </Inner>
      </Container>
    </Wrapper>
  );
};

const linkedin = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.5"
    height="31.5"
    viewBox="0 0 31.5 31.5"
  >
    <path
      d="M29.25,2.25H2.243A2.26,2.26,0,0,0,0,4.521V31.479A2.26,2.26,0,0,0,2.243,33.75H29.25a2.266,2.266,0,0,0,2.25-2.271V4.521A2.266,2.266,0,0,0,29.25,2.25Zm-19.73,27H4.852V14.217H9.527V29.25ZM7.186,12.164A2.707,2.707,0,1,1,9.893,9.457a2.708,2.708,0,0,1-2.707,2.707ZM27.021,29.25H22.352V21.938c0-1.744-.035-3.987-2.426-3.987-2.433,0-2.805,1.9-2.805,3.86V29.25H12.452V14.217h4.479V16.27h.063a4.917,4.917,0,0,1,4.423-2.426c4.725,0,5.6,3.115,5.6,7.165Z"
      transform="translate(0 -2.25)"
      fill="#f6f6f6"
    />
  </svg>
);

const fb = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.5"
    height="31.5"
    viewBox="0 0 31.5 31.5"
  >
    <path
      d="M28.125,2.25H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h9.65V23.041H8.6V18h4.43V14.158c0-4.37,2.6-6.784,6.586-6.784a26.836,26.836,0,0,1,3.9.34V12h-2.2a2.521,2.521,0,0,0-2.842,2.723V18h4.836l-.773,5.041H18.475V33.75h9.65A3.375,3.375,0,0,0,31.5,30.375V5.625A3.375,3.375,0,0,0,28.125,2.25Z"
      transform="translate(0 -2.25)"
      fill="#f6f6f6"
    />
  </svg>
);

const insta = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.518"
    height="31.51"
    viewBox="0 0 31.518 31.51"
  >
    <path
      d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
      transform="translate(0.005 -2.238)"
      fill="#f6f6f6"
    />
  </svg>
);

export default Footer;
