import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";

import "../../../styles/Header/Header.css";
import { withRouter, Link } from "react-router-dom";
import logo from "../../../images/logo.svg";
import close from "../../../images/close.svg";
import user from "../../../images/user.svg";
import email from "../../../images/email.svg";
import { getUnread } from "../../../store/actions/misc";
import Container from "../../UI/Container";
import {
  SET_USER,
  SET_COMPANY,
  SET_USER_SUBSCRIPTION,
} from "../../../store/actionTypes";

const cookies = new Cookies(),
  token = cookies.get("tc_token");

const Header = ({ history }) => {
  const USER = useSelector((state) => state.dashboard.user?.data);
  const messageCount = useSelector((state) => state.app?.misc?.unread);
  const dispatch = useDispatch();
  const [active, setActive] = useState("off");

  // Get data for message count
  useEffect(() => {
    if (USER) {
      dispatch(getUnread(token));
    }
  }, [USER]);

  const handleLogOut = () => {
    const cookies = new Cookies();
    cookies.remove("tc_token", { path: "/" });
    dispatch({
      type: SET_USER,
      payload: null,
    });
    dispatch({
      type: SET_COMPANY,
      payload: null,
    });
    dispatch({
      type: SET_USER_SUBSCRIPTION,
      payload: null,
    });
    history.push("/");
  };

  const toggle = () => setActive(active === "off" ? "on" : "off");

  return (
    <>
      <div className="navbar-desktop">
        <Container>
          <header>
            <Link to="/" className="logo">
              <img src={logo} alt="TransConnector" />
            </Link>
            <div className="nav__items">
              <Link to="/spotload">Load Exchange</Link>
              <Link to="/roundtrip">Roundtrip</Link>
              <Link to="/tender">Tender</Link>
              <Link to="/trucks">Empty trucks</Link>
              <div className="info">
                <Link to="#!">Info</Link>
                <div className="info--dropdown">
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/free-trial">Free trial</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="nav__actions">
              {USER ? (
                <div className="nav__logged_actions">
                  <div className="email">
                    <Link to="/dashboard/messages">
                      <img src={email} alt="" />
                      {messageCount >= 1 && (
                        <div className="email--count">
                          <h6>{messageCount}</h6>
                        </div>
                      )}
                    </Link>
                  </div>
                  <div className="user">
                    <Link to="/dashboard/">
                      <img src={user} alt="" />
                    </Link>
                    <div className="user--dropdown">
                      <ul>
                        <li>
                          <Link to="/dashboard/my-company">Profile</Link>
                        </li>
                        <li>
                          <Link to="/dashboard/my-offers">My offers</Link>
                        </li>
                        {USER.privileges === "Admin" && (
                          <li>
                            <Link to="/dashboard/subscription">
                              Subscription
                            </Link>
                          </li>
                        )}
                        <li onClick={handleLogOut}>Log out</li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Link to="/log-in">Login</Link>
                  <Link to="/sign-up">Join now</Link>{" "}
                </>
              )}
            </div>
          </header>
        </Container>
      </div>
      <div className="navbar-mobile">
        <Container>
          <header>
            <Link to="/dashboard" className="logo">
              <img src={logo} alt="TransConnector" />
            </Link>
            <div className="burger" onClick={toggle}>
              <div className="burger-inner"></div>
            </div>
            <div className={`nav__items ${active}`} onClick={toggle}>
              <div className="close" onClick={toggle}>
                <img src={close} alt="Close" />
              </div>
              <Link to="/spotload">Load Exchange</Link>
              <Link to="/roundtrip">Roundtrip</Link>
              <Link to="/tender">Tender</Link>
              <Link to="/trucks">Empty trucks</Link>
              <Link to="/pricing">Pricing</Link>
              <Link to="/about">About</Link>
              <Link to="/free-trial">Free trial</Link>
              <Link to="/blog">Blog</Link>
              <div className="nav__actions">
                {USER ? (
                  <div className="nav__logged_actions">
                    <Link to="/dashboard/messages">Messages</Link>
                    <Link to="/dashboard/my-account">My account</Link>
                    <Link to="/" onClick={handleLogOut}>
                      Log out
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link to="/sign-up">Join now</Link>
                    <Link to="/log-in">Login</Link>
                  </>
                )}
              </div>
            </div>
          </header>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Header);
