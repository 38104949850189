import React, { useState } from "react";
import Modal from "../Modal";
import Header from "../Header";
import MessageBox from "../MessageBox";
import Footer from "../Footer";
import LargeButton from "../../CreateForm/LargeButton";
import { ModalInnerWrapper, StickyWrapper } from "../Styles";

const MessageModal = (props) => {
  const [value, setValue] = useState("");

  return (
    <Modal onClose={props.onClose} small>
      <StickyWrapper>
        <Header title="Send Message" onClose={props.onClose} />
        <ModalInnerWrapper>
          <MessageBox
            value={value}
            onInputChange={(value) => setValue(value)}
          />
        </ModalInnerWrapper>
        <Footer>
          <LargeButton
            label="Send Message"
            onClick={() => props.handleMessageSubmit(value)}
          />
        </Footer>
      </StickyWrapper>
    </Modal>
  );
};

export default MessageModal;
