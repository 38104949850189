import React from "react";
import { CheckboxWrapper } from "../CreateForm/Styles";

const Checkbox = (props) => {
  return (
    <CheckboxWrapper
      selected={
        !props.descendant
          ? props.inputs?.[props.value]
          : props.inputs?.[props.descendant]?.[props.value]
      }
    >
      <label htmlFor={props.value}>
        <input
          type="checkbox"
          name={props.label}
          value={props.value}
          id={props.value}
          checked={
            !props.descendant
              ? props.inputs?.[props.value] || false
              : props.inputs?.[props.descendant]?.[props.value] || false
          }
          onChange={(e) =>
            !props.descendant
              ? props.onInputChange(e.target.checked, props.value)
              : props.onInputChange(
                  e.target.checked,
                  props.value,
                  props.descendant
                )
          }
        />
        {props.label && props.label}
      </label>
    </CheckboxWrapper>
  );
};

export default Checkbox;
