import React, { useState, useEffect } from "react";
import moment from "moment";
import Cookies from "universal-cookie";
import image from "../images/login.png";
import "../styles/Forgot/Forgot.css";
import logo from "../images/logo-white.svg";
import { Redirect, withRouter } from "react-router-dom";
import Header from "../components/FrontPage/Header/Header";
import axios from "axios";
import Loader from "../components/Dashboard/UI/Loader";
import { API_URL } from "../constants";

const ResetPassword = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [resetId] = useState(props.match.params.id);
  const [success, setSuccess] = useState(false);
  const [inputs, setInputs] = useState({});
  const [notAllowed, setNotAllowed] = useState(false);
  const [userId, setUserId] = useState();

  // check if page is accessible
  useEffect(() => {
    axios
      .get(`${API_URL}/recoverUser/${resetId}`)
      .then((res) => {
        if (res.data.userId) {
          setLoader(false);
          setUserId(res.data.userId);
        } else {
          setNotAllowed(true);
        }
      })
      .catch(() => setNotAllowed(true));
  }, [resetId]);

  // handle input change

  const inputChangeHandler = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  // handle form submission

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setErrorMessage("");
    if (inputs.password !== inputs.repeatPassword) {
      setError(true);
      setErrorMessage("Passwords do not match");
    } else if (inputs.password?.length < 8) {
      setError(true);
      setErrorMessage("Password must be atleast 8 characters");
    } else if (inputs.password?.length > 30) {
      setError(true);
      setErrorMessage("Password must be less than 30 characters");
    } else {
      axios
        .post(`${API_URL}/updateRecoveredUser`, {
          userId: userId,
          password: inputs.password,
        })
        .then((res) => {
          const token = res.data;
          const cookies = new Cookies();
          cookies.set("tc_token", token, {
            path: "/",
            expires: moment().add(14, "days").toDate(),
          });
          setSuccess(true);
        })
        .catch(() => {
          setError(true);
          setErrorMessage("An unexpected error occurred. Try again later.");
        });
    }
  };

  // redirect if not allowed to visit the page

  if (notAllowed) {
    return <Redirect to="/" />;
  }

  // redirect to dashboard when password is reset

  if (success) {
    return <Redirect to="/log-in" />;
  }

  return (
    <>
      <Header />
      <div className="Login">
        <div className="Login__Image">
          <img src={logo} alt="TransConnector" />
          <img src={image} alt="TransConnector" />
        </div>
        <div className="Login--inner">
          {!loader ? (
            <>
              <h2>Reset password</h2>
              <h4>Enter new password</h4>
              <form onSubmit={(e) => handleSubmit(e)}>
                {
                  <div className={`Login__Error animated ${error}`}>
                    <p>{`${errorMessage}`}</p>
                  </div>
                }
                <div className="floating-label">
                  <input
                    type="password"
                    placeholder=" "
                    name="password"
                    value={inputs.password || ""}
                    onChange={(e) => inputChangeHandler(e)}
                  />
                  <label>Password</label>
                </div>
                <div className="floating-label">
                  <input
                    type="password"
                    placeholder=" "
                    name="repeatPassword"
                    value={inputs.repeatPassword || ""}
                    onChange={(e) => inputChangeHandler(e)}
                  />
                  <label>Repeat Password</label>
                </div>
                <div className="Submit__Form">
                  <input
                    //className={`${loader}`}
                    type="submit"
                    value="Reset Password"
                  />
                  <div className={`loader ${loader}`}></div>
                </div>
              </form>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(ResetPassword);
