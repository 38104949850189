import React from "react";
import Select from "react-select";
import { selectStyles } from "./Styles";
import allCountries from "../../../data/allCountries.json";
const countries = allCountries.countries;

const CountrySelect = (props) => {
  return (
    <Select
      placeholder={props.placeholder}
      options={countries}
      isMulti
      closeMenuOnSelect={false}
      isClearable={false}
      styles={selectStyles}
      name={props.type}
      value={props.filters?.[props.type] || null}
      onChange={(e) => props.onSelectChange(e, props.type)}
    />
  );
};

export default CountrySelect;
