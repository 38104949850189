import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    max-width: 698px;
  }
  @media (min-width: 992px) {
    max-width: 838px;
  }
  @media (min-width: 1200px) {
    max-width: 1020px;
  }
  @media (min-width: 1440px) {
    max-width: ${({ gutter }) =>
      gutter === "medium" ? "1054px" : gutter === "large" ? "822px" : "1286px"};
  }
  @media (min-width: 1740px) {
    max-width: ${({ gutter }) =>
      gutter === "medium"
        ? "1324px"
        : gutter === "large"
        ? "1092px"
        : "1556px"};
  }
  ${({ background }) => background && `background: ${background}`}
`;
