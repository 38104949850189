import React from "react";
import { StatusWrapper, StatusInner, StatusBubble } from "./Styles";

const Status = (props) => {
  return (
    <StatusWrapper onClick={props.onClick}>
      <StatusInner inReview={props.inReview} active={props.active}>
        <span>{props.status}</span>
        <StatusBubble />
      </StatusInner>
    </StatusWrapper>
  );
};

export default Status;
