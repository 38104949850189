import React from "react";
import { RadioGroup, Radio } from "./Styles";

const TypeOfLoadFilter = (props) => {
  return (
    <RadioGroup>
      <Radio checked={!props.filters.typeOfLoad}>
        <label htmlFor="All">
          <input
            type="checkbox"
            value="All"
            id="All"
            checked={!props.filters.typeOfLoad}
            name="typeOfLoad"
            onChange={(e) => props.onInputChange("", "typeOfLoad")}
          />
          All
        </label>
      </Radio>
      <Radio checked={props.filters.typeOfLoad === "FTL"}>
        <label htmlFor="FTL">
          <input
            type="checkbox"
            value="FTL"
            id="FTL"
            checked={props.filters.typeOfLoad === "FTL"}
            name="typeOfLoad"
            onChange={(e) =>
              props.onInputChange(
                e.target.checked ? e.target.value : "",
                "typeOfLoad"
              )
            }
          />
          FTL
        </label>
      </Radio>
      <Radio checked={props.filters.typeOfLoad === "LTL"}>
        <label htmlFor="LTL">
          <input
            type="checkbox"
            value="LTL"
            id="LTL"
            name="typeOfLoad"
            checked={props.filters.typeOfLoad === "LTL"}
            onChange={(e) =>
              props.onInputChange(
                e.target.checked ? e.target.value : "",
                "typeOfLoad"
              )
            }
          />
          LTL
        </label>
      </Radio>
    </RadioGroup>
  );
};

export default TypeOfLoadFilter;
