import React, { useState, useEffect } from "react";

import ScreenBlock from "../Screen/ScreenBlock";
import Loader from "../UI/Loader";
import Filter from "./Filter";
import RoundtripsTable from "./RoundtripsTable";

const Roundtrips = (props) => {
  const [filter, setFilter] = useState(0);
  const [active, setActive] = useState(0);
  const [inActive, setInactive] = useState(0);
  const [roundtrips, setRoundtrips] = useState([]);

  const { OFFERS } = props;
  // Handle setting roundtrip state
  useEffect(() => {
    if (OFFERS && OFFERS[1].roundtrips && OFFERS[1].roundtrips?.length > 0) {
      setRoundtrips(OFFERS[1].roundtrips);
    }
  }, [OFFERS]);

  // Reset filter when there are no roundtrips to show
  useEffect(() => {
    if (roundtrips?.length <= 0 && filter > 0) {
      setFilter(0);
      setRoundtrips(OFFERS[1].roundtrips);
    }
  }, [roundtrips, filter, OFFERS]);

  // Get count of active and inactive roundtrips
  useEffect(() => {
    if (OFFERS && OFFERS[1].roundtrips) {
      setActive(0);
      setInactive(0);
      OFFERS[1].roundtrips.forEach((e) =>
        e.active
          ? setActive((prevCount) => prevCount + 1)
          : setInactive((prevCount) => prevCount + 1)
      );
    }
  }, [OFFERS]);

  // Handle roundtrip filtering
  useEffect(() => {
    if (filter === 0 && OFFERS) {
      setRoundtrips(OFFERS[1].roundtrips);
    } else if (filter === 1) {
      setRoundtrips(OFFERS[1].roundtrips.filter((e) => e.active));
    } else if (filter === 2) {
      setRoundtrips(OFFERS[1].roundtrips.filter((e) => !e.active));
    }
  }, [OFFERS, filter]);

  // Display welcome mat when no roundtrips to show
  if (OFFERS && OFFERS[1].roundtrips && OFFERS[1].roundtrips?.length <= 0) {
    return null;
  }

  return (
    <ScreenBlock title="My Roundtrips">
      {roundtrips.length > 0 && (
        <Filter
          all={OFFERS && OFFERS[1]?.roundtrips?.length}
          active={active}
          inActive={inActive}
          filter={filter}
          setFilter={setFilter}
          type="Roundtrip"
          to="/dashboard/roundtrip/create"
          isExpired={props.isExpired}
          setExpiredModal={props.setExpiredModal}
        />
      )}
      {roundtrips.length > 0 ? (
        <RoundtripsTable
          roundtrips={roundtrips}
          handleStatusChange={props.handleStatusChange}
          handleOfferRemove={props.handleOfferRemove}
        />
      ) : (
        <Loader />
      )}
    </ScreenBlock>
  );
};

export default Roundtrips;
