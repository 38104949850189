import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";

const RoundtripsTable = (props) => {
  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.7">Roundtrip</TableHeaderItem>
          <TableHeaderItem flex="0.3">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.2">Price</TableHeaderItem>
          <TableHeaderItem flex="0.2">Published</TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.roundtrips.map((roundtrip) => {
            const truckTypes = roundtrip?.trucks?.truckTypes
              .map((element) => {
                if (
                  element.value === "Refrigerator" &&
                  roundtrip?.trucks?.temperature
                ) {
                  return `${element.value} (${roundtrip?.trucks?.temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ");
            return (
              <TableRow
                key={roundtrip._id}
                onClick={() =>
                  handleRedirect("/roundtrip", {
                    sheet: roundtrip._id,
                  })
                }
              >
                <TableRowItem
                  rowName="Roundtrip"
                  title={{
                    name: roundtrip.name,
                  }}
                  flex="0.7"
                >
                  <span>
                    {" "}
                    {roundtrip.countries
                      ?.map((country) => {
                        return country.ISO;
                      })
                      .join(", ")}
                  </span>
                </TableRowItem>
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truckTypes,
                  }}
                  flex="0.3"
                />
                <TableRowItem
                  rowName="Price"
                  title={{
                    name:
                      roundtrip?.price?.kmPrice &&
                      roundtrip?.price?.kmPrice !== 0
                        ? `${roundtrip?.price?.kmPrice}€ /km`
                        : "N/A",
                  }}
                  flex="0.2"
                >
                  {roundtrip?.price?.priceType && (
                    <span>{roundtrip?.price?.priceType}</span>
                  )}
                </TableRowItem>
                <TableRowItem
                  rowName="Published"
                  title={{
                    name: moment(roundtrip.createdAt).format("DD.MM.YY"),
                  }}
                  flex="0.2"
                />
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
    </>
  );
};

export default withRouter(RoundtripsTable);
