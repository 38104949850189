import styled from "styled-components";

export const Block = styled.div`
  @media (min-width: 1200px) {
    margin: 8rem 0;
    display: inline-flex;
    width: 100%;
    align-items: flex-end;
  }
`;
export const Content = styled.div`
  margin: 4rem 0;
  h3 {
    display: flex;
    align-items: center;
    color: #363636;
    font-size: 18px;
    svg {
      height: 30px;
      width: 30px;
      margin-right: 1rem;
    }
  }
  ul {
    padding-left: calc(30px + 1rem);
    li {
      color: #262a68;
      font-size: 14px;
      list-style-type: none;
      padding: 0.5rem 0;
    }
  }
  @media (min-width: 992px) {
    h3 {
      font-size: 2rem;
    }
    ul li {
      font-size: 1rem;
    }
  }
  @media (min-width: 1200px) {
    margin: 0;
    h3 {
      svg {
        margin: none;
        width: unset;
        height: unset;
        display: none;
      }
    }
    h3 {
      font-size: 36px;
    }
    ul {
      margin: 1.5rem 0;
      padding: 0;
      li {
        font-size: 18px;
      }
    }
  }
`;
export const OffsetImage = styled.div`
  position: absolute;
  z-index: -1;
  opacity: 0.2;
`;
export const Wrapper = styled.div`
  @media (min-width: 1200px) {
    ${Block}:nth-child(even) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      ${Content} {
        margin-left: 10rem;
      }
      ${OffsetImage} {
        top: 0;
        right: -20%;
      }
    }
  }
`;
export const MainImage = styled.div``;
export const PartnerImage = styled.div`
  display: none;
  @media (min-width: 1200px) {
    transform: translate(14%, 35%);
    display: block;
    position: relative;
    ${OffsetImage} {
      transform: translate(-90px, -25px);
    }
  }
  @media (min-width: 1440px) {
    transform: translate(20%, 35%);
  }
`;
export const PublishImage = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: relative;
  }
`;
