import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "./helpers/ScrollToTop";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Register from "./pages/Register";
import Invited from "./pages/Invited";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import ResetPassword from "./pages/ResetPassword";
import Pricing from "./pages/Pricing";
import FreeTrial from "./pages/FreeTrial";
import Checkout from "./pages/Checkout";
import Tender from "./pages/Tender";
import Roundtrip from "./pages/Roundtrip";
import Spotload from "./pages/Spotload";
import Dashboard from "./pages/Dashboard";
import { CookiesProvider } from "react-cookie";
import { getCompany } from "./store/actions/dashboard/company";
import { getUser } from "./store/actions/dashboard/user";
import BlogPage from "./pages/Blog";
import About from "./pages/About";
import FAQPage from "./pages/FAQ";
import TermsPage from "./pages/Terms";
import PrivacyPage from "./pages/Privacy";
import EmptyTruck from "./pages/EmptyTruck";
import CookieNotice from "./components/UserNotice/CookieNotice";
import { useClearCache } from "react-clear-cache";

//FACEBOOK PIXEL SETUP
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init("373022400279435", options);

ReactPixel.pageView();

//HOTJAR
hotjar.initialize(1424857, 6);
//GOOGLE ANALYTICS
ReactGA.initialize("UA-122084775-2");
ReactGA.pageview(window.location.pathname + window.location.search);

//Purechat script

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  console.log(isLatestVersion)
  const USER = useSelector((state) => state.dashboard?.user?.data);
  const COMPANY = useSelector((state) => state.dashboard?.company?.data);
  const dispatch = useDispatch();
  const cookies = new Cookies(),
    token = cookies.get("tc_token");
  // FETCH USER AND COMPANY WHEN TOKEN IS SET
  useEffect(() => {
    if (token && !USER) {
      dispatch(getUser(token));
      if (!COMPANY) {
        dispatch(getCompany(token));
      }
    }
  }, [token, USER]);

  // Wait for fetch to complete
  if (token && !USER) return null;
  return (
    <CookiesProvider>
      <Router>
        <div className="App">
          {!isLatestVersion && (
            <div style={{
              "position": "fixed",
              "z-index": "22",
              "width": "500px",
              "height": "300px",
              "top": "50%",
              "left": "50%",
              "background": "#fff",
              "border-radius": "6px",
              "transform": "translate(-50%, -50%)",
              "display": "flex",
              "align-items": "center",
              "justify-content": "center",
              "box-shadow": "#00000016 0 0px 10px",
              "flex-wrap": "wrap"
            }} className="updateNotice">
              <p>There is a newer version of TransConnector, please update</p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
              >Update version</a>
            </div>
          )}
          <ScrollToTop />
          <Switch>
            <Route exact path="/" render={() => <Home USER={USER} />} />
            <Route
              path="/log-in"
              render={() => <Login USER={USER} COMPANY={COMPANY} />}
            />
            <Route path="/sign-up" render={() => <Register USER={USER} />} />
            <Route exact path="/invited/:id" component={Invited} />
            <Route path="/forgot" render={() => <Forgot USER={USER} />} />
            <Route
              path="/reset-password/:id"
              render={() => <ResetPassword USER={USER} />}
            />
            <Route path="/pricing" render={() => <Pricing USER={USER} />} />
            <Route
              path="/free-trial"
              render={() => <FreeTrial USER={USER} />}
            />
            <Route path="/checkout" render={() => <Checkout USER={USER} />} />
            <Route path="/tender/:id?" render={() => <Tender USER={USER} />} />
            <Route
              path="/roundtrip/:id?"
              render={() => <Roundtrip USER={USER} />}
            />
            <Route
              path="/spotload/:id?"
              render={() => <Spotload USER={USER} />}
            />
            <Route
              path="/trucks/:id?"
              render={() => <EmptyTruck USER={USER} />}
            />
            <Route path="/blog/:id?" render={() => <BlogPage USER={USER} />} />
            <Route path="/about" render={() => <About USER={USER} />} />
            <Route path="/faq" render={() => <FAQPage USER={USER} />} />
            <Route path="/terms" render={() => <TermsPage USER={USER} />} />
            <Route path="/privacy" render={() => <PrivacyPage USER={USER} />} />
            <Route path="/dashboard" render={() => <Dashboard USER={USER} />} />
          </Switch>
          <ToastContainer newestOnTop />
          {!localStorage.hasOwnProperty("cookie_accept") && <CookieNotice />}
        </div>
      </Router>
    </CookiesProvider>
  );
};

export default App;
