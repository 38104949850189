import React from "react";
import Input from "../../components/Register/RegisterInput";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const RegisterStepTwo = (props) => {
  return (
    <div
      className={`Registration__part--PartSecond ${
        props.pagination === 2 && "active"
      }`}
    >
      <h3>Step 2. Your account</h3>
      {isMobile && <p>Fill form with your personal info</p>}
      <Input
        classes={`floating-label ${props?.errors?.firstName && "invalid"}`}
        name="firstName"
        type="text"
        value={props.inputs?.firstName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="First name"
        error={props.errors?.firstName && props.errors?.firstName?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.lastName && "invalid"}`}
        name="lastName"
        type="text"
        value={props.inputs?.lastName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Last name"
        error={props.errors?.lastName && props.errors?.lastName?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.email && "invalid"}`}
        name="email"
        type="text"
        value={props.inputs?.email || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Personal email address"
        error={props.errors?.email && props.errors?.email?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.phone && "invalid"}`}
        name="phone"
        type="text"
        value={props.inputs?.phone || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Personal phone"
        error={props.errors?.phone && props.errors?.phone?.msg}
      />
      <div className="Password">
        <Input
          classes={`floating-label ${props.errors?.password && "invalid"}`}
          name="password"
          type="password"
          value={props.inputs?.password || ""}
          onChange={(e) => props.handleInputChange(e)}
          label="Password"
          error={props.errors?.password && props.errors?.password?.msg}
        />
        <Input
          classes={`floating-label ${
            props.errors?.repeatPassword && "invalid"
          }`}
          name="repeatPassword"
          type="password"
          value={props.inputs?.repeatPassword || ""}
          onChange={(e) => props.handleInputChange(e)}
          label="Repeat password"
          error={
            props.errors?.repeatPassword && props.errors?.repeatPassword?.msg
          }
        />
      </div>
      <div className="inline-label termslinks">
        <label htmlFor="accept">
          <input
            type="checkbox"
            id="accept"
            name="accept"
            onChange={(e) => props.handleInputChange(e)}
          />
          I have read and agree with{" "}
          <span>
            <Link to="/terms" target="_blank">
              Terms & Conditions
            </Link>
          </span>{" "}
          and{" "}
          <span>
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>
          </span>
        </label>
        {props.errors?.accept && (
          <div className="error-message">{props.errors?.accept?.msg}</div>
        )}
      </div>
      <div className="formSubmit">
        <button
          type="button"
          className="next-step back"
          onClick={() => props.setPagination(1)}
        >
          Back
        </button>
        <div className="Submit__Wrapper">
          <button type="submit" className="next-step">
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterStepTwo;
