import React from "react";
import styled from "styled-components";

const Form = (props) => {
  return (
    <FormWrapper>
      <FormWrapperInner>
        <FormTitle>
          {props.icon}
          {props.title ? <h1>{props.title}</h1> : null}
        </FormTitle>
        <form onSubmit={(e) => props.onSubmit(e)} noValidate={props.noValidate}>
          {props.children}
        </form>
      </FormWrapperInner>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.5rem;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const FormWrapperInner = styled.div`
  background: #fff;
  @media (min-width: 768px) {
    padding-bottom: 0;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 2rem;
    box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
  }
`;

const FormTitle = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  h1 {
    font-family: "Inter";
    font-size: 32px;
    color: #262a68;
    margin: 0;
    margin-left: 1rem;
  }
  svg {
    width: 32px;
    height: 32px;
  }
`;

export default Form;
