import React from "react";
import Loader from "../UI/Loader";

const CompanyInfo = (props) => {
  // Show loader when company data is not ready to be shown

  if (!props.COMPANY_DATA) {
    return <Loader />;
  }

  return (
    <div className="Company_Info">
      <div className="Company_Info-block">
        <div className="item">
          <h4>Company Name:</h4>
          <h5>{props.COMPANY_DATA.companyName}</h5>
        </div>
        <div className="item">
          <h4>Company Type:</h4>
          <h5>{props.COMPANY_DATA.companyType}</h5>
        </div>
        <div className="item">
          <h4>VAT number:</h4>
          <h5>{props.COMPANY_DATA.vatNumber}</h5>
        </div>
        <div className="item item-space">
          <h4>Address:</h4>
          <h5>{props.COMPANY_DATA.address}</h5>
        </div>
        <div className="item">
          <h4>Website:</h4>
          <h5>{props.COMPANY_DATA.website}</h5>
        </div>
        <div className="item">
          <h4>Email:</h4>
          <h5>{props.COMPANY_DATA.companyEmail}</h5>
        </div>
        <div className="item">
          <h4>Phone:</h4>
          <h5>{props.COMPANY_DATA.companyPhone}</h5>
        </div>
      </div>
      <div className="Company_Info-block">
        <div className="item">
          <h4>Founded:</h4>
          <h5>{props.COMPANY_DATA.yearFounded}</h5>
        </div>
        <div className="item">
          <h4>Number of employees:</h4>
          <h5>{props.COMPANY_DATA.noOfEmployees}</h5>
        </div>
        <div className="description">
          <p>{props.COMPANY_DATA.description}</p>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
