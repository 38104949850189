import React from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  selectStyles,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";
import Select from "react-select";
import cargoData from "../../data/cargotypes.json";

const jsonCargo = JSON.parse(JSON.stringify(cargoData));
const freights = [];
Object.entries(jsonCargo).forEach(function (key) {
  freights.push({
    value: key[0],
    label: key[1],
  });
});

const CargoTypeSelect = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Select
            placeholder="Cargo type"
            options={freights}
            styles={selectStyles}
            value={props.inputs?.[props.name] || null}
            classNamePrefix="styled"
            onChange={(val) => props.onSelectChange(val, props.name)}
            className={props.error && "invalid"}
          />
          {props.error && (
            <ErrorWrapper>
              <span>{props.error.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

export default CargoTypeSelect;
