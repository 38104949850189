import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import SpotloadList from "./SpotloadList/SpotloadList";
import Filter from "./SpotloadFilter/Filter";
import SpotloadSheet from "./SpotloadSheet/SpotloadSheet";
import Modal from "../Modals/Modal";
import ModalHeader from "../Modals/Header";
import { withRouter } from "react-router-dom";
import Container from "../UI/Container";
import DashboardFooter from "../Dashboard/DashboardFooter";

const Spotload = (props) => {
  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [allSpotloads, setAllSpotloads] = useState([]);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const USER = useSelector((state) => state.dashboard.user?.data);

  // If user has requested a spotload via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    if (routeSheet && allSpotloads?.length > 0) {
      if (!allSpotloads.find((e) => e._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allSpotloads]);

  const handleSheetChange = useCallback((id) => {
    setSheetID(id);
  }, []);

  const handleModalClose = () => {
    setShowRouteModal(false);
    handleSheetChange(null);
  };

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener("scroll", function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener("scroll", scrollEvt);
    }
  }, []);
  return (
    <>
      <Filter fixed={filterFixed} filters={filters} setFilters={setFilters} />
      <Container>
        <SpotloadList
          filters={filters}
          sheetID={sheetID}
          setSheetID={handleSheetChange}
          setAllSpotloads={setAllSpotloads}
          clearFilters={() => setFilters({})}
          USER={USER}
        />
        {allSpotloads?.length > 0 && <DashboardFooter />}
      </Container>
      {allSpotloads && sheetID && (
        <SpotloadSheet
          spotloads={allSpotloads}
          id={sheetID}
          setSheetID={handleSheetChange}
          USER={USER}
        />
      )}
      {showRouteModal && (
        <Modal onClose={handleModalClose} small>
          <ModalHeader
            title="Spot freight not found"
            onClose={handleModalClose}
          />
          <h4>The spot freight you requested was not found.</h4>
          <h5>Here are some reasons to why:</h5>
          <ul>
            <li>
              The spot freight you requested was removed or is currently
              inactive.
            </li>
            <li>The link you accessed was broken or missing.</li>
          </ul>
        </Modal>
      )}
    </>
  );
};

export default withRouter(Spotload);
