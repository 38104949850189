import React, { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import logo from "../../images/logo.svg";
import "../../styles/Checkout/Checkout.css";
import { Link, withRouter } from "react-router-dom";
import da from "../../images/checkout-elem.svg";
import ds from "../../images/checkout-elems.svg";
import { isMobile } from "react-device-detect";
import client from "braintree-web/client";
import hostedFields from "braintree-web/hosted-fields";
import axios from "axios";
import { threeDSecure } from "braintree-web";
import { API_URL } from "../../constants";
import Container from "../UI/Container";

const cookies = new Cookies(),
  tcToken = cookies.get("tc_token");

const Checkout = (props) => {
  const [pathName, setPathName] = useState("/pricing");
  useEffect(() => {
    if (props.location.pathname === "/checkout") {
      setPathName("/checkout");
    }
  }, [props.location]);
  let billingString;
  if (props.billing === "12") {
    billingString = "annualy";
  } else if (props.billing === "6") {
    billingString = "every 6 months";
  } else if (props.billing === "1") {
    billingString = "monthly";
  }
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(props.price);
  const [totalPrice, setTotalPrice] = useState(
    parseFloat(props.billing * props.price).toFixed(2)
  );
  const [oldMonthlyPrice, setOldMonthlyPrice] = useState(props.price);
  const [oldTotalPrice, setOldTotalPrice] = useState(totalPrice);
  const [discount, setDiscount] = useState("");

  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState();
  const [discountBorder, setDiscountBorder] = useState();
  const [discountLoader, setDiscountLoader] = useState(false);
  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [nextPayment, setNextPayment] = useState();
  const [subscriptionId] = useState(props.subscriptionId);
  const totalPriceRef = useRef(totalPrice);
  const discountSumRef = useRef(0);
  const discountCodeRef = useRef("");

  //Current subscription info

  const [firstMpriceState] = useState(0);
  const [downgrade] = useState(false);

  // GET CURRENT SUBSCRIPTION

  const expirationDate = props.expirationDate;

  const [nextBilling] = useState(expirationDate);

  const [errorMessage, setErrorMessage] = useState(
    "Payment couldn't be processed. Please check if the information entered is correct."
  );

  // CALCULATE DISCOUNT
  const handleDiscountSubmit = (e) => {
    e.preventDefault();
    setDiscountBorder("");
    setDiscountLoader(true);

    axios
      .post("https://transconnector.eu/APIrequests/discountCode.php", {
        discountCode: discount,
        subscriptionID: props.subscriptionId,
        price: totalPrice,
      })
      .then(function (response) {
        setDiscountLoader(false);
        if (response.data.valid) {
          setHasDiscount(true);
          setDiscountBorder("valid");
          if (!hasDiscount) {
            // set old prices
            setOldMonthlyPrice(props.price);
            setOldTotalPrice(
              parseFloat(props.billing * props.price).toFixed(2)
            );

            //SET REFS TO USE IN SENDING DATA
            discountSumRef.current = totalPrice - response.data.discountedPrice;
            discountCodeRef.current = discount;
            // start calculating new prices
            const newPrice = response.data.discountedPrice;
            setTotalPrice(newPrice);
            // SET CURRENT PRICE REF
            totalPriceRef.current = newPrice;

            if (response.data.type === "%") {
              const newMonthlyPrice = parseFloat(
                newPrice / props.billing
              ).toFixed(2);
              setMonthlyPrice(newMonthlyPrice);
              setDiscountAmount("-" + response.data.discount + "%");
            } else if (response.data.type === "flat") {
              const newMonthlyPrice = parseFloat(
                newPrice / props.billing
              ).toFixed(2);
              setMonthlyPrice(newMonthlyPrice);
              setDiscountAmount("-€" + response.data.discount);
            }
          }
        } else {
          // SET BACK TO ORIGINAL STATES
          setDiscountBorder("invalid");
          setTotalPrice(oldTotalPrice);
          setMonthlyPrice(oldMonthlyPrice);
          setHasDiscount(false);
          totalPriceRef.current = oldTotalPrice;
          discountSumRef.current = 0;
          discountCodeRef.current = "";
        }
      });
  };

  // LOAD 3D SECURE TO DOM
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://js.braintreegateway.com/web/3.56.0/js/three-d-secure.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // LOAD BRAINTREE FIELDS AND PROCESS THE PAYMENT

  useEffect((props) => {
    const form = document.querySelector("#checkoutForm");
    const submit = document.querySelector("#pay");
    //GET CLIENT TOKEN FROM EXPRESS SERVER
    axios.get(`${API_URL}/clientToken`, {}).then(function (res) {
      const clientToken = res.data;
      client.create(
        {
          authorization: clientToken,
        },
        function (clientErr, clientInstance) {
          if (clientErr) {
            return;
          }
          threeDSecure.create(
            {
              version: 2,
              client: clientInstance,
            },
            function (threeDSecureErr, threeDSecure) {
              if (threeDSecureErr) {
                setLoader(false);
                setTransactionCompleted(false);
                return;
              }
              // Create a hostedFields component to initialize the form
              hostedFields.create(
                {
                  client: clientInstance,
                  styles: {
                    input: {
                      height: "58px",
                      "font-size": "14px",
                      "font-family": "Inter, sans-serif",
                    },
                    "input.invalid": {
                      color: "red",
                    },
                    "input.valid": {
                      color: "green",
                    },
                    "::-webkit-input-placeholder": {
                      color: "#9a9a9a",
                    },
                    ":-moz-placeholder": {
                      color: "#9a9a9a",
                    },
                    "::-moz-placeholder": {
                      color: "#9a9a9a",
                    },
                    ":-ms-input-placeholder": {
                      color: "#9a9a9a",
                    },
                    ":focus": {
                      transition: "0.25s all ease",
                      outline: "none",
                      "box-shadow": "0 0 0 2px #a0d1fa",
                    },
                  },
                  fields: {
                    number: {
                      selector: "#card-number",
                    },
                    cvv: {
                      selector: "#cvv",
                    },
                    expirationDate: {
                      selector: "#expiration-date",
                    },
                  },
                },
                function (hostedFieldsErr, instance) {
                  if (hostedFieldsErr) {
                    setLoader(false);
                    setTransactionCompleted(false);
                    return;
                  }
                  instance.on("empty", function (event) {
                    var field = event.fields[event.emittedBy];
                    field.container.parentElement.classList.remove("not-empty");
                  });
                  instance.on("notEmpty", function (event) {
                    var field = event.fields[event.emittedBy];
                    field.container.parentElement.classList += " not-empty";
                  });
                  submit.removeAttribute("disabled");
                  form.addEventListener(
                    "submit",
                    function (event) {
                      event.preventDefault();
                      const cardName = document.getElementById("cardName")
                        .value;
                      setLoader(true);
                      setError(false);

                      // When the user clicks on the 'Submit payment' button this code will send the
                      // encrypted payment information in a variable called a payment method nonce
                      instance.tokenize(function (tokenizeErr, payload) {
                        setError(false);
                        if (tokenizeErr) {
                          setLoader(false);
                          setErrorMessage(
                            "Payment couldn't be processed. Please check if the information entered is correct."
                          );
                          setError(true);
                          return;
                        }
                        //GET USERDATA
                        const threeDSecureParameters = {
                          amount: totalPrice,
                          nonce: payload.nonce,
                          onLookupComplete: function (data, next) {
                            next();
                          },
                        };
                        //3D Secure Card verification

                        threeDSecure.verifyCard(
                          threeDSecureParameters,
                          function (err, res) {
                            if (err) {
                              setTransactionCompleted(false);
                              setLoader(false);
                              setError(true);
                              setErrorMessage(
                                "Authentication failed, please try another payment method"
                              );
                              return;
                            }
                            if (res.liabilityShifted === false) {
                              setTransactionCompleted(false);
                              setLoader(false);
                              setError(true);
                              setErrorMessage(
                                "Authentication failed, please try another payment method"
                              );
                              return;
                            }
                            const threeDnonce = res.nonce;
                            axios
                              .post(
                                `${API_URL}/addSubscription`,
                                {
                                  paymentMethodNonce: threeDnonce,
                                  planId: subscriptionId,
                                  cardholderName: cardName,
                                  price: totalPriceRef.current,
                                },
                                {
                                  headers: {
                                    Authorization: "Bearer " + tcToken,
                                  },
                                }
                              )
                              .then((response) => {
                                const days = String(
                                  response.data.expirationDate
                                ).split("-");
                                const dateDay = String(days[2]).split("T");
                                const formatedDate =
                                  dateDay[0] + "." + days[1] + "." + days[0];
                                setNextPayment(formatedDate);
                                setTransactionCompleted(true);
                                setLoader(false);
                              });
                          }
                        );
                      });
                    },
                    false
                  );
                }
              );
            }
          );
        }
      );
    });
  }, []);
  return (
    <div className="Checkout">
      <div className="Checkout__Navbar">
        <Container>
          <header>
            <Link to="/" className="logo">
              <img src={logo} alt="TransConnector" />
            </Link>
          </header>
        </Container>
      </div>
      <div className="Checkout__Steps">
        {!transactionCompleted ? (
          <>
            <Link to={pathName}>
              <div className="Step--completed">{check}</div>
              <div className="Step completed">
                <h4>Select Plan</h4>
              </div>
            </Link>
            <div className="Step--arrow">{arrow}</div>
          </>
        ) : (
          <div className="Step--completed">{check}</div>
        )}
        <div
          className={
            !transactionCompleted ? "Step step-current" : "Step completed"
          }
        >
          <h4>Checkout</h4>
        </div>
        <div className={!transactionCompleted ? "Step not-completed" : "Step"}>
          {arrow}
        </div>
        <div
          className={
            !transactionCompleted ? "Step not-completed" : "Step step-current"
          }
        >
          <h4>Summary</h4>
        </div>
        {!isMobile ? (
          <div className="Checkout--da">
            <img src={da} alt="" />
          </div>
        ) : null}
      </div>
      <div className="Content">
        <div className="Summary">
          <div className="Summary--inner">
            {!transactionCompleted ? (
              <>
                <div className="Back">
                  <Link to={pathName}>{"< "}Back</Link>
                </div>
                <h3>Checkout</h3>
              </>
            ) : (
              <h3>Your subscription</h3>
            )}
            <div className="Summary--list">
              <h5>Order Summary</h5>
              <div className="Item">
                <h5>Plan:</h5>
                <h4>{props.plan}</h4>
              </div>
              <div className="Item">
                <h5>Duration:</h5>
                <h4>{props.billing} months</h4>
              </div>
              {!transactionCompleted ? (
                <div></div>
              ) : (
                <div className="Item">
                  <h5>Next payment:</h5>
                  <h4> {nextPayment} </h4>
                </div>
              )}
              {!transactionCompleted ? (
                <div className={`Item ${hasDiscount}`}>
                  <h5>Monthly price:</h5>
                  <h4>
                    {hasDiscount ? <span>€{oldMonthlyPrice}</span> : null}€
                    {monthlyPrice}
                  </h4>
                </div>
              ) : null}
              <div className="Total-wrapper">
                {hasDiscount ? (
                  <div className="Item">
                    <h5>Discount:</h5>
                    <h4>{discountAmount}</h4>
                  </div>
                ) : null}
                <div className={`Item ${hasDiscount}`}>
                  {!transactionCompleted ? <h5>Total:</h5> : <h5>Paid:</h5>}
                  <h4>
                    {hasDiscount ? <span>€{oldTotalPrice}</span> : null}€
                    {totalPrice}
                  </h4>
                </div>
                {firstMpriceState > 0 ? (
                  <div className="Item">
                    <h5>Total today:</h5>
                    <h4>€{firstMpriceState}</h4>
                  </div>
                ) : (
                  <div></div>
                )}
                {downgrade === true ? (
                  <div className="Item">
                    <h5>Next payment:</h5>
                    <h4>{nextBilling}</h4>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <p>Renews {billingString}, cancel anytime.</p>
          </div>
          {!isMobile ? (
            <div className="Summary--ds">
              <img src={ds} alt="" />
            </div>
          ) : null}
        </div>
        <div className="Billing">
          {!transactionCompleted ? (
            <>
              <div className="Discount">
                <form onSubmit={handleDiscountSubmit}>
                  <div className={`floating-label ${discountBorder}`}>
                    <input
                      type="text"
                      placeholder=" "
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                    <label>Discount code</label>
                  </div>
                  <div className={`Discount__Apply ${discountLoader}`}>
                    <input type="submit" value="Apply" />
                    <div className={`loader ${discountLoader}`}></div>
                  </div>
                </form>
              </div>
              <h2>Billing Details</h2>
              <form method="post" id="checkoutForm">
                <div className={`Payment__Error animated ${error}`}>
                  <p>{errorMessage}</p>
                </div>
                <div className="floating-label">
                  <input type="text" placeholder=" " id="cardName" />
                  <label>Cardholder Name</label>
                </div>
                <div className="floating-label">
                  <div id="card-number"></div>
                  <label htmlFor="card-number">Card number</label>
                </div>
                <div className="inline-label">
                  <div className="floating-label">
                    <div id="cvv"></div>
                    <label htmlFor="cvv">CVV</label>
                  </div>
                  <div className="floating-label">
                    <div id="expiration-date"></div>
                    <label htmlFor="expiration-date">Expiration Date</label>
                  </div>
                </div>
                <div id="checkout-message"></div>
                <div className="Submit">
                  <input
                    className={`${loader}`}
                    id="pay"
                    type="submit"
                    value="Pay"
                  />
                  <div className={`loader ${loader}`}></div>
                </div>
              </form>
            </>
          ) : (
            <div className="Success">
              <h3>Thank you for your subscription!</h3>
              <h4>You can now enjoy following features on TransConnector:</h4>
              <ul>
                <li>{check}Send unlimited messages</li>
                {props.plan === "Premium" ? (
                  <li>{check}Create tenders</li>
                ) : (
                  <li>{check}Create tenders and roundtrips</li>
                )}
                <li>{check}Unlimited access to all offers</li>
                <li>{check}Access to companies directory</li>
              </ul>
              <Link to="/dashboard">Continue</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.57"
    height="11.086"
    viewBox="0 0 20.57 11.086"
  >
    <g transform="translate(0 0.707)">
      <line
        x2="18.77"
        transform="translate(0 4.898)"
        fill="none"
        stroke="#262a68"
        strokeWidth="2"
      />
      <path
        d="M-15083.424-18445.023l4.834,4.836-4.834,4.836"
        transform="translate(15097.745 18445.023)"
        fill="none"
        stroke="#262a68"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const check = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.418"
    height="14.348"
    viewBox="0 0 20.418 14.348"
  >
    <path
      d="M-16949.34-18415.387l7.131,7.131,11.166-11.166"
      transform="translate(16950.4 18420.482)"
      fill="none"
      stroke="#00b27e"
      strokeWidth="3"
    />
  </svg>
);

export default withRouter(Checkout);
