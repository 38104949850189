import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer/Footer";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import Privacy from "./static/Privacy";
import ScrollToTopOnMount from "../helpers/ScrollToTop";

const PrivacyPage = ({ USER }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <Privacy />
      <Footer />
      {USER && <LeftDrawer small />}
    </>
  );
};

export default PrivacyPage;
