import React from "react";
import SheetBlock from "../../PageWithList/Sheet/SheetBlock";
import {
  ShowWrapper,
  SheetItem,
  SheetItemInline,
  SheetItemIconsWrapper,
  SheetItemWithIcon,
} from "../../PageWithList/Sheet/Styles";
import { Link } from "react-router-dom";
import uuid from "uuid";
import DetailsSpecialRequests from "../../PageWithList/Sheet/DetailsSpecialRequests";
import moment from "moment";

const Details = (props) => {
  const {
    author,
    loading,
    unloading,
    temperature,
    freight,
    typeOfLoad,
    truckType,
    extraInfo,
    company,
    specialFeatures,
  } = props.truck;

  return (
    <ShowWrapper show={props.show}>
      <SheetItemIconsWrapper>
        {author?.firstName && (
          <SheetItemWithIcon>
            <Link to={`/dashboard/company/${company?._id}`}>
              {userIcon} {author?.firstName} {author?.lastName}
            </Link>
          </SheetItemWithIcon>
        )}
        {author?.email && (
          <SheetItemWithIcon>
            <a href={`mailto:${author?.email}`}>
              {emailIcon} {author?.email}
            </a>
          </SheetItemWithIcon>
        )}
        {author?.phone && (
          <SheetItemWithIcon>
            <a href={`tel:${author?.phone}`}>
              {phoneIcon} {author?.phone}
            </a>
          </SheetItemWithIcon>
        )}
      </SheetItemIconsWrapper>
      <SheetBlock label="Route">
        <SheetItem>
          <h3>
            From - {moment(loading.date.start).format("DD.MM.YY")} &gt;{" "}
            {moment(loading.date.end).format("DD.MM.YY")}
          </h3>
          <h4>
            {loading.locations[0].locationName}{" "}
            {loading.locations[0]?.radius &&
              `(radius ${loading.locations[0]?.radius}km)`}
          </h4>
        </SheetItem>
        <SheetItem>
          <h3>To</h3>
          {unloading.locations &&
            unloading.locations.map((unload) => {
              return (
                <h4 key={uuid()}>
                  {unload.locationName}{" "}
                  {unload?.radius && `(radius ${unload.radius}km)`}
                </h4>
              );
            })}
        </SheetItem>
      </SheetBlock>
      <SheetBlock label="Truck">
        <SheetItem>
          <h3>Truck type</h3>
          <h4>
            {" "}
            {truckType[0].label}{" "}
            {truckType[0].value === "Refrigerator" &&
              temperature &&
              `(${temperature}°C)`}
          </h4>
        </SheetItem>
        <SheetItem>
          <h3>Type of load</h3>
          <h4>{typeOfLoad}</h4>
        </SheetItem>
        <SheetItemInline inlineRow>
          {freight.weight && (
            <SheetItem>
              <h3>Weight</h3>
              <h4>
                {freight.weight}
                {freight.weight <= 101 ? "000 kg" : " kg"}
              </h4>
            </SheetItem>
          )}
          {freight.loadingMeters && (
            <SheetItem>
              <h3>Loading meters</h3>
              <h4>{freight.loadingMeters} m</h4>
            </SheetItem>
          )}
          {freight?.length && (
            <SheetItem>
              <h3>Length</h3>
              <h4>{freight?.length} cm</h4>
            </SheetItem>
          )}
          {freight.width && (
            <SheetItem>
              <h3>Width</h3>
              <h4>{freight.width} cm</h4>
            </SheetItem>
          )}
          {freight.height && (
            <SheetItem>
              <h3>Height</h3>
              <h4>{freight.height} cm</h4>
            </SheetItem>
          )}
          {freight.volume && (
            <SheetItem>
              <h3>Volume</h3>
              <h4>{freight.volume} m</h4>
            </SheetItem>
          )}
        </SheetItemInline>
        <DetailsSpecialRequests
          renameWaste
          title="Special features"
          specialRequests={specialFeatures}
        />
      </SheetBlock>
      {extraInfo && (
        <SheetBlock label="Extra info">
          <SheetItem>
            <h4>{extraInfo}</h4>
          </SheetItem>
        </SheetBlock>
      )}
    </ShowWrapper>
  );
};

const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
  >
    <g transform="translate(-5.5 -4)">
      <path
        d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2"
        transform="translate(0 -6)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M20,8.5a4,4,0,1,1-4-4A4,4,0,0,1,20,8.5Z"
        transform="translate(-2)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const emailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.406"
    height="13.062"
    viewBox="0 0 17.406 13.062"
  >
    <g transform="translate(-2.297 -5.5)">
      <path
        d="M4.6,6H17.4A1.56,1.56,0,0,1,19,7.508v9.046a1.56,1.56,0,0,1-1.6,1.508H4.6A1.56,1.56,0,0,1,3,16.554V7.508A1.56,1.56,0,0,1,4.6,6Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M19,9l-8,6.428L3,9"
        transform="translate(0 -1.163)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const phoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.2"
    height="21.286"
    viewBox="0 0 15.2 21.286"
  >
    <g transform="translate(-7.5 -2.5)">
      <path
        d="M9.529,3H19.671A2.029,2.029,0,0,1,21.7,5.029V21.257a2.029,2.029,0,0,1-2.029,2.029H9.529A2.029,2.029,0,0,1,7.5,21.257V5.029A2.029,2.029,0,0,1,9.529,3Z"
        transform="translate(0.5 0)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.381"
        transform="translate(11 20.015)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Details;
