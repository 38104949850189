import { SET_OFFERS, UPDATE_OFFER } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const getOffers = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .get(`${API_URL}/myOffers`, config)
      .then((res) => {
        dispatch({
          type: SET_OFFERS,
          payload: res.data,
        });
      })
      .catch((err) => {
        // TODO: SET TOAST
      });
  };
};

export const changeStatus = (id, status, type, array) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .put(
        `${API_URL}/updateOffer`,
        {
          _id: id,
          newStatus: status,
          type: type,
        },
        config
      )
      .then((res) => {
        dispatch({
          type: UPDATE_OFFER,
          payload: res.data,
          array: array,
        });
      });
  };
};

export const removeOffer = (id, type) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.delete(`${API_URL}/offer`, {
      data: { _id: id, type: type },
      ...config,
    });
  };
};
