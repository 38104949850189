import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyTruckListHeader from "./EmptyTruckListHeader";
import EmptyTruckListItem from "./EmptyTruckListItem";
import Loader from "../../Dashboard/UI/Loader";
import {
  filterTruckTypes,
  filterLocations,
} from "../../../helpers/ListFiltering";
import UserNotice from "../../UserNotice/UserNotice";
import welcomeNoResults from "../../../assets/welcome/noResults.svg";
import { getEmptyTruckFilters } from "../../../store/actions/app/emptytruck";
import ReviewModal from "../../Modals/ReviewModal/ReviewModal";
import AddReview from "../../Modals/ReviewModal/AddReview";

const EmptyTruckListWrapper = React.memo((props) => {
  const { USER } = props;
  const [trucks, setTrucks] = useState([]);
  const [hasSetAllTrucks, setHasSetAllTrucks] = useState(false);
  const [sorting] = useState({
    sortBy: "5",
    order: "ASC",
  });
  const [reviewModal, setReviewModal] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Truck list filtering
  const {
    loadingLocation,
    loadingRadius,
    date,
    unloadingLocation,
    unloadingRadius,
    truckType,
    typeOfLoad,
    specialFeatures,
  } = props.filters;
  const { setAllTrucks } = props;

  useEffect(() => {
    if (props.filters) {
      // Did cancel is used for canceling a request when the user requests another payload while the previous one is still loading
      let didCancel = false;
      // Rearrange trucktype and locations structure for filtering
      const truckTypes = filterTruckTypes(truckType);
      const specialFtrs = filterTruckTypes(specialFeatures);
      const params = filterLocations(
        loadingLocation,
        loadingRadius,
        unloadingLocation,
        unloadingRadius
      );

      dispatch(
        getEmptyTruckFilters({
          loadingLocation: params.loading.value,
          loadingRadius: params.loading.radius,
          unloadingLocation: params.unloading.value,
          unloadingRadius: params.unloading.radius,
          startDate: date?.startDate,
          endDate: date?.endDate,
          truckType: truckTypes,
          typeOfLoad: typeOfLoad,
          specialFeatures: specialFtrs,
          /*           sortby: sorting.sortBy,
          order: sorting.order, */
          //loadingMeters: props.filters?.loadingMeters
        })
      )
        .then((res) => {
          if (!didCancel) {
            const result = res?.data;
            setTrucks(result);
            if (!hasSetAllTrucks) {
              setAllTrucks(result);
              setHasSetAllTrucks(true);
            }
          }
        })
        .catch((err) => console.log(err));
      return () => (didCancel = true);
    }
  }, [
    props.filters,
    loadingLocation,
    loadingRadius,
    date,
    unloadingLocation,
    unloadingRadius,
    truckType,
    typeOfLoad,
    sorting,
  ]);

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  // Add Sheet ID to localstorage to display viewed trucks
  useEffect(() => {
    if (props.sheetID && USER && !isExpired) {
      const viewedTrucks = localStorage.hasOwnProperty("viewedTrucks")
        ? JSON.parse(localStorage.getItem("viewedTrucks"))
        : [];
      if (!viewedTrucks.includes(props.sheetID)) {
        viewedTrucks.push(props.sheetID);
        localStorage.setItem("viewedTrucks", JSON.stringify(viewedTrucks));
      }
    }
  }, [props.sheetID, USER, isExpired]);

  return (
    <EmptyTruckListHeader USER={USER}>
      {trucks?.length > 0
        ? trucks?.map((truck) => {
            return (
              <EmptyTruckListItem
                USER={USER}
                key={truck._id}
                truck={truck}
                sheetID={props.sheetID === truck._id ? props.sheetID : null}
                setSheetID={props.setSheetID}
                viewed={
                  localStorage.hasOwnProperty("viewedTrucks") &&
                  localStorage.getItem("viewedTrucks").includes(truck._id)
                }
                setReviewModal={setReviewModal}
                location={
                  props.filters?.loading?.value ||
                  props.filters?.unloading?.value
                    ? {
                        loading: props.filters?.loading?.value,
                        unloading: props.filters?.unloading?.value,
                      }
                    : null
                }
              />
            );
          })
        : trucks && trucks?.data !== null && <Loader />}
      {(!trucks || trucks?.data === null) && (
        <UserNotice
          image={welcomeNoResults}
          title="Unfortunately no empty trucks were found. Try again with different filters or clear all filters with the button below."
          buttons={[
            {
              name: "Clear filters",
              isButton: true,
              onClick: props.clearFilters,
            },
          ]}
        />
      )}
      {reviewModal && (
        <ReviewModal
          showHeader
          fetchReviews
          id={reviewModal}
          onClose={() => setReviewModal(null)}
          onClick={() => {
            setAddReviewModal(reviewModal);
            setReviewModal(null);
          }}
        />
      )}
      {!reviewModal && addReviewModal && (
        <AddReview
          name={reviewModal?.company?.companyName}
          id={addReviewModal}
          onClose={() => setAddReviewModal(false)}
        />
      )}
    </EmptyTruckListHeader>
  );
});

export default EmptyTruckListWrapper;
