import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { isOnlyMobile } from "react-device-detect";
import { API_URL } from "../../../constants/";
import Container from "../../UI/Container";
import Post from "./Post";

import { Wrapper, List } from "./Styles";

const Blog = () => {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if (!posts) {
      axios.get(`${API_URL}/cms/blog`).then((res) => setPosts(res.data));
    }
  }, [posts]);

  if (!posts) return null;
  return (
    <Container gutter="medium">
      <Wrapper>
        <h2>Blog</h2>
        <List>
          {posts?.slice(0, isOnlyMobile ? 2 : 4)?.map((post) => {
            const { _id, title, shortContent, imageUrl, createdAt } = post;
            return (
              <Post
                key={_id}
                to={`/blog/${_id}`}
                title={title}
                content={shortContent}
                img={imageUrl}
                date={moment(createdAt).format("DD MMMM YYYY")}
              />
            );
          })}
        </List>
      </Wrapper>
    </Container>
  );
};

export default Blog;
