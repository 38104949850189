import React from "react";
import RadioGroup from "../CreateForm/RadioGroup";
import RadioInput from "../CreateForm/RadioInput";
import NumberGroup from "../CreateForm/NumberGroup";
import NumberInput from "../CreateForm/NumberInput";
import AdditionalRequirements from "../CreateForm/AdditionalRequirements";
import CheckboxGroup from "../CreateForm/CheckboxGroup";
import Checkbox from "../CreateForm/Checkbox";
import CargoTypeSelect from "../CreateForm/CargoTypeSelect";
import Textarea from "../CreateForm/Textarea";
import { Wrapper, InlineWrapper } from "../CreateForm/Styles";

const SpotloadCreatorStepTwo = (props) => {
  return (
    <Wrapper>
      <InlineWrapper>
        <RadioGroup label="Type of load" error={props.errors?.typeOfLoad}>
          <InlineWrapper>
            <RadioInput
              name="typeOfLoad"
              value="FTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
            <RadioInput
              name="typeOfLoad"
              value="LTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
          </InlineWrapper>
        </RadioGroup>
        <NumberGroup>
          <InlineWrapper>
            <NumberInput
              label="Weight"
              min={0}
              name="weight"
              placeholder="Kg"
              inputs={props.inputs}
              value={props.inputs?.freight?.weight || ""}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.weight}
            />
            <NumberInput
              label="Loading meters"
              min={0}
              name="loadingMeters"
              placeholder="Meter"
              inputs={props.inputs}
              value={props.inputs?.freight?.loadingMeters || ""}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.loadingMeters}
            />
          </InlineWrapper>
          <AdditionalRequirements
            onInputChange={props.onInputChange}
            errors={props.errors}
            inputs={props.inputs}
          />
        </NumberGroup>
      </InlineWrapper>
      <CheckboxGroup
        label="Special requests"
        error={props.errors?.specialRequests}
      >
        <Checkbox
          label="ADR"
          value="ADR"
          name="specialRequests"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialRequests"
        />
        <Checkbox
          label="Euro pallet exchange"
          value="euroPalletExchange"
          name="specialRequests"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialRequests"
        />
        <Checkbox
          label="TIR Cable"
          value="TIRCable"
          name="specialRequests"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialRequests"
        />
        <Checkbox
          label="Live tracking"
          value="liveTracking"
          name="specialRequests"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialRequests"
        />
        <Checkbox
          label="Non stackable"
          value="nonStackable"
          name="specialRequests"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialRequests"
        />
        <Checkbox
          label="Waste load"
          value="wasteLoad"
          name="specialRequests"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialRequests"
        />
      </CheckboxGroup>
      <CargoTypeSelect
        label="Cargo type"
        name="cargoType"
        inputs={props.inputs}
        onSelectChange={props.onSelectChange}
        error={props.errors?.cargoType}
      />
      <Textarea
        label="Extra info"
        name="extraInfo"
        inputs={props.inputs}
        onInputChange={props.onInputChange}
        error={props.errors?.extraInfo}
      />
    </Wrapper>
  );
};

export default SpotloadCreatorStepTwo;
