import styled from "styled-components";

export const InputInlineWrapper = styled.div`
  max-width: 600px;
  span {
    margin-bottom: 2rem;
  }
  @media (min-width: 1200px) {
    display: inline-flex;
    width: 100%;
    align-items: center;
    span {
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
    }
  }
`;
