import React from "react";
import { withRouter } from "react-router-dom";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";
import formatLocationName from "../../../helpers/LocationName"

const TendersTable = (props) => {
  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.5">Loading</TableHeaderItem>
          <TableHeaderItem flex="0.5">Unloading</TableHeaderItem>
          <TableHeaderItem flex="0.4">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.2">No of trips</TableHeaderItem>
          <TableHeaderItem flex="0.2">Target price</TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.tenders.map((tender) => {
            const truckTypes = tender?.truckType
              ?.map((element) => {
                if (element.value === "Refrigerator" && tender.temperature) {
                  return `${element.value} (${tender.temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ");
            return (
              <TableRow
                key={tender._id}
                onClick={() =>
                  handleRedirect("/tender", {
                    sheet: tender._id,
                  })
                }
              >
                <TableRowItem
                  rowName="Loading"
                  image={{
                    url: tender.loading[0].flagUrl,
                    alt: tender.loading[0].country,
                  }}
                  title={{
                    name: formatLocationName(tender.loading[0].locationName),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Unloading"
                  image={{
                    url: tender.unloading[0].flagUrl,
                    alt: tender.unloading[0].country,
                  }}
                  title={{
                    name: formatLocationName(tender.unloading[0].locationName),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truckTypes,
                  }}
                  flex="0.4"
                >
                  <span>
                    {`${tender.Freight.cargoType}`}{" "}
                    {tender.Freight.dimensions.weight &&
                      ` · ${tender.Freight.dimensions.weight}t`}{" "}
                    {tender.Freight.dimensions.loadingMeters &&
                      ` · LDM: ${tender.Freight.dimensions.loadingMeters}m`}
                  </span>
                </TableRowItem>
                <TableRowItem
                  rowName="No of trips"
                  title={{
                    name: `${tender.shipments.numberOfShipments} x
                    ${tender.shipments.periodicity.toLowerCase()}`,
                  }}
                  flex="0.2"
                />
                <TableRowItem
                  rowName="Target price"
                  title={{
                    name:
                      tender.targetPrice && tender.targetPrice !== "0"
                        ? `${tender.targetPrice}€`
                        : "N/A",
                  }}
                  flex="0.2"
                />
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
    </>
  );
};

export default withRouter(TendersTable);
