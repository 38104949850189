import { SET_TENDERS } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

const token = getToken();
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const getTenders = () => {
  return (dispatch) => {
    axios
      .get(`${API_URL}/getTenders`, config)
      .then((res) => {
        dispatch({ type: SET_TENDERS, payload: res.data });
        //dispatch(tendersFetchSuccess(res.data.reverse()));
      })
      .catch((err) => {
        // TODO: SET ERROR
        //dispatch(tendersFetchFailure());
      });
  };
};

export const getFilterTenders = (params) => {
  return () => {
    return axios.get(`${API_URL}/getTenders`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: { ...params },
    });
  };
};

export const getSingleTender = (id) => {
  return () => {
    return axios.get(`${API_URL}/getTenderByID?id=${id}`, config);
  };
};

export const validateTender = (method, url, data) => {
  return () => {
    return axios({
      method: method,
      url: url,
      data: data,
      ...config,
    });
  };
};

export const createTender = (method, url, data) => {
  return () => {
    return axios({
      method: method,
      url: url,
      data: data,
      ...config,
    });
  };
};
