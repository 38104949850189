import React from "react";
import { ActionButtonsWrapper, ActionButtonsInnerWrapper } from "./Styles";

const ActionButtons = (props) => {
  return (
    <ActionButtonsWrapper iOS={props.iOS}>
      <ActionButtonsInnerWrapper>{props.children}</ActionButtonsInnerWrapper>
    </ActionButtonsWrapper>
  );
};

export default ActionButtons;
