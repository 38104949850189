import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { Wrapper, Inner, Info, InfoDropdown } from "./Styles";
import { getUnread } from "../../../store/actions/misc";

const Navbar = () => {
  const USER = useSelector((state) => state.dashboard?.user?.data);
  const dispatch = useDispatch();

  // Get data for message count
  useEffect(() => {
    const cookies = new Cookies(),
      token = cookies.get("tc_token");
    if (USER) {
      dispatch(getUnread(token));
    }
  }, [USER]);
  return (
    <Wrapper>
      <Inner>
        <NavLink to="/spotload">Load exchange</NavLink>
        <NavLink to="/roundtrip">Roundtrip</NavLink>
        <NavLink to="/tender">Tender</NavLink>
        <NavLink to="/trucks">Empty trucks</NavLink>

        <Info>
          <NavLink to="#!">Info</NavLink>
          <InfoDropdown>
            <ul>
              <li>
                <NavLink to="/about">About</NavLink>
              </li>
              <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/free-trial">Free trial</NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li>
                <NavLink to="/terms">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy">Privacy Policy</NavLink>
              </li>
            </ul>
          </InfoDropdown>
        </Info>
      </Inner>
    </Wrapper>
  );
};

export default Navbar;
