import {
  SET_USER,
  UPDATE_USER,
  ADD_FAVOURITE,
  REMOVE_FAVOURITE,
  UPDATE_USER_NOTIFICATION,
  SET_USER_SUBSCRIPTION,
} from "../../actionTypes";

const initialState = {
  data: null,
  subscription: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        data: action.payload,
      };
    case SET_USER_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case UPDATE_USER_NOTIFICATION:
      const newNotificationIndex = state.data.notifications.findIndex(
        (notification) => notification._id === action.payload._id
      );
      const newNotifications = state.data.notifications.map(
        (notification, index) => {
          if (index === newNotificationIndex) {
            return action.payload;
          }
          return notification;
        }
      );
      return {
        ...state,
        data: {
          ...state.data,
          notifications: newNotifications,
        },
      };
    case ADD_FAVOURITE:
      return {
        ...state,
        data: {
          ...state.data,
          favourites: [...state.data.favourites, action.payload],
        },
      };
    case REMOVE_FAVOURITE:
      return {
        ...state,
        data: {
          ...state.data,
          favourites: state.data.favourites.filter(
            (favourite) => favourite.favouriteId !== action.id
          ),
        },
      };
    default:
      return state;
  }
};

export default reducer;
