import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  margin: 3rem 0;
  @media (min-width: 992px) {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
  @media (min-width: 1200px) {
    margin-top: 6rem;
    margin-bottom: 15rem;
  }
  @media (min-width: 1440px) {
    margin-bottom: 16rem;
  }
`;
export const ItemWrapper = styled.div`
  max-width: 400px;
  background: #fff;
  box-shadow: 0 3px 30px rgba(151, 198, 239, 0.16);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  p {
    line-height: 1.6;
    color: #4a4a4a;
    font-size: 12px;
    padding: 0 0.5rem;
  }
  @media (min-width: 992px) {
    p {
      font-size: 14px;
    }
  }
`;
export const List = styled.div`
  @media (min-width: 768px) {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    ${ItemWrapper} {
      width: 50%;
    }
    ${ItemWrapper}:nth-child(odd) {
      width: calc(50% - 2rem);
      margin-right: 1rem;
    }
  }
  @media (min-width: 1200px) {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    ${ItemWrapper} {
      height: min-content;
    }
    ${ItemWrapper}, ${ItemWrapper}:nth-child(odd) {
      width: 23%;
      margin-right: 1rem;
    }
    ${ItemWrapper}:last-child {
      margin-right: 0;
    }
    ${ItemWrapper}:nth-child(2) {
      transform: translateY(50%);
    }
    ${ItemWrapper}:nth-child(3) {
      transform: translateY(25%);
    }
    ${ItemWrapper}:nth-child(4) {
      transform: translateY(75%);
    }
  }
  @media (min-width: 1440px) {
    max-width: 1400px;
    ${ItemWrapper}, ${ItemWrapper}:nth-child(odd) {
      margin-right: 2rem;
    }
    ${ItemWrapper}:last-child {
      margin-right: 0;
    }
  }
  @media (min-width: 1740px) {
    max-width: 1660px;
  }
`;
export const ItemFooter = styled.div`
  margin: 0.5rem 0;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  img {
    max-width: 100px;
    max-height: 40px;
    margin-right: 0.5rem;
  }
  h4 {
    font-size: 12px;
    color: #262a68;
    line-height: 1.6;
    margin: 0;
    font-weight: 500;
  }
  @media (min-width: 992px) {
    h4 {
      font-size: 14px;
    }
  }
`;

export const Poster = styled.div`
  display: flex;
  flex-direction: column;
`;
