import React from "react";
import { SheetWrapper, SheetInner } from "./Styles";

const Sheet = (props) => {
  return (
    <SheetWrapper {...props}>
      <SheetInner>{props.children}</SheetInner>
    </SheetWrapper>
  );
};

export default Sheet;
