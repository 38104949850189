import React from "react";
import TrucktypeSelect from "../../CreateForm/TrucktypeSelect";
import Input from "../../CreateForm/Input";
import { Wrapper, InputWrapper } from "../../CreateForm/Styles";
import CheckboxGroup from "../../CreateForm/CheckboxGroup";
import Checkbox from "../../CreateForm/Checkbox";
import CountrySelect from "../../CreateForm/CountrySelect";
import StandardQuill from "../../UI/StandardQuill/";

const RoundtripCreatorStepOne = (props) => {
  return (
    <Wrapper>
      <InputWrapper>
        <Input
          label="Name"
          name="name"
          placeholder="Roundtrip name"
          value={props.inputs?.name || ""}
          onInputChange={props.onInputChange}
          error={props.errors?.name}
        />
        <StandardQuill
          label="Route description"
          name="routeDescription"
          placeholder="Please describe your route"
          value={props.inputs?.routeDescription}
          onInputChange={props.onInputChange}
          error={props.errors?.routeDescription}
        />
        <CountrySelect
          label="Countries"
          type="countries"
          placeholder="Select countries"
          inputs={props.inputs}
          onSelectChange={props.onSelectChange}
          error={props.errors?.countries}
        />
        <TrucktypeSelect
          label="Truck types"
          name="truckTypes"
          onSelectChange={(value, name) =>
            props.onSelectChange(value, name, "trucks")
          }
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "trucks")
          }
          inputs={props.inputs?.trucks}
          errors={props.errors}
        />
        <StandardQuill
          label="Truck info"
          name="additionalInfo"
          placeholder="Additional info for trucks"
          value={props.inputs?.trucks?.additionalInfo}
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "trucks")
          }
          error={props.errors?.trucks?.additionalInfo}
        />
        <CheckboxGroup label="Trailer rent" error={props.errors?.trailerRent}>
          <Checkbox
            label="Trailer rent"
            name="available"
            value="available"
            inputs={props.inputs?.trailerRent}
            onInputChange={(value, name) =>
              props.onInputChange(value, name, "trailerRent")
            }
          />
        </CheckboxGroup>
        {props.inputs?.trailerRent?.available && (
          <StandardQuill
            label="Comments"
            name="addBenefits"
            placeholder="Additional benefits comments"
            value={props.inputs?.trailerRent?.addBenefits}
            onInputChange={(value, name) =>
              props.onInputChange(value, name, "trailerRent")
            }
            error={props.errors?.trailerRent?.addBenefits}
          />
        )}
      </InputWrapper>
    </Wrapper>
  );
};

export default RoundtripCreatorStepOne;
