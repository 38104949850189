import React from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  selectStyles,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";
import Select from "react-select";
import allLanguages from "../../data/allLanguages.json";
const languages = allLanguages.languages;

const PeriodSelect = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Select
            placeholder={props.placeholder}
            options={languages}
            styles={selectStyles}
            isMulti={props.isMulti}
            closeMenuOnSelect={!props.isMulti}
            value={props.inputs?.[props.name] || null}
            classNamePrefix="styled"
            onChange={(val) => props.onSelectChange(val, props.name)}
            className={props.error && "invalid"}
          />
          {props.error && (
            <ErrorWrapper>
              <span>{props.error?.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

export default PeriodSelect;
