import styled from "styled-components";

export const CardBlockWrapper = styled.div`
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
  width: 100%;
  margin: 1rem 0;
`;
export const CardBlockTitle = styled.div``;
export const CardWrapper = styled.div``;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: ${({ nobg }) => (!nobg ? "#e7e7e7" : "")};
  border-radius: 3px;
  padding: 0.25rem;
  span {
    color: #8c8c8c;
    font-size: 12px;
    font-family: "Inter";
    font-weight: 500;
    max-width: 0;
    opacity: 0;
    transition: 0.25s all ease;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Icon = styled.div`
  background: #d3d3d3;
  border-radius: 3px;
  padding: 0 0.25rem;
`;

export const CardHeader = styled.div`
  width: 100%;
  position: relative;
  h3,
  a {
    color: #4785ff;
    font-size: 1rem;
    font-family: "Inter";
    font-weight: 600;
    margin: 0;
    padding: 1.5rem 1rem;
    display: flex;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    align-items: center;
  }
  a {
    transition: 0.25s all ease;
    text-decoration: none;
    &:hover {
      transition: 0.25s all ease;
      background: #fafafa;
      cursor: pointer;
      ${IconWrapper} {
        span {
          padding: 0 0.35rem;
          max-width: 200px;
          opacity: 1;
          transition: 0.25s all ease;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    h3,
    a {
      font-size: 18px;
    }
  }
`;
export const CardContent = styled.div`
  padding: 1rem;
  .Table {
    padding: 0;
    .Table--inner .Table-content .Table-row {
      padding: 0;
      box-shadow: none;
      border: none;
      margin: 0;
      .item:first-child {
        padding-top: 0.75rem;
      }
    }
  }
`;
export const CardInline = styled.div`
  padding-bottom: 150px;
  @media (min-width: 800px) {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0;
    ${CardBlockWrapper} {
      &:nth-child(odd) {
        margin-right: 1rem;
      }
      width: calc(50% - 2rem);
    }
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    display: flex;
    ${CardBlockWrapper} {
      width: 100%;
      &:nth-child(odd) {
        margin-right: 0rem;
      }
    }
  }
`;

export const CardTitle = styled.h3`
  color: #4785ff;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`;
