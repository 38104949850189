import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import "../../../styles/Dashboard/MyCompany/MyCompany.css";
import { CardInline } from "../UI/Card/Styles";
import CardBlock from "../UI/Card/CardBlock";
import Card from "../UI/Card/Card";
import CompanyProgress from "./CompanyProgress";
import CompanyInfo from "./CompanyInfo";
import Table from "../../NewTable/Table";
import Loader from "../UI/Loader";
import FleetCard from "./FleetCard/FleetCard";
import LeftDrawer from "../Drawers/LeftDrawer";
import Screen from "../Screen/Screen";
import Navbar from "../Navbar";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";
import Minimessages from "../Messages/Minimessages";
import { isBrowser } from "react-device-detect";
import DashboardFooter from "../DashboardFooter";

const MyCompany = () => {
  const [currentDocuments, setCurrentDocuments] = useState([]);
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);

  // Load all current documents
  useEffect(() => {
    if (COMPANY?.documents) {
      setCurrentDocuments(COMPANY.documents);
    }
  }, [COMPANY]);

  return (
    <>
      <Screen>
        <Navbar />
        {USER?.privileges === "Admin" && (
          <CompanyProgress USER={USER} COMPANY={COMPANY} />
        )}
        <CardBlock>
          <Card
            title="Company profile"
            to="/dashboard/my-account/company-profile"
            privileges={USER ? USER.privileges : null}
          >
            <CompanyInfo COMPANY_DATA={COMPANY} />
          </Card>
        </CardBlock>
        <CardInline>
          <CardBlock>
            <FleetCard COMPANY={COMPANY} USER={USER} />
          </CardBlock>
          {COMPANY?.documents?.length > 0 ? (
            <CardBlock>
              <Card
                title="Documents"
                to="/dashboard/my-account/documents"
                privileges={USER ? USER.privileges : null}
              >
                <Table>
                  <TableHeader>
                    <TableHeaderItem flex="1">File</TableHeaderItem>
                  </TableHeader>
                  {currentDocuments.map((document) => {
                    return (
                      <TableRow key={document._id}>
                        <TableRowItem
                          rowName="File"
                          title={{
                            name: document.name,
                            href: document.url,
                            targetBlank: true,
                          }}
                          flex="1"
                        />
                      </TableRow>
                    );
                  })}
                </Table>
              </Card>
            </CardBlock>
          ) : (
            !currentDocuments && <Loader />
          )}
        </CardInline>
        <LeftDrawer />
        {isBrowser && <Minimessages />}
        <DashboardFooter />
      </Screen>
    </>
  );
};

export default MyCompany;
