import React from "react";
import { withRouter } from "react-router-dom";
import Login from "../components/Login/Login";

const LoginPage = (props) => {
  const { USER, COMPANY, history } = props;
  return (
    <>
      {USER && history.push("/")}
      <Login USER={USER} COMPANY={COMPANY} />
    </>
  );
};

export default withRouter(LoginPage);
