import React from "react";
import Container from "../../components/UI/Container";
import { Static, TermsList } from "./Styles";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <Container gutter="large">
      <Static>
        <h1>TERMS AND CONDITIONS</h1>
        <TermsList>
          <h3>1 Definitions</h3>
          <ul>
            <li>
              1.1 TransConnector - TransConnector OÜ (registry code 14630745,
              address Küütri 3 - 411, Tartu, Republic of Estonia).
            </li>
            <li>
              1.2 Database - The TransConnector database at{" "}
              <Link to="/">www.transconnector.eu</Link>, which contains internal
              job offers for transport companies and price inquiries from
              freight providers and other related information.
            </li>
            <li>
              1.3 User - Legal person who has accepted the Terms and Conditions,
              has registered itself in the Database and created the User
              Account.
            </li>
            <li>
              1.4 User Data - all data relating to the User entered into the
              Database by the User, including data entered in the Registration
              Form, data entered in the Database as an offer or an inquiry and
              other data on the User processed by TransConnector.
            </li>
            <li>
              1.5 Terms and Conditions - the current terms and conditions of use
              of the TransConnector Database for registered users.
            </li>
            <li>
              1.6 Data Recipient - a legal entity that uses the Database,
              including for searching for and selecting of subcontractors for
              itself or for its clients, or other services provided by
              TransConnector through the Portal, and to whom TransConnector
              provides or allows access to the User Data of the User in
              accordance with the Terms and Conditions for this purpose.
            </li>
            <li>1.7 Parties - TransConnector and User.</li>
            <li>
              1.8 Services - All TransConnector services described in the{" "}
              <Link to="/about">About page</Link> sections on{" "}
              <Link to="/">www.transconnector.eu.</Link>
            </li>
            <li>
              1.9 Access data - the username and password chosen by the User
              upon registration as a User or later changed by the User in the
              Database.
            </li>
            <li>
              1.10 Portal - The environment created by TransConnector for
              providing services related to the provision of job offers and
              price inquiries at <Link to="/">www.transconnector.eu.</Link>
            </li>
          </ul>
          <h3>2 User rights and obligations</h3>
          <ul>
            <li>
              2.1 After completing the registration form and accepting the Terms
              and Conditions, the User shall have access to the Database through
              the Access Data for the use of the Services.
            </li>
            <li>
              2.2 The User undertakes to keep the Access Data confidential and
              not to disclose it to any other person. The User is fully
              responsible for the use of the Access Data.
            </li>
            <li>
              2.4 The User is required to enter only the correct and accurate
              User Data into the Database.
            </li>
            <li>
              2.5 All rights to the Database belong to TransConnector. The User
              does not acquire any rights to the Database or its parts when
              using the Database and Services. The User may use the information
              contained in the Database to make contact in good faith through
              the contact details provided in the inside sob offers and price
              quotes. The User has no right to make other extracts or copies of
              the Database or parts thereof, or otherwise use the Database, or
              to damage or interfere with the operation of the Database. The
              User shall not acquire a copyright to the software required to
              maintain the Database in any way (including for the purpose of
              using the Service).
            </li>
            <li>
              2.6 The User has the right to supplement, correct and replace the
              User Data entered into the Database and decide on their
              availability to the recipients of Data (the right to change the
              status of the User Data, i.e., to make them active, passive or
              block them).
            </li>
            <li>
              In order to delete a User Account, the User must send a request to
              this effect via e-mail to{" "}
              <a href="mailto:info@transconnector.eu">info@transconnector.eu</a>
              . TransConnector will delete the user account within 3 working
              days of receiving the respective request from the User.
            </li>
          </ul>
          <h3>3 Rights and obligations of TransConnector</h3>
          <ul>
            <li>
              3.1 By agreeing to the Terms and Conditions by the User, the User
              gives consent to TransConnector at{" "}
              <Link to="/">www.transconnector.eu</Link> for the use of cookies
              for the purpose of personalizing the Service and contributing to
              providing the User with a better Service.
            </li>
            <li>
              3.2 TransConnector allows the User to use the Services as
              specified in the Terms and Conditions and the Portal as of the
              User's registration on the Portal.
            </li>
            <li>
              3.3 The User shall process the User Data on the basis of the Terms
              and Conditions as well as the contract concluded between the User
              and TransConnector to fulfill them and to the extent provided for
              therein. Processing of certain User Data, in accordance with the
              Service provided, also takes place for the purpose of fulfilling
              obligations under the TransConnector, for example to the extent
              provided by the Accounting Act.
            </li>
            <li>
              3.4 TransConnector points out that submission of User Data is an
              obligation arising from the Terms and Conditions, and failure to
              submit them or requesting termination or limitation of their
              processing may render TransConnector unable to provide the
              Services to the User in accordance with the Terms and Conditions.
              In the event that the User submits such a claim in relation to the
              processing of its User Data, TransConnector shall have the right
              to terminate the provision of the Services to the User and to
              delete the User Account together with all related data. In this
              case, the User may also request the deletion of its user account
              and all related information from the TransConnector.
            </li>
            <li>
              3.5 TransConnector processes (including collects, stores, saves,
              organizes, distributes, connects, uses, deletes, makes available
              to recipients of the Data, etc.) the User Data submitted by the
              User upon registration on the Portal and entered into the Database
              for the purpose of providing the Services to the User, as well as
              when it is required for performance of the statutory obligations
              of TransConnector or to protect the legitimate interests of
              TransConnector.
            </li>
            <li>
              3.6 TransConnector will not transmit User Data to third parties
              except:
            </li>
            <ol>
              <li>
                3.6.1 to the recipients of the data in order to execute the
                contracts entered into with them or to ensure their performance;
              </li>
              <li>
                3.6.2 to third party service providers used by TransConnector to
                provide services and ensure providing Services to the User.
              </li>
            </ol>
            <li>
              3.7 The persons referred to in section 3.6 are authorized
              processors of User Data.
            </li>
            <li>
              3.8 If TransConnector has reason to believe that User Data of the
              User entered into the Database is incorrect or erroneous,
              TransConnector shall have the right to delete the entered data
              from the Database.
            </li>
            <li>
              3.9 TransConnector is not an authorized representative or agent of
              the Data provider, the data entry clerk of the
              offers/advertisements/inquiries, therefore TransConnector shall
              not be liable for any damage that may be caused to the User
              through searching for a job through the Portal, making price
              offers or during further cooperation with third parties related to
              the Portal. The User enters into the pre-contractual negotiations
              with third parties associated with the Portal and concludes
              agreements/contracts independently with TransConnector and
              TransConnector is not responsible for the fulfilment of the
              promises, agreements or terms and conditions of the contract
              provided by the recipient of the Data/Third Parties associated
              with the Portal.
            </li>
            <li>
              3.10 TransConnector does not guarantee to the User finding of a
              client/partner / subcontractor through the Portal.
            </li>
            <li>
              3.11 TransConnector shall not be liable for the unauthorized use
              of User Data of the User made available to the recipient of the
              Data, as well as for the confidentiality of such data, if
              confidentiality must be ensured by the recipient of the Data.
            </li>
            <li>
              3.12 TransConnector has the right to notify the User of the
              services, capabilities, and news of itself and its business
              partners at the e-mail address entered upon registration of the
              Portal.
            </li>
            <li>
              3.13 TransConnector has the right to notify the User by e-mail
              about new job offers and price inquiries.
            </li>
            <li>
              3.14 TransConnector has the right to place the Database, including
              the User Data contained therein, on a server located abroad. In
              this case, TransConnector remains the data controller.
            </li>
            <li>
              3.15 TransConnector maintains User Data throughout the period of
              provision of the Services and until User Account of the User has
              not been deleted in accordance with Section 2.6.
            </li>
            <li>
              3.16 In addition to the provisions of paragraph 3.15,
              TransConnector reserves the right to retain User Data for a period
              of time after the closure of the User Account, which is justified
              for the purpose of protecting the rights arising from the Terms
              and Conditions of TransConnector or law or other directly
              applicable law, in particular during expiry period for any claims.
            </li>
            <li>
              3.17 TransConnector has the right to change the structure of the
              Database procedures and the menu without warning the User.
            </li>
            <li>
              3.18 TransConnector has the right to unilaterally change the Terms
              and Conditions by notifying of changes and making new terms and
              conditions available on <Link to="/">www.transconnector.eu</Link>{" "}
              at least 5 days before the changes take effect. The changes will
              not apply retroactively to the Services ordered by the User prior
              to their entry into force.
            </li>
          </ul>
          <h3>4 Liability of the contracting parties</h3>
          <ul>
            <li>
              4.1 TransConnector shall have the right to terminate unilaterally
              without a notice provision of the Services to the User without
              returning the to the User the fee paid for the Services, if the
              User has violated the Terms and Conditions and has not eliminated
              the violation within 10 working days from the sending of the
              respective warning by TransConnector via e-mail.
            </li>
            <li>
              4.2 The User shall have the right, at any time, to waive the
              further use of the Services by giving notice to TransConnector by
              e-mail. In such a case, the User shall not be entitled to demand
              reimbursement of the fee paid for the ordered Services.
            </li>
            <li>
              4.3 The User shall be liable for any damage caused to
              TransConnector by a violation of the Terms and Conditions.
            </li>
            <li>
              4.4 TransConnector shall not be liable for the accuracy or content
              of the information entered in the Database or for any damage
              arising to the User from the use of the information contained in
              the Database.
            </li>
          </ul>
          <h3>5 Refunds and cancellations</h3>
          <ul>
            <li>6.1 TransConnector does not offer any refunds.</li>
            <li>
              6.2 You can cancel your subscription any time under my account "My
              subscription" area by clicking cancel subscription. No additional
              fees will be charged by cancelation. Your subscription will stay
              active until the end of previously paid subscription period.
            </li>
          </ul>
          <h3>7 Final provisions</h3>
          <ul>
            <li>
              7.1 If the Terms and Conditions provide for notification of
              TransConnector by e-mail, the User shall send such notifications
              to{" "}
              <a href="mailto:info@transconnector.eu.">
                info@transconnector.eu.
              </a>
            </li>
            <li>
              7.2 If the Terms and Conditions provide for notification of
              TransConnector by telephone, the User shall transmit such
              notifications to the number{" "}
              <a href="tel:+37256886666">+372 56 886 666.</a>
            </li>
          </ul>
          <p>
            The Parties shall endeavour to settle all disputes and disagreements
            arising in the course of their duties by negotiation. If the
            agreement is not reached, the dispute shall be settled in the Tartu
            Courthouse of Tartu County Court in accordance with the legislation
            of the Republic of Estonia.
          </p>
        </TermsList>
      </Static>
    </Container>
  );
};

export default Terms;
