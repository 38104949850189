import React, { useState, useEffect } from "react";
import SheetTab from "../../PageWithList/Sheet/SheetTab";
import { SheetInnerWrapper } from "../../PageWithList/Sheet/Styles";
import Details from "./Details";
import Map from "../../PageWithList/Sheet/Map";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import ActionButton from "../../PageWithList/Sheet/ActionButton";
import MessageModal from "../../Modals/MessageModal/MessageModal";
import SendQuoteModal from "../../Modals/SendQuoteModal/SendQuoteModal";
import { isIOS } from "react-device-detect";
import MessageSent from "../../Modals/MessageModal/MessageSent";

const SheetInner = (props) => {
  const [detailsTab, setDetailsTab] = useState(true);
  const [hasRequestedMap, setHasRequestedMap] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const { loading, unloading, _id } = props.tender;

  const handleModal = (open, type) => {
    setModal(open);
    setModalType(type);
  };

  useEffect(() => {
    if (!detailsTab) setHasRequestedMap(true);
  }, [detailsTab]);
  return (
    <>
      <SheetInnerWrapper iOS={isIOS}>
        <SheetTab detailsTab={detailsTab} setDetailsTab={setDetailsTab} />
        <Details tender={props.tender} show={detailsTab ? true : false} />
        <Map
          loading={loading}
          unloading={unloading}
          hasRequestedMap={hasRequestedMap}
          setHasRequestedMap={setHasRequestedMap}
          show={!detailsTab ? true : false}
        />
      </SheetInnerWrapper>
      {!props.denyActions && (
        <>
          <ActionButtons iOS={isIOS && "1"}>
            <ActionButton
              icon={messageIcon}
              label="Send message"
              onClick={() => handleModal(!modal, "message")}
            />
            <ActionButton
              icon={quoteIcon}
              label="Send quote"
              onClick={() => handleModal(!modal, "quote")}
            />
          </ActionButtons>
          {modal && modalType === "message" && !props.messageSent && (
            <MessageModal
              handleMessageSubmit={props.handleMessageSubmit}
              id={_id}
              onClose={() => handleModal(!modal, null)}
            />
          )}
          {modal && modalType === "quote" && !props.messageSent && (
            <SendQuoteModal
              id={_id}
              loading={loading}
              unloading={unloading}
              onClose={() => handleModal(!modal, null)}
            />
          )}
          {props.messageSent && (
            <MessageSent
              onClose={() => {
                props.setMessageSent(false);
                setModal(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.812"
    height="15.852"
    viewBox="0 0 18.812 15.852"
  >
    <path
      d="M17.811,10.234a1.544,1.544,0,0,1-1.539,1.539H4.078L1,14.852V2.539A1.544,1.544,0,0,1,2.539,1H16.272a1.544,1.544,0,0,1,1.539,1.539Z"
      fill="none"
      stroke="#262a68"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

const quoteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.205"
    height="16.205"
    viewBox="0 0 16.205 16.205"
  >
    <path
      d="M15.727,7.762,8.435.47A1.61,1.61,0,0,0,7.292,0H1.621A1.625,1.625,0,0,0,0,1.621V7.292A1.617,1.617,0,0,0,.478,8.443L7.77,15.735a1.61,1.61,0,0,0,1.142.47,1.584,1.584,0,0,0,1.142-.478l5.672-5.672a1.584,1.584,0,0,0,.478-1.142,1.637,1.637,0,0,0-.478-1.151ZM2.836,4.051A1.215,1.215,0,1,1,4.051,2.836,1.214,1.214,0,0,1,2.836,4.051Z"
      transform="translate(16.205) rotate(90)"
      fill="#fff"
    />
  </svg>
);

export default SheetInner;
