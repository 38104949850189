import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TenderListHeader from "./TenderListHeader";
import TenderListItem from "./TenderListItem";
import Loader from "../../Dashboard/UI/Loader";
import UserNotice from "../../UserNotice/UserNotice";
import welcomeNoResults from "../../../assets/welcome/noResults.svg";
import {
  filterTruckTypes,
  filterLocations,
} from "../../../helpers/ListFiltering";
import { getFilterTenders } from "../../../store/actions/app/tender";
import ReviewModal from "../../Modals/ReviewModal/ReviewModal";
import AddReview from "../../Modals/ReviewModal/AddReview";

const TenderList = React.memo((props) => {
  const [tenders, setTenders] = useState([]);
  const [hasSetAllTenders, setHasSetAllTenders] = useState(false);
  const { USER } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [reviewModal, setReviewModal] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(null);
  const dispatch = useDispatch();
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  // Tender list filtering
  const {
    loading,
    loadingradius,
    unloading,
    unloadingradius,
    truckType,
    typeOfLoad,
  } = props.filters;
  const { setAllTenders } = props;
  useEffect(() => {
    if (props.filters) {
      // Did cancel is used for canceling a request when the user requests another payload while the previous one is still loading
      let didCancel = false;
      // Rearrange trucktype and locations structure for filtering
      const truckTypes = filterTruckTypes(truckType);
      const params = filterLocations(
        loading,
        loadingradius,
        unloading,
        unloadingradius
      );

      // Send the request to filter tenders
      dispatch(
        getFilterTenders({
          loadinglocation: params.loading.value,
          loadingradius: params.loading.radius,
          unloadinglocation: params.unloading.value,
          unloadingradius: params.unloading.radius,
          trucktype: truckTypes,
          typeofload: typeOfLoad,
        })
      ).then((res) => {
        // Set filtered tender count
        if (!didCancel) {
          res.data?.length > 0
            ? props.setTenderCount(res.data?.length)
            : props.setTenderCount(0);

          // Set filtered tenders and all tenders
          if (res.data.success === false || res.data?.length <= 0) {
            setTenders(null);
          } else {
            const result = res.data.filter((e) => e.status).reverse();
            setTenders(result);
            if (!hasSetAllTenders) {
              setAllTenders(result);
              setHasSetAllTenders(true);
            }
          }
        }
      });
      return () => (didCancel = true);
    }
  }, [
    props.filters,
    loading,
    loadingradius,
    unloading,
    unloadingradius,
    truckType,
    typeOfLoad,
  ]);

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  // Add Sheet ID to localstorage to display viewed tenders
  useEffect(() => {
    if (props.sheetID && USER && !isExpired) {
      const viewedTenders = localStorage.hasOwnProperty("viewedTenders")
        ? JSON.parse(localStorage.getItem("viewedTenders"))
        : [];
      if (!viewedTenders.includes(props.sheetID)) {
        viewedTenders.push(props.sheetID);
        localStorage.setItem("viewedTenders", JSON.stringify(viewedTenders));
      }
    }
  }, [props.sheetID, USER, isExpired]);

  return (
    <TenderListHeader USER={USER}>
      {tenders?.length > 0
        ? tenders?.map((tender) => {
            return (
              <TenderListItem
                key={tender._id}
                tender={tender}
                sheetID={props.sheetID === tender._id ? props.sheetID : null}
                setSheetID={props.setSheetID}
                USER={USER}
                setReviewModal={setReviewModal}
                viewed={
                  localStorage.hasOwnProperty("viewedTenders") &&
                  localStorage.getItem("viewedTenders").includes(tender._id)
                }
              />
            );
          })
        : tenders && <Loader />}
      {!tenders && (
        <UserNotice
          image={welcomeNoResults}
          title="Unfortunately no tenders were found. Try again with different filters or clear all filters with the button below."
          buttons={[
            {
              name: "Clear filters",
              isButton: true,
              onClick: props.clearFilters,
            },
          ]}
        />
      )}
      {reviewModal && (
        <ReviewModal
          showHeader
          fetchReviews
          id={reviewModal}
          onClose={() => setReviewModal(null)}
          onClick={() => {
            setAddReviewModal(reviewModal);
            setReviewModal(null);
          }}
        />
      )}
      {!reviewModal && addReviewModal && (
        <AddReview
          name={reviewModal?.company?.companyName}
          id={addReviewModal}
          onClose={() => setAddReviewModal(false)}
        />
      )}
    </TenderListHeader>
  );
});

export default TenderList;
