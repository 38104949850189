import React from "react";
import { sanitize } from "dompurify";
import SheetBlock from "../../PageWithList/Sheet/SheetBlock";
import {
  ShowWrapper,
  SheetItem,
  SheetItemIconsWrapper,
  SheetItemWithIcon,
  HTMLField,
  SheetItemInline,
} from "../../PageWithList/Sheet/Styles";
import { Link } from "react-router-dom";
import DetailsSpecialRequests from "../../PageWithList/Sheet/DetailsSpecialRequests";

const Details = (props) => {
  const {
    name,
    author,
    company,
    routeDescription,
    trucks,
    trailerRent,
    countries,
    requirements,
    additionalInformation,
    languages,
    payment,
    price,
    kmPerMonth,
  } = props.roundtrip;

  return (
    <ShowWrapper show={props.show}>
      <SheetItemIconsWrapper>
        {(author?.firstName || author?.lastName) && (
          <SheetItemWithIcon>
            <Link to={`/dashboard/company/${company?._id}`}>
              {userIcon} {author?.firstName} {author?.lastName}
            </Link>
          </SheetItemWithIcon>
        )}
        {author?.email && (
          <SheetItemWithIcon>
            <a href={`mailto:${author.email}`}>
              {emailIcon} {author.email}
            </a>
          </SheetItemWithIcon>
        )}
        {author?.phone && (
          <SheetItemWithIcon>
            <a href={`tel:${author.phone}`}>
              {phoneIcon} {author.phone}
            </a>
          </SheetItemWithIcon>
        )}
      </SheetItemIconsWrapper>
      <SheetBlock label="Roundtrip info">
        <SheetItem>
          <h3>Roundtrip name</h3>
          <h4>{name}</h4>
        </SheetItem>
        {countries && (
          <SheetItem>
            <h3>Countries</h3>
            <h4>
              {countries
                ?.map((country) => {
                  return country?.label.replace(/_/g, " ");
                })
                .join(", ")}
            </h4>
          </SheetItem>
        )}
        <SheetItem>
          <h3>Route description</h3>
          <HTMLField
            dangerouslySetInnerHTML={{ __html: sanitize(routeDescription) }}
          ></HTMLField>
        </SheetItem>
        {trucks?.truckTypes && (
          <SheetItem>
            <h3>Truck type</h3>
            <h4>
              {trucks?.truckTypes
                .map((element) => {
                  if (element.value === "Refrigerator" && trucks?.temperature) {
                    return `${element.value} (${trucks?.temperature}°C)`;
                  }
                  return element.value;
                })
                .join(", ")
                .replace(/_/g, " ")}
            </h4>
            {trucks?.additionalInfo && (
              <HTMLField
                dangerouslySetInnerHTML={{
                  __html: sanitize(trucks?.additionalInfo),
                }}
              />
            )}
          </SheetItem>
        )}
        {trailerRent?.available && (
          <SheetItem>
            <h3>Trailer rent</h3>
            <h4>Yes</h4>
            {trailerRent?.addBenefits && <h4>{trailerRent?.addBenefits}</h4>}
          </SheetItem>
        )}
        {(price?.kmPrice > 0 || kmPerMonth) && (
          <SheetItemInline>
            <>
              {kmPerMonth && (
                <SheetItem>
                  <h3>Km / per month</h3>
                  <h4>{kmPerMonth} km</h4>
                </SheetItem>
              )}
              {price?.kmPrice > 0 && (
                <SheetItem>
                  <h3>Price</h3>
                  <h4>{price?.kmPrice.toFixed(2)}€ /km</h4>
                  {price?.priceType && <h4>{price?.priceType}</h4>}
                </SheetItem>
              )}
            </>
          </SheetItemInline>
        )}
        {requirements && (
          <>
            <DetailsSpecialRequests
              title="Requirements for carrier"
              specialRequests={requirements}
            />
            {requirements?.otherRequirements && (
              <HTMLField
                dangerouslySetInnerHTML={{
                  __html: sanitize(requirements?.otherRequirements),
                }}
              ></HTMLField>
            )}
          </>
        )}
      </SheetBlock>
      <SheetBlock label="Additional info">
        {additionalInformation && (
          <SheetItem>
            <HTMLField
              dangerouslySetInnerHTML={{
                __html: sanitize(additionalInformation),
              }}
            ></HTMLField>
          </SheetItem>
        )}
        {languages && (
          <SheetItem>
            <h3>Languages</h3>
            <h4>
              {languages
                ?.map((language) => {
                  return language.label;
                })
                .join(", ")}
            </h4>
          </SheetItem>
        )}
      </SheetBlock>
      <SheetBlock label="Payment terms">
        <SheetItem>
          <h3>Payment on after receiving document:</h3>
          <h4>{payment?.documents}</h4>
        </SheetItem>
        {payment?.paymentTime && (
          <SheetItem>
            <h3>Payment time</h3>
            <h4>{payment.paymentTime} days</h4>
          </SheetItem>
        )}
        {payment?.skonto && (
          <SheetItem>
            <h3>Skonto payment possibility</h3>
            <h4>Yes</h4>
          </SheetItem>
        )}
        {payment?.dieselCard && (
          <SheetItem>
            <h3>Diesel card</h3>
            <h4>Yes</h4>
          </SheetItem>
        )}
        {payment?.paymentComment && (
          <SheetItem>
            <HTMLField
              dangerouslySetInnerHTML={{
                __html: sanitize(payment?.paymentComment),
              }}
            ></HTMLField>
          </SheetItem>
        )}
      </SheetBlock>
    </ShowWrapper>
  );
};

const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
  >
    <g transform="translate(-5.5 -4)">
      <path
        d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2"
        transform="translate(0 -6)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M20,8.5a4,4,0,1,1-4-4A4,4,0,0,1,20,8.5Z"
        transform="translate(-2)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const emailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.406"
    height="13.062"
    viewBox="0 0 17.406 13.062"
  >
    <g transform="translate(-2.297 -5.5)">
      <path
        d="M4.6,6H17.4A1.56,1.56,0,0,1,19,7.508v9.046a1.56,1.56,0,0,1-1.6,1.508H4.6A1.56,1.56,0,0,1,3,16.554V7.508A1.56,1.56,0,0,1,4.6,6Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M19,9l-8,6.428L3,9"
        transform="translate(0 -1.163)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const phoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.2"
    height="21.286"
    viewBox="0 0 15.2 21.286"
  >
    <g transform="translate(-7.5 -2.5)">
      <path
        d="M9.529,3H19.671A2.029,2.029,0,0,1,21.7,5.029V21.257a2.029,2.029,0,0,1-2.029,2.029H9.529A2.029,2.029,0,0,1,7.5,21.257V5.029A2.029,2.029,0,0,1,9.529,3Z"
        transform="translate(0.5 0)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.381"
        transform="translate(11 20.015)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Details;
