import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants/";
import moment from "moment";
import { withRouter } from "react-router-dom";

import Container from "../components/UI/Container";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import { BlogPageList } from "../components/FrontPage/Blog/Styles";
import Post from "../components/FrontPage/Blog/Post";
import Header from "../components/FrontPage/Header/Header";
import Single from "../components/FrontPage/Blog/Single";
import Footer from "../components/Footer/Footer";
import ScrollToTopOnMount from "../helpers/ScrollToTop";
import { isBrowser } from "react-device-detect";
import Minimessages from "../components/Dashboard/Messages/Minimessages";

const BlogPage = (props) => {
  const { USER, match, history } = props;
  const [posts, setPosts] = useState(null);
  const [post, setPost] = useState(null);
  const postId = match?.params?.id;

  useEffect(() => {
    if (postId && posts) setPosts(null);
    if (!postId && post) setPost(null);
    if (!posts && !postId) {
      axios.get(`${API_URL}/cms/blog`).then((res) => setPosts(res.data));
    }
    if ((!post || post?._id !== postId) && postId) {
      axios
        .get(`${API_URL}/cms/blog/${postId}`)
        .then((res) => {
          if (!res.data) {
            history.push("/blog");
            return;
          }
          setPost(res.data);
        })
        .catch(() => history.push("/blog"));
    }
  }, [posts, postId]);

  if (postId && !post) return null;
  if (!postId && !posts) return null;

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      {posts && (
        <Container>
          <BlogPageList>
            {posts?.map((post) => {
              const { _id, title, shortContent, imageUrl, createdAt } = post;
              return (
                <Post
                  key={_id}
                  to={`/blog/${_id}`}
                  title={title}
                  content={shortContent}
                  img={imageUrl}
                  date={moment(createdAt).format("DD MMMM YYYY")}
                />
              );
            })}
          </BlogPageList>
        </Container>
      )}
      {post && <Single post={post} />}
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
      <Footer />
    </>
  );
};

export default withRouter(BlogPage);
