import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";
import Status from "../../NewTable/Status";
import Options from "../../NewTable/Options";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import ConfirmationSuccess from "../../Modals/ConfirmationModal/ConfirmationSuccess";
import { REMOVE_OFFER } from "../../../store/actionTypes";
import { removeOffer } from "../../../store/actions/dashboard/offers";
import formatLocationName from "../../../helpers/LocationName"


const EmptyTrucksTable = (props) => {
  const [optionsId, setOptionsId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmationModal = (id, title) => {
    setConfirmationModal({ id, title });
  };

  const deleteEmptytruck = (id) => {
    dispatch(removeOffer(id, "Emptytruck")).then((res) => {
      dispatch({
        type: REMOVE_OFFER,
        payload: res.data,
        id: id,
        array: "emptyTrucks",
      });
      setConfirmationModal(null);
      setConfirmationSuccess(true);
    });
  };

  const handleToggleOptions = (id) => {
    if (optionsId === id) {
      setOptionsId(null);
    } else {
      setOptionsId(id);
    }
  };

  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.5">From</TableHeaderItem>
          <TableHeaderItem flex="0.5">To</TableHeaderItem>
          <TableHeaderItem flex="0.4">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.2">Status</TableHeaderItem>
          <TableHeaderItem flex="0.1"></TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.emptyTrucks.map((truck) => {
            const loading = truck.loading?.locations[0];
            const unloading = truck.unloading?.locations[0];
            return (
              <TableRow
                key={truck._id}
                onClick={() =>
                  handleRedirect("/trucks", {
                    sheet: truck._id,
                  })
                }
              >
                <TableRowItem
                  rowName="From"
                  image={{
                    url: loading.flagUrl,
                    alt: loading.country,
                  }}
                  title={{
                    name: formatLocationName(loading.locationName),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="To"
                  image={{
                    url: unloading.flagUrl,
                    alt: unloading.country,
                  }}
                  title={{
                    name: formatLocationName(unloading.locationName),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name:
                      truck.truckType[0]?.label === "Refrigerator"
                        ? `(${truck?.temperature}°C)`
                        : truck.truckType[0]?.label,
                  }}
                  flex="0.4"
                >
                  <span>
                    {`${truck.typeOfLoad}`}{" "}
                    {truck.freight.weight && ` · ${truck.freight.weight}t`}{" "}
                    {truck.freight.loadingMeters &&
                      ` · LDM: ${truck.freight.loadingMeters}m`}
                  </span>
                </TableRowItem>
                <TableRowItem rowName="Status" flex="0.2">
                  <Status
                    active={truck.active}
                    status={truck.active ? "Active" : "Inactive"}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleStatusChange(
                        truck._id,
                        !truck.active,
                        "Emptytruck",
                        "emptyTrucks"
                      );
                    }}
                  />
                </TableRowItem>
                <TableRowItem flex="0.1" overflow>
                  <Options
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleOptions(truck._id);
                    }}
                    open={optionsId === truck._id}
                    onBlur={() => setOptionsId(null)}
                  >
                    <ul>
                      <li
                        onClick={() =>
                          handleRedirect(`/dashboard/trucks/edit/${truck._id}`)
                        }
                      >
                        Edit
                      </li>
                      <li
                        onClick={() =>
                          handleRedirect(
                            `/dashboard/trucks/duplicate/${truck._id}`
                          )
                        }
                      >
                        Duplicate
                      </li>
                      <li
                        onClick={() =>
                          handleConfirmationModal(
                            truck._id,
                            `Delete: ${loading.locationName} - ${unloading.locationName}`
                          )
                        }
                      >
                        Delete
                      </li>
                    </ul>
                  </Options>
                </TableRowItem>
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
      {confirmationModal && (
        <ConfirmationModal
          onClose={() => setConfirmationModal(null)}
          title={confirmationModal.title}
          onConfirm={() => deleteEmptytruck(confirmationModal.id)}
        />
      )}
      {confirmationSuccess && (
        <ConfirmationSuccess onClose={() => setConfirmationSuccess(false)} />
      )}
    </>
  );
};

export default withRouter(EmptyTrucksTable);
